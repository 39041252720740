<template>
  <v-dialog v-model="isActive" persistent max-width="300px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text class="text-center">
        <div v-for="text in texts" :key="text">{{ text }}</div>
        <template v-if="dispMemo == true">
          <v-textarea
            class="pt-2"
            background-color="amber lighten-5"
            :label="memoLabel"
            auto-grow
            outlined
            v-model="memo"
          />
        </template>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-col>
          <v-row no-gutters justify="center">
            <v-btn
              class="font-weight-bold"
              color="primary"
              large
              @click="agree"
            >
              OK
            </v-btn>
          </v-row>
          <v-row no-gutters class="mt-5" justify="center">
            <v-btn
              v-show="showCancel"
              color="cancel"
              large
              text
              @click="cancel"
            >
              <span class="text-caption">キャンセル</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
    showCancel: false,
    title: null,
    texts: null,
    resolve: null,
    reject: null,
    dispMemo: false,
    memo: "",
    memoLabel: "",
  }),
  methods: {
    open(
      title = "",
      texts = [],
      showCancel = false,
      dispMemo = true,
      memo,
      memoLabel
    ) {
      this.isActive = true;
      this.showCancel = showCancel;
      this.title = title;
      this.texts = texts;
      this.memo = memo;
      this.memoLabel = memoLabel;
      this.dispMemo = dispMemo;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.isActive = false;
      this.$emit("agree", this.memo);
    },
    cancel() {
      this.resolve(false);
      this.isActive = false;
    },
  },
};
</script>

<style></style>
