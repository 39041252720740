import cookieMixin from "./cookieMixin";
export default {
  mixins: [cookieMixin],

  data() {
    return {
      loading: false,
      snackbar: false,
      timeout: 2000,
    };
  },
  methods: {
    postForm(url, param, sucessFunc) {
      this.loading = true;
      window.axios.post(url, param, { headers: { 'Authorization': "Bearer " + window.token, 'Content-Type': 'multipart/form-data' } })
        .then(function (response) {
          this.loading = false;
          sucessFunc(response)
        }.bind(this))
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            console.log("refToken")
            this.refToken(url, param, false, true, sucessFunc);
          } else {
            this.loading = false;
            alert("サーバでエラーが発生しました。");
            console.log("postForm", error);
            console.log(error.response);
          }
        }.bind(this));
    },
    async postFormPromise(url, param) {
      this.loading = true;
      let res
      try {
        res = await window.axios
          .post(url, param, { headers: { 'Authorization': "Bearer " + window.token } })
      } catch (err) {
        if (err && err.response.status == 401) {
          console.log("refToken")
          res = this.refTokenPromise(url, param, false);
        } else if (err.response.status != 200) {
          this.loading = false;
          alert("サーバでエラーが発生しました。");
          console.log(err);
        }
      } finally {
        this.loading = false;
      }
      return res;
    },
    async postFormBlobPromise(url, param) {
      this.loading = true;
      let res
      try {
        res = await window.axios
          .post(url, param, { responseType: "blob", headers: { 'Authorization': "Bearer " + window.token } })
      } catch (err) {
        if (err && err.response.status == 401) {
          console.log("refToken")
          res = this.refTokenPromise(url, param, true);
        } else if (err.response.status != 200) {
          this.loading = false;
          alert("サーバでエラーが発生しました。");
          console.log(err);
        }
      } finally {
        this.loading = false;
      }
      return res;
    },
    async refTokenPromise(url, param, blob_res) {
      let res
      try {
        res = await window.axios.post(window.base_url + "/auth/refresh", { ref_token: window.reftoken })
      } catch (error) {
        if (error && error.response.status == 401) {
          this.loading = false;
          if (window.token != "") {
            alert("セッションがタイムアウトになりました。");
          }
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('reftoken')
          window.token = "";
          window.reftoken = "";
          window.name = "";
          window.level = -1;
          if (window.isiBow) {
            //最近のブラウザではscriptからcloseできないらしい（暫定対応）
            window.open('about:blank', '_self').close();
            return;
          } else {
            location.href = "/";
          }
          return
        } else if (error.response.status !== 200) {
          this.loading = false;
          alert("サーバでエラーが発生しました。");
          console.log(error);
          return
        }
      }

      const response = res;

      var base64Url = response.data.token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      var payload = JSON.parse(jsonPayload);
      window.token = response.data.token;
      window.reftoken = response.data.ref_token;
      window.name = payload.name;
      window.level = payload.level;

      if (blob_res) {
        return await this.postFormBlobPromise(url, param);
      }
      else {
        return await this.postFormPromise(url, param);
      }
    },
    postJson(url, param, sucessFunc) {
      this.loading = true;
      window.axios.post(url, param, { headers: { 'Authorization': "Bearer " + window.token } })
        .then(function (response) {
          this.loading = false;
          sucessFunc(response)
        }.bind(this))
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            console.log("refToken")
            this.refToken(url, param, false, sucessFunc);
          } else {
            this.loading = false;
            alert("サーバでエラーが発生しました。");
            console.log(error);
            console.log(error.response);
          }
        }.bind(this));
    },
    postJsonCheck(url, param, sucessFunc) {
      const func = (response) => {
        if (response.data.code > 0) {
          alert(response.data.message)
        } else {
          sucessFunc(response)
        }
      }
      this.postJson(url, param, func)
    },
    postJsonBlobRes(url, param, sucessFunc) {
      this.loading = true;
      window.axios.post(url, param, { responseType: "blob", headers: { 'Authorization': "Bearer " + window.token } })
        .then(function (response) {
          this.loading = false;
          sucessFunc(response)
        }.bind(this))
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            console.log("refToken")
            this.refToken(url, param, true, sucessFunc);
          } else {
            this.loading = false;
            alert("サーバでエラーが発生しました。");
            console.log(error);
            console.log(error.response);
          }
        }.bind(this));
    },
    postJsonBlobResCheck(url, param, sucessFunc) {
      const func = (response) => {
        if (response.data.code && response.data.code > 0) {
          alert(response.data.message)
        } else {
          sucessFunc(response)
        }
      }
      this.postJsonBlobRes(url, param, func)
    },
    refToken(url, param, blob_res, sucessFunc) {
      window.axios.post(window.base_url + "/auth/refresh", { ref_token: window.reftoken })
        .then(function (response) {
          var base64Url = response.data.token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          var payload = JSON.parse(jsonPayload);
          window.token = response.data.token;
          window.reftoken = response.data.ref_token;
          window.name = payload.name;
          window.level = payload.level;
          if (blob_res) {
            this.postJsonBlobRes(url, param, sucessFunc);
          } else {
            this.postJson(url, param, sucessFunc);
          }
        }.bind(this))
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            this.loading = false;
            if (window.token != "") {
              alert("セッションがタイムアウトになりました。");
            }
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('reftoken')
            window.token = "";
            window.reftoken = "";
            window.name = "";
            window.level = -1;
            if (window.isiBow) {
              //最近のブラウザではscriptからcloseできないらしい（暫定対応）
              window.open('about:blank', '_self').close();
              return;
            } else {
              location.href = "/";
            }
          } else {
            this.loading = false;
            alert("サーバでエラーが発生しました。");
            console.log(error);
            console.log(error.response);
          }
        }.bind(this));
    },

    //数値チェック
    isNumber(val) {
      var regex = new RegExp(/^[+-]?[0-9]+$/);
      return regex.test(val);
    },
    chkNum(val) {
      if (!this.isNumber(val)) {
        return '数値を入力して下さい。';
      }
      return true;
    },
  }
}
