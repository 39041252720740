<template>
  <div class="max-area-component">
    <v-form ref="requiredForms">
      <v-row no-gutters align="end" class="ml-1">
        <TextDatePicker
          label="出力期間"
          v-model="start_attend_date"
          textClass="mr-5"
          :rules="[(v) => !!v || '年月日は必須入力です。']"
        />
        <div class="mr-5 mb-5">~</div>
        <TextDatePicker
          label=""
          v-model="end_attend_date"
          textClass="mr-5"
          :rules="[(v) => !!v || '年月日は必須入力です。']"
          :min="start_attend_date"
        />
        <v-select
          style="max-width: 310px"
          label="就業状況"
          v-model="selected_status"
          :items="staff_status"
          chips
          multiple
          clearable
        >
          <template v-slot:selection="{ item }">
            <v-chip class="font-weight-bold" color="primary" dark>
              <v-icon left>{{ item.icon }}</v-icon>
              <span>{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-row>
      <v-row no-gutters align="end" class="mb-3">
        <v-btn
          class="ml-3 font-weight-bold white--text"
          color="append"
          @click="loadData()"
        >
          <v-icon left>mdi-magnify</v-icon>検索
        </v-btn>
        <!-- <v-select
          class="ml-3"
          label="フォーマット選択"
          v-model="selected_format"
          :items="format_options"
          outlined
          hide-details
          style="max-width: 260px"
        /> -->
        <v-btn
          class="ml-3 font-weight-bold"
          color="primary"
          :disabled="isRestricted"
          @click="outputCsv()"
        >
          <v-icon left>mdi-export-variant</v-icon>治験実績出力
        </v-btn>
      </v-row>
      <v-row no-gutters class="ml-10">
        <v-sheet class="ml-15" width="800px">
          <span>
            ※CSV形式でダウンロードしたデータをExcelで展開した場合、数値・書式の不整合が発生する場合があります。Excel画面上でデータ修正・確認を行う必要がある場合は、ご利用のExcelにてヘルプをご参照ください。
          </span>
        </v-sheet>
      </v-row>
    </v-form>
    <v-data-table
      height="100%"
      fixed-header
      v-model="selected_staffs"
      :headers="headers"
      :items="staffs"
      :options="options"
      item-key="id"
      class="elevation-1 attenddatalink-table"
      show-select
      hide-default-footer
      no-data-text="職員のデータが見つかりませんでした。"
      @click:row="clickRow"
    />
    <LoadingDialog :loading="loading" />
    <!-- 支給日入力画面 -->
    <payment-date-dialog ref="paymentDate" />
    <v-snackbar bottom v-model="snackbar" :timeout="1000">
      処理が完了しました。
    </v-snackbar>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import LoadingDialog from "../common/LoadingDialog";
import TextDatePicker from "../common/TextDatePicker";
import PaymentDateDialog from "./PaymentDateDialog";
import Encoding from "encoding-japanese";

export default {
  mixins: [axiosMixin, commonMixin],

  components: {
    LoadingDialog,
    TextDatePicker,
    PaymentDateDialog,
  },

  data: (vm) => ({
    headers: [
      { text: "職員名", value: "name" },
      { text: "職種", value: "job" },
      { text: "ステータス", value: "status" },
    ],
    options: { itemsPerPage: -1 },
    snackbar: false,
    now_date: vm.dateJp(new Date()),
    start_attend_date: "",
    end_attend_date: "",
    staff_status: [
      { text: "在職", value: "1", icon: "mdi-account" },
      { text: "休職", value: "2", icon: "mdi-account-heart" },
      { text: "退職", value: "3", icon: "mdi-human-greeting" },
    ],
    selected_status: ["1"],
    staffs: [],
    selected_staffs: [],
    test: false,
    isRestricted: false,
    format_options: [
      { text: "iBow KINTAI標準出力", value: 0 },
      { text: "ジョブカン給与計算", value: 1 },
      { text: "freee", value: 2 },
      // { text: "弥生給与", value: 3 },
    ],
    selected_format: 0,
    payment_date: "",
  }),

  mounted: function () {
    this.loadData();
  },

  // watch: {
  //   start_attend_date() {
  //     this.checkFreeLogRestrict();
  //   },
  //   end_attend_date() {
  //     this.checkFreeLogRestrict();
  //   },
  // },

  methods: {
    async outputCsv() {
      if (
        Date.parse(this.end_attend_date) < Date.parse(this.start_attend_date)
      ) {
        alert("出力期間の終了日を開始日以後の日付に設定してください。");
        return;
      }
      if (this.selected_staffs.length == 0) {
        alert("職員を選択してください。");
        return;
      }
      if (!this.$refs.requiredForms.validate(true)) return;
      if (this.selected_format == 1) {
        let returnValue = await this.$refs.paymentDate.open(true);
        if (returnValue != false) {
          this.payment_date = returnValue;
        } else {
          return;
        }
      }

      if (!confirm("選択した内容で出力しますがよろしいですか？")) {
        return;
      }

      let staffIds = [];
      this.selected_staffs.forEach((staff) => {
        staffIds.push(String(staff.id));
      });

      const params = {
        start_attendancedate: this.start_attend_date,
        end_attendancedate: this.end_attend_date,
        staff_ids: staffIds,
        format: this.selected_format,
        payment_date: this.payment_date,
      };
      this.postJsonCheck(
        window.base_url + "/api/attenddatalink/csv",
        params,
        (res) => {
          let blob;
          if (this.selected_format == 3) {
            //SJISで出力
            let csv = new Encoding.stringToCode(res.data);
            csv = Encoding.convert(csv, "SJIS");
            csv = new Uint8Array(csv);
            blob = new Blob([csv]);
          } else {
            //UTF-8 BOM付きで出力
            blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data]);
          }
          const a = document.createElement("a");
          a.download =
            `attendance_datalink_` + this.now_date.replace(/-/g, "") + `.csv`;
          a.href = URL.createObjectURL(blob);
          a.click();
          this.snackbar = true;
        }
      );
    },

    loadData() {
      this.selected_staffs = [];
      // 該当するステータスの職員リストを取得する
      this.postJson(
        window.base_url + "/api/attenddatalink/list",
        { s_statuses: this.selected_status },
        (res) => {
          this.staffs = [];
          if (res.data.staffs_details != null) {
            res.data.staffs_details.forEach((data) => {
              this.staffs.push({
                id: data.id,
                name: data.s_name,
                job: data.job_name,
                status: data.s_status,
              });
            });
            if (this.start_attend_date == "") {
              this.start_attend_date = res.data.start_date;
            }
            if (this.end_attend_date == "") {
              this.end_attend_date = res.data.end_date;
            }
          }
        }
      );
    },

    clickRow(item, params) {
      if (params.isSelected == false) {
        this.selected_staffs.push(item);
      } else {
        this.selected_staffs.splice(
          this.selected_staffs.findIndex((staff) => staff === item),
          1
        );
      }
    },

    checkFreeLogRestrict() {
      this.postJson(
        window.base_url + "/api/attenddatalink/restrict",
        { start_date: this.start_attend_date, end_date: this.end_attend_date },
        (res) => {
          if (res.data.code == 201) {
            this.isRestricted = true;
            alert(res.data.message);
            return;
          } else if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }
          this.isRestricted = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.max-area-component {
  height: 100%;
  .attenddatalink-table {
    height: 100%;
    max-height: calc(100% - 80px - 44px - 45px);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
</style>