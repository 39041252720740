<template>
  <v-dialog v-model="isActive" persistent max-width="300px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">【休憩時間入力】</span>
      </v-card-title>
      <v-card-text class="text-center">
        <div>休憩時間を入力してください</div>
        <time-picker :hh.sync="hh" :mm.sync="mm" :hours="hours" class="ma-4" />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-col>
          <v-row no-gutters justify="center">
            <v-btn class="font-weight-bold" color="primary" large @click="agree"
              >OK</v-btn
            >
          </v-row>
          <v-row no-gutters class="mt-5" justify="center">
            <v-btn v-show="showCancel" color="cancel" large text @click="cancel"
              ><span class="text-caption">キャンセル</span></v-btn
            >
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TimePicker from "../common/TimePicker";
export default {
  components: {
    TimePicker,
  },
  data: () => ({
    isActive: false,
    showCancel: false,
    hh: 0, // 休憩時間（時）
    mm: 0, // 休憩時間（分）
    hours: Array(24)
      .fill()
      .map((_, i) => i),
    resolve: null,
    reject: null,
  }),
  computed: {
    // 休憩時間（分単位）
    restTime() {
      return this.hh * 60 + this.mm;
    },
  },
  methods: {
    open(restTime, showCancel = false) {
      this.isActive = true;
      this.showCancel = showCancel;
      this.assignRestTime(restTime);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      console.log(this.restTime);
      this.resolve(this.restTime);
      this.isActive = false;
    },
    cancel() {
      this.resolve(false);
      this.isActive = false;
    },
    assignRestTime(restTime) {
      if (restTime / 60 < 1) {
        this.mm = restTime;
      } else {
        this.hh = Math.floor(restTime / 60);
        this.mm = restTime - this.hh * 60;
      }
    },
  },
};
</script>

<style></style>
