<template>
  <div class="max-area-component">
    <v-row no-gutters align="center">
      <v-col>
        <TextDayChangePicker v-model="date" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="7">
        <v-text-field v-model="staffName" label="職員名"></v-text-field>
      </v-col>
      <!-- <v-col class="ml-8">
        <v-select
          v-model="attendanceDiv"
          :items="div"
          label="区分"
          clearable
        ></v-select>
      </v-col>
      <v-col class="ml-8">
        <v-checkbox v-model="serchOncall" label="オンコール当番"></v-checkbox>
      </v-col> -->
    </v-row>
    <v-row no-gutters class="mb-2">
      <v-col>
        <v-btn
          color="append"
          class="mr-2 font-weight-bold white--text"
          :disabled="isRestricted"
          @click="getDates()"
        >
          <v-icon left>mdi-magnify</v-icon>検索
        </v-btn>
        <v-btn
          color="primary"
          class="mr-2 font-weight-bold"
          :disabled="isRestricted"
          @click="downloadExcel()"
        >
          <v-icon left>mdi-export-variant</v-icon>Excel出力
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      height="100%"
      no-data-text="データが見つかりませんでした。"
      :headers="displayHeaders"
      :items="attends"
      item-key="table_key"
      @click:row="clickRow"
      hide-default-footer
      disable-pagination
      fixed-header
      class="mb-3 elevation-1 attend-day-expand-table"
    >
      <template v-slot:[`item.staff_name`]="{ item }">
        <div class="d-flex align-center">
          <div class="mr-auto">{{ item.staff_name }}</div>
          <v-btn
            class="ml-auto font-weight-bold"
            color="primary"
            :to="`/attendprivate/${item.staff_id}?yearmonth=${dateForManth}`"
            >月次</v-btn
          >
        </div>
      </template>
      <template v-slot:[`item.middle_time`]="{ item }">
        <div v-for="v in item.attend_middles" :key="v.id">
          {{ v.middle_time }}
        </div>
      </template>
      <!-- <template v-slot:[`header.staff_over_time_sum`]="{ header }">
        {{ header.text }}
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-help-circle</v-icon>
          </template>
          <div>対象年月の1日〜対象年月日までの</div>
          <div>合計残業時間が表示されます</div>
        </v-tooltip>
      </template> -->
      <!-- <template v-slot:[`item.staff_over_time_sum`]="{ item }">
        <div class="d-flex align-center">
          <div class="mr-3">
            {{ minuteToStrHours(item.staff_over_time_sum) }}
          </div>
        </div>
      </template> -->
      <template v-slot:[`item.memo`]="{ item }">
        <div style="white-space: pre-line">
          {{ item.memo }}
        </div>
      </template>
      <template v-slot:[`item.work_memo`]="{ item }">
        <div
          style="white-space: pre-line"
          v-for="v in item.attend_middles"
          :key="v.id"
        >
          {{ v.memo }}
        </div>
      </template>
      <template v-slot:[`item.file_upload`]="{ item }">
        <div v-if="item.file_infos">◯</div>
      </template>
    </v-data-table>
    <LoadingDialog :loading="loading" />
    <AttendPrivateDialog
      ref="dialog"
      :attend="attend"
      :staffId="staffId"
      @update="updated()"
      :checkDisable="checkDisable"
      :month="date.substr(0, 7)"
    />
    <v-snackbar bottom v-model="snackbar" :timeout="1000">
      保存しました。
    </v-snackbar>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import AttendMixin from "./AttendMixin";
import AttendPrivateDialog from "./AttendPrivateDialog";
import TextDayChangePicker from "../common/TextDayChangePicker";
import LoadingDialog from "../common/LoadingDialog";

export default {
  mixins: [axiosMixin, AttendMixin, commonMixin],

  components: {
    AttendPrivateDialog,
    TextDayChangePicker,
    LoadingDialog,
  },

  data: (vm) => ({
    date: vm.dateJp(new Date()),
    attendanceDiv: null,
    serchOncall: false,
    staffName: "",
    headers: [
      { text: "職員名", value: "staff_name", width: "280px" },
      { text: "状態", value: "staff_status", width: "100px" },
      { text: "区分", value: "type_disp", width: "80px" },
      // { text: "オンコール当番", value: "is_oncall_disp", width: "100px" },
      // { text: "シフト", value: "shift_from_to", width: "140px" },
      { text: "実績", value: "work_from_to", width: "140px" },
      { text: "ログ", value: "log", width: "80px" },
      { text: "治験時間", value: "work_time", width: "100px" },
      { text: "時間外", value: "night_time", width: "100px" },
      // { text: "休憩時間", value: "refresh_time", width: "100px" },
      // { text: "残業時間", value: "over_time", width: "100px" },
      { text: "業務時間", value: "middle_time", width: "100px" },
      // { text: "合計残業時間", value: "staff_over_time_sum", width: "160px" },
      { text: "治験情報", value: "memo", width: "180px" },
      { text: "業務メモ", value: "work_memo", width: "260px" },
      {
        text: "添付ファイル",
        value: "file_upload",
        width: "140px",
        align: "center",
        sortable: false,
      },
    ],
    headerEnables: [
      { text: "治験開始住所", value: "start_address", width: "260px" },
      { text: "治験終了住所", value: "end_address", width: "260px" },
    ],
    headerAfter: [{ text: "備考", value: "remarks", width: "260px" }],
    attends: [],
    attend: {},
    checkDisable: {},
    snackbar: false,
    staffId: 0,
    isRestricted: false,
  }),

  computed: {
    dateForParameter() {
      if (!this.date) return null;
      const [year, month, day] = this.date.split("-");
      return `${year}${month}${day}`;
    },
    searchOnCallForParameter() {
      if (this.serchOncall) {
        return 1;
      } else {
        return 0;
      }
    },
    dateForManth() {
      if (!this.date) return null;
      const [year, month] = this.date.split("-");
      return `${year}-${month}`;
    },
    displayHeaders() {
      if (this.checkDisable.address_enable) {
        return this.headers.concat(this.headerEnables).concat(this.headerAfter);
      }
      return this.headers.concat(this.headerAfter);
    },
  },

  watch: {
    dateForParameter() {
      this.$router.replace(`attendday?date=${this.date}`);
      this.getDates();
    },
  },

  mounted() {
    if (this.$route.query.date && this.date !== this.$route.query.date) {
      this.date = this.$route.query.date;
    } else {
      this.getDates();
    }
  },

  methods: {
    clickRow(row) {
      this.attend = row;
      this.staffId = row.staff_id;
      this.$refs.dialog.open();
    },
    updated() {
      this.snackbar = true;
      this.getDates();
    },
    getDates() {
      this.postJson(
        window.base_url + "/api/attend/office/list",
        {
          tag_ymd: this.dateForParameter,
          attendance_div: this.attendanceDiv,
          staff_name: this.staffName,
          is_oncall: this.searchOnCallForParameter,
        },
        (res) => {
          if (res.data.code == 201) {
            this.attends = [];
            this.isRestricted = true;
            alert(res.data.message);
            return;
          } else if (res.data.code > 0) {
            alert(res.data.message);
            return;
          }
          this.isRestricted = false;
          this.attends = res.data.staff_attends;
          this.checkDisable = res.data.check_disable;
        }
      );
    },
    downloadExcel() {
      const param = {
        tag_ymd: this.dateForParameter,
      };
      this.postJsonBlobResCheck(
        window.base_url + "/api/attend/office/download",
        param,
        (res) => {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = `治験実施状況_${this.dateForParameter}.xlsx`;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.max-area-component {
  .attend-day-expand-table {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0px);
  }
}
</style>