<template>
  <div class="inline-frame-area-info">
    <div class="iframe-wrapper" :style="{ height: height + 'px' }">
      <iframe :src="url" :style="{ height: height + 'px' }" />
    </div>
  </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";

export default {
  mixins: [axiosMixin],
  props: {
    isChikenUser: Boolean,
  },
  data: () => ({
    url: "",
    height: 0,
  }),

  mounted() {
    this.postJsonCheck(
      window.base_url + "/iframe_url/get",
      {
        is_chiken_user: this.isChikenUser,
      },
      (res) => {
        if (res.data.notice) {
          this.url = res.data.notice.url;
          this.height = res.data.notice.height;
        }
      }
    );
  },
};
</script>

<style lang="scss">
.inline-frame-area-info {
  text-align: center;
  display: inline-block;

  .agree-checkbox {
    display: inline-block;
  }

  .iframe-wrapper {
    width: calc(100% - 16px);
    margin: 8px;

    iframe {
      width: 100%;
      border: solid 2px #fabe00;
    }
  }
}
</style>