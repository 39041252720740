<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px">
    <v-card class="pa-4">
      <v-card-title>
        <span class="headline"
          >勤務形態一覧表{{ monthFormat(yearmonth) }}設定</span
        >
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row no-gutters>
            <v-col>
              <div class="mx-3">常勤職員が勤務すべき１日あたりの勤務時間</div>
              <v-text-field
                style="width: 200px"
                class="mx-3"
                v-model.number="config.working_hours"
                label="勤務時間"
                :rules="[rules.floatNumeric]"
                maxlength="5"
                suffix="時間"
              ></v-text-field>
              <div class="caption mx-3">
                ※空欄の場合は「事業所設定」に登録された時間で計算されます。
              </div>
              <div class="caption mx-3">
                （{{ config.office_working_hours }}時間）
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col>
              <div class="mx-3">常勤職員が通常勤務すべき日数</div>
              <v-row no-gutters class="mx-0">
                <v-text-field
                  style="max-width: 200px"
                  class="mx-3"
                  label="4週表記"
                  v-model.number="config.working_days_4weeks"
                  :rules="[rules.digitNumeric]"
                  maxlength="2"
                  suffix="日"
                ></v-text-field>
                <v-text-field
                  style="max-width: 200px"
                  class="mx-3"
                  label="歴月表記"
                  v-model.number="config.working_days_month"
                  :rules="[rules.digitNumeric]"
                  maxlength="2"
                  suffix="日"
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-row>
              <div class="caption mx-3">
                ※空欄の場合は「休日カレンダー」に登録された公休日から自動計算されます。
              </div>
              <div class="caption mx-3">
                （4週表記：{{ config.calc_working_days_4weeks }}日 歴月表記：{{
                  config.calc_working_days_month
                }}日）
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="font-weight-bold" color="cancel" text @click="close"
          >閉じる</v-btn
        >
        <v-btn class="font-weight-bold" color="primary" text @click="saveConfig"
          >保存</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- ローディング -->
    <loading-dialog :loading="loading"></loading-dialog>
  </v-dialog>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import LoadingDialog from "../common/LoadingDialog";

export default {
  components: {
    LoadingDialog,
  },
  mixins: [axiosMixin],
  data: () => ({
    config: {},
    loading: false,
    dialog: false,
    rules: {
      floatNumeric: (v) =>
        !v ||
        /^([1-9][0-9]{0,1}|0)(\.[0-9]{0,2})?$/.test(v) ||
        "整数２桁、少数２桁以内でご記入ください",
      digitNumeric: (v) =>
        !v || /^[1-9][0-9]{0,1}$/.test(v) || "２桁以内の整数でご記入ください",
    },
    errorMessages: "",
  }),
  props: {
    yearmonth: { type: Number },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.fetchConfig();
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    monthFormat(yearmonth) {
      if (yearmonth == null) {
        return "";
      }
      const month = Number(String(yearmonth).substr(4, 2));
      return `${month}月`;
    },
    fetchConfig() {
      if (this.yearmonth == null || this.yearmonth <= 0) return;

      const param = { yearmonth: this.yearmonth };
      this.postJson(
        window.base_url + "/api/worktypelist/config/fetch",
        param,
        (res) => {
          console.log(res);
          if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }
          this.config = res.data.config;
        }
      );
    },
    saveConfig() {
      if (!this.$refs.form.validate()) {
        alert("入力内容に不備があります。");
        return;
      }
      if (this.config == null) {
        alert("設定画面を開き直してください。");
        return;
      }

      if (!confirm("登録内容を保存します。よろしいですか？")) return;

      // ゼロ以外はnullセット
      if (!this.config.working_hours && this.config.working_hours !== 0) {
        this.config.working_hours = null;
      }
      if (
        !this.config.working_days_4weeks &&
        this.config.working_days_4weeks !== 0
      ) {
        this.config.working_days_4weeks = null;
      }
      if (
        !this.config.working_days_month &&
        this.config.working_days_month !== 0
      ) {
        this.config.working_days_month = null;
      }

      const param = { config: this.config };
      console.log(param);
      this.postJson(
        window.base_url + "/api/worktypelist/config/update",
        param,
        (res) => {
          console.log(res);
          if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }
          this.$emit("updated", "保存しました。");
          this.close();
        }
      );
    },
  },
};
</script>