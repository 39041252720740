<template :ref="updateDialog">
  <v-row no-gutters justify="center">
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
      <v-form ref="form">
        <v-card>
          <LoadingDialog :loading="attendFileLoading" />
          <v-card-title>
            <span class="headline">実績編集</span>
          </v-card-title>
          <v-card-text id="scroll-target">
            <v-row no-gutters>
              <v-col cols="4">
                <TextDatePicker
                  label="日付"
                  v-model="attendDate"
                  :rules="[(v) => !!v || '日付は必須入力です。']"
                  textClass="m-5"
                  :disabled="attend != null"
                  :min="minDate"
                  :max="maxDate"
                />
              </v-col>
              <!-- <v-col cols="4">
                <v-select
                  v-model="type"
                  :items="div"
                  label="区分"
                  :disabled="checkDisable.work_disable"
                ></v-select>
              </v-col> -->
            </v-row>
            <v-divider />
            <!-- <v-row
              no-gutters
              v-show="type != shiftType.rest && type != shiftType.over"
            >
              <v-col cols="10">
                <div class="d-flex align-center">
                  <TimePicker
                    :hh.sync="shiftStartHour"
                    :mm.sync="shiftStartMinute"
                    class="mr-5"
                    hoursLabel="シフト"
                    :disabled="
                      checkDisable.shift_disable ||
                      type == shiftType.over ||
                      type == shiftType.rest
                    "
                    :hoursRules="[ruleShiftHour(shiftStartTime)]"
                    :minutesRules="[ruleShiftMinute(shiftStartTime)]"
                  />
                  <div class="px-3">~</div>
                  <TimePicker
                    :hh.sync="shiftEndHour"
                    :mm.sync="shiftEndMinute"
                    class="mr-5"
                    :disabled="
                      checkDisable.shift_disable ||
                      type == shiftType.over ||
                      type == shiftType.rest
                    "
                    :hoursRules="[ruleShiftHour(shiftEndTime)]"
                    :minutesRules="[ruleShiftMinute(shiftEndTime)]"
                  />
                  <div class="mx-2"></div>
                  <TimeCountPicker
                    v-model="shiftRestTime"
                    hoursLabel="休憩"
                    :disabled="
                      checkDisable.shift_disable ||
                      type == shiftType.over ||
                      type == shiftType.rest
                    "
                  />
                </div>
              </v-col>
            </v-row> -->
            <v-row
              class="py-3"
              no-gutters
              v-show="type != shiftType.rest"
              align="center"
            >
              <v-col cols="10">
                <div class="d-flex align-center">
                  <TimePicker
                    :hh.sync="attendStartHour"
                    :mm.sync="attendStartMinute"
                    class="mr-5"
                    hoursLabel="実績"
                    :disabled="
                      checkDisable.work_disable || type == shiftType.rest
                    "
                  />
                  <div class="px-3">~</div>
                  <TimePicker
                    :hh.sync="attendEndHour"
                    :mm.sync="attendEndMinute"
                    class="mr-5"
                    :disabled="
                      checkDisable.work_disable || type == shiftType.rest
                    "
                  />
                  <!-- <div class="mx-2"></div>
                  <TimeCountPicker
                    v-model="attendRestTime"
                    hoursLabel="休憩"
                    :disabled="
                      checkDisable.work_disable || type == shiftType.rest
                    "
                  /> -->
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="10">
                <v-textarea
                  label="治験情報"
                  dense
                  auto-grow
                  v-model="attendMemo"
                />
              </v-col>
            </v-row>
            <!-- <v-row no-gutters v-show="type != shiftType.rest">
              <v-col cols="9">
                <span>勤務時間</span>
                <span> (残り</span>
                <span
                  :class="{ 'actual-time-error': shiftTimeDiff != 0 }"
                  v-if="type == shiftType.normal"
                >
                  {{ shiftHint }}</span
                >
                <span :class="{ 'actual-time-error': actualTimeDiff < 0 }">
                  {{ actualHint }}</span
                >
                <span>)</span>
                <div
                  class="d-flex align-center"
                  v-for="job in attendJobs"
                  :key="job.job_id"
                >
                  <div class="mr-5" style="width: 100px">
                    {{ job.job_name }}
                  </div>
                  <TimeCountPicker
                    v-model="job.plan_time"
                    class="mr-5"
                    hoursLabel="シフト"
                    :disabled="
                      checkDisable.shift_disable ||
                      type == shiftType.rest ||
                      type == shiftType.over
                    "
                    v-show="type != shiftType.over"
                    :hoursRules="[ruleShiftJobHour]"
                    :minutesRules="[ruleShiftJobMinute]"
                  />
                  <TimeCountPicker
                    v-model="job.actual_time"
                    hoursLabel="実績"
                    class="working-job-time"
                    :disabled="
                      checkDisable.work_disable || type == shiftType.rest
                    "
                    :hoursRules="[ruleActualJobHour]"
                    :minutesRules="[ruleActualJobMinute]"
                  />
                </div>
              </v-col>
            </v-row> -->
            <!-- <v-row no-gutters class="mb-3">
              <v-btn
                class="font-weight-bold"
                text
                color="alert"
                @click="clearAchive"
                :disabled="checkDisable.work_disable || type == shiftType.rest"
                >実績時間クリア</v-btn
              >
            </v-row> -->
            <v-divider />
            <v-row class="py-3" no-gutters v-show="type != shiftType.rest">
              <v-col cols="9">
                <div>業務時間</div>
                <template v-for="(middle, index) in attendMiddles">
                  <div class="d-flex align-center" :key="index">
                    <TimePicker
                      :hh.sync="middle.starttime_middle_hour"
                      :mm.sync="middle.starttime_middle_minute"
                      class="mr-5"
                      hoursLabel="開始"
                      :disabled="
                        checkDisable.work_disable || type == shiftType.rest
                      "
                    />
                    <div class="mx-3">~</div>
                    <TimePicker
                      :hh.sync="middle.endtime_middle_hour"
                      :mm.sync="middle.endtime_middle_minute"
                      hoursLabel="終了"
                      :disabled="
                        checkDisable.work_disable || type == shiftType.rest
                      "
                    />
                    <v-btn
                      class="ml-2 font-weight-bold"
                      color="alert"
                      dark
                      @click="deleteMiddle(index)"
                      :disabled="
                        checkDisable.work_disable || type == shiftType.rest
                      "
                    >
                      削除
                    </v-btn>
                  </div>
                  <div :key="'memo' + index">
                    <v-textarea
                      label="業務メモ"
                      dense
                      auto-grow
                      v-model="middle.memo"
                    />
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row class="py-3" no-gutters v-show="type != shiftType.rest">
              <v-col cols="9">
                <v-btn
                  class="font-weight-bold"
                  color="append"
                  dark
                  @click="addMiddle"
                  :disabled="
                    checkDisable.work_disable || type == shiftType.rest
                  "
                >
                  業務時間追加
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-3" no-gutters>
              <v-col cols="9">
                <v-text-field
                  v-model="remarks"
                  label="備考"
                  :disabled="checkDisable.work_disable"
                />
              </v-col>
            </v-row>
            <template v-for="(fileInfo, index) in fileInfos">
              <v-row
                class="py-3"
                no-gutters
                v-if="!fileInfo.is_delete_file"
                :key="'fileInfo-' + `${fileInfo.file_id}` + index"
              >
                <v-col>
                  <file-input
                    :fileParam="fileParams[index]"
                    :fileInfo="fileInfo"
                    @change="changeImage(fileParams[index], fileInfo, index)"
                    @delete="deleteImage(index)"
                  />
                </v-col>
                <v-btn
                  class="ml-2 font-weight-bold"
                  color="alert"
                  dark
                  @click="deleteFileInput(index)"
                >
                  削除
                </v-btn>
              </v-row>
            </template>
            <v-row class="py-3" no-gutters>
              <v-btn
                class="font-weight-bold"
                color="append"
                dark
                @click="addFile"
              >
                添付ファイル追加
              </v-btn>
            </v-row>
            <!-- <v-row no-gutters>
              <v-col>
                <file-input
                  :fileParam="fileParam"
                  :fileInfo="fileInfo"
                  @change="changeImage(fileParam.file)"
                  @delete="deleteImage"
                />
              </v-col>
            </v-row> -->
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="font-weight-bold"
              :disabled="checkDisable.work_disable"
              v-show="
                attend != null && this.attend.id && type == shiftType.over
              "
              color="alert"
              text
              @click="deleteData"
              >削除</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn class="font-weight-bold" color="cancel" text @click="close"
              >閉じる</v-btn
            >
            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="save"
              :disabled="checkDisable.work_disable"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import AttendMixin from "./AttendMixin";
import TimePicker from "../common/TimePicker";
import TextDatePicker from "../common/TextDatePicker";
import FileInput from "./FileInput";
import LoadingDialog from "../common/LoadingDialog";

// const reader = new FileReader();
export default {
  mixins: [axiosMixin, AttendMixin, commonMixin],

  components: {
    TimePicker,
    TextDatePicker,
    FileInput,
    LoadingDialog,
  },

  props: {
    attend: Object,
    staffJobs: Array,
    month: String,
    checkDisable: Object,
    staffId: Number,
  },

  data: () => ({
    dialog: false,
    type: 0,
    is_oncall: 0,
    shiftStartHour: 0,
    shiftStartMinute: 0,
    shiftEndHour: 0,
    shiftEndMinute: 0,
    shiftRestTime: 0,
    attendStartHour: 0,
    attendStartMinute: 0,
    attendEndHour: 0,
    attendEndMinute: 0,
    attendRestTime: 0,
    attendDate: null,
    attendJobs: [],
    attendMiddles: [],
    remarks: "",
    attendMemo: "",
    attendImageId: 0,
    fileParams: [
      {
        file: null,
        previewSrc: "",
      },
    ],
    fileInfos: [
      {
        attendance_id: null,
        file_name: "",
        file_id: 0,
        file_type: "",
        is_delete_file: false,
        file_hash: "",
        updated_at: "",
      },
    ],
    attendFiles: [],
    attendFileLoading: false,
  }),

  computed: {
    normalDiv() {
      return this.div.filter((v) => {
        return v.value != 2;
      });
    },
    restDiv() {
      return this.div.filter((v) => {
        return v.value == 2;
      });
    },
    formatAttendDate() {
      return this.attendDate.replace(/-/g, "/");
    },
    shiftStartTime() {
      return this.formatDate(
        this.attendDate,
        this.shiftStartHour,
        this.shiftStartMinute
      );
    },
    shiftEndTime() {
      return this.formatDate(
        this.attendDate,
        this.shiftEndHour,
        this.shiftEndMinute
      );
    },
    attendStartTime() {
      return this.formatDate(
        this.attendDate,
        this.attendStartHour,
        this.attendStartMinute
      );
    },
    attendEndTime() {
      return this.formatDate(
        this.attendDate,
        this.attendEndHour,
        this.attendEndMinute
      );
    },
    minDate() {
      let dt = new Date(this.month.replace(/-/g, "/") + "/01");
      dt = new Date(dt.getFullYear(), dt.getMonth(), 1);
      return this.dateJp(dt);
    },
    maxDate() {
      let dt = new Date(this.month.replace(/-/g, "/") + "/01");
      dt = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
      return this.dateJp(dt);
    },
    ruleActualJobMinute() {
      let actualTimes = 0;
      if (
        this.type != this.shiftType.rest &&
        this.attendStartTime !== null &&
        this.attendEndTime !== null
      ) {
        for (let i = 0; i < this.attendJobs.length; i++) {
          const v = this.attendJobs[i];
          actualTimes += v.actual_time;
        }
        if (actualTimes == 0) {
          return "";
        }
      }
      return true;
    },
    ruleActualJobHour() {
      if (this.ruleActualJobMinute === "") {
        return "勤務時間を入力してください";
      }
      return true;
    },
    ruleShiftJobMinute() {
      if (this.type != this.shiftType.normal) {
        return true;
      }
      if (this.shiftTimes == 0) {
        return "";
      }
      return true;
    },
    ruleShiftJobHour() {
      if (this.ruleShiftJobMinute === "") {
        return "シフト時間を入力してください";
      }
      return true;
    },
    actualTimes() {
      let actualTimes = 0;
      if (
        this.type != this.shiftType.rest &&
        this.attendStartTime !== null &&
        this.attendEndTime !== null
      ) {
        for (let i = 0; i < this.attendJobs.length; i++) {
          const v = this.attendJobs[i];
          actualTimes += v.actual_time;
        }
      }
      return actualTimes;
    },
    shiftStartMinutes() {
      return this.shiftStartHour * 60 + this.shiftStartMinute;
    },
    shiftEndMinutes() {
      return this.shiftEndHour * 60 + this.shiftEndMinute;
    },
    attendStartMinutes() {
      return this.attendStartHour * 60 + this.attendStartMinute;
    },
    startMinutes() {
      if (this.attendStartMinutes == 0) {
        return 0;
      }
      // シフトが存在し、出勤予定時刻より前に出勤していた場合、退勤時刻と出勤予定時刻の差から実績を算出する
      if (
        this.shiftStartMinutes > 0 &&
        this.attendStartMinutes < this.shiftStartMinutes
      ) {
        return this.shiftStartMinutes;
      }
      return this.attendStartMinutes;
    },
    attendEndMinutes() {
      return this.attendEndHour * 60 + this.attendEndMinute;
    },
    attendMiddleMinutes() {
      let resultMinutes = 0;
      this.attendMiddles.forEach((attendMiddle) => {
        let starttimeMiddles =
          attendMiddle.starttime_middle_hour * 60 +
          attendMiddle.starttime_middle_minute;
        let endtimeMiddles =
          attendMiddle.endtime_middle_hour * 60 +
          attendMiddle.endtime_middle_minute;
        resultMinutes += endtimeMiddles - starttimeMiddles;
      });
      if (resultMinutes < 0) {
        return 0;
      }
      return resultMinutes;
    },
    actualTimeDiff() {
      return (
        this.attendEndMinutes -
        this.startMinutes -
        this.attendRestTime -
        this.attendMiddleMinutes -
        this.actualTimes
      );
    },
    shiftTimes() {
      let shiftTimes = 0;
      for (let i = 0; i < this.attendJobs.length; i++) {
        const v = this.attendJobs[i];
        shiftTimes += v.plan_time;
      }
      return shiftTimes;
    },
    shiftTimeDiff() {
      const diff =
        this.shiftEndMinutes -
        this.shiftStartMinutes -
        this.shiftRestTime -
        this.shiftTimes;
      return diff;
    },
    shiftHint() {
      return `シフト時間:${this.time2HourStr(this.shiftTimeDiff)}`;
    },
    actualHint() {
      return `実績時間:${this.time2HourStr(this.actualTimeDiff)}`;
    },
  },

  watch: {
    dialog() {
      if (this.dialog) {
        if (this.attend) {
          this.type = this.attend.type;
          this.is_oncall = this.attend.is_oncall;
          this.attendDate = this.attend.target_date
            .substr(0, 10)
            .replace(/\//g, "-");
          this.shiftStartHour = this.attend.starttime_shift_hour;
          this.shiftStartMinute = this.attend.starttime_shift_minute;
          this.shiftEndHour = this.attend.endtime_shift_hour;
          this.shiftEndMinute = this.attend.endtime_shift_minute;
          this.shiftRestTime = this.attend.rest_time_shift;
          this.attendStartHour = this.attend.starttime_work_hour;
          this.attendStartMinute = this.attend.starttime_work_minute;
          this.attendEndHour = this.attend.endtime_work_hour;
          this.attendEndMinute = this.attend.endtime_work_minute;
          this.attendRestTime = this.attend.rest_time_work;
          this.attendJobs = this.attend.attend_job.map((v) => {
            return {
              id: v.id,
              job_id: v.job_id,
              job_name: v.job_name,
              plan_time: v.plan_time,
              actual_time: v.actual_time,
            };
          });
          this.attendMiddles = this.attend.attend_middles.map((v) => {
            return {
              id: v.id,
              starttime_middle_hour: v.starttime_middle_hour,
              starttime_middle_minute: v.starttime_middle_minute,
              endtime_middle_hour: v.endtime_middle_hour,
              endtime_middle_minute: v.endtime_middle_minute,
              memo: v.memo,
            };
          });
          this.remarks = this.attend.remarks;
          this.attendMemo = this.attend.memo;
          this.fileParams = [];
          this.fileInfos = [];
          if (this.attend.file_infos) {
            this.fileInfos = this.attend.file_infos.map((v) => {
              return {
                attendance_id: v.attendance_id,
                file_name: v.file_name,
                file_id: v.file_id,
                file_type: v.file_type,
                is_delete_file: v.is_delete_file,
                file_hash: v.file_hash,
                updated_at: v.updated_at,
              };
            });
            this.attendFileLoading = true;
            this.fileInfos.forEach((fileInfo, index) => {
              this.fileParams.push({
                file: [],
                previewSrc: "",
              });
              if (fileInfo.file_id == 0) return;
              if (
                fileInfo.file_type == "image/jpeg" ||
                fileInfo.file_type == "image/png"
              ) {
                var fileReq = {
                  file_id: fileInfo.file_id,
                  staff_id: this.staffId,
                };
                const url =
                  window.base_url + "/api/attendprivate/thumbnail/download";
                this.postJsonBlobResCheck(url, fileReq, (res) => {
                  var blob = new Blob([res.data], {
                    type: "application/octet-binary",
                  });
                  // BlobからFileオブジェクト作成
                  const file = new File([blob], fileInfo.file_name, {
                    type: fileInfo.file_type,
                  });
                  this.fileParams[index].file = file;
                  this.fileParams[index].previewSrc = URL.createObjectURL(file);
                  if (index + 1 >= this.fileInfos.length) {
                    this.attendFileLoading = false;
                  }
                });
              } else {
                const file = new File([], fileInfo.file_name, {
                  type: fileInfo.file_type,
                });

                this.fileParams[index].file = file;
                this.fileParams[index].previewSrc = "";
                if (index + 1 >= this.fileInfos.length) {
                  this.attendFileLoading = false;
                }
              }
            });
          }
        } else {
          this.type = 2;
          this.is_oncall = 0;
          this.attendDate = this.month + "-01";
          this.shiftStartHour = null;
          this.shiftStartMinute = null;
          this.shiftEndHour = null;
          this.shiftEndMinute = null;
          this.shiftRestTime = 0;
          this.attendStartHour = null;
          this.attendStartMinute = null;
          this.attendEndHour = null;
          this.attendEndMinute = null;
          this.attendRestTime = 0;
          this.attendJobs = this.staffJobs.map((v) => {
            return {
              job_id: v.job_id,
              job_name: v.job_name,
              plan_time: 0,
              actual_time: 0,
            };
          });
          this.attendMiddles = [];
          this.remarks = "";
          this.attendMemo = "";
          this.attendImageId = 0;
        }
        if (
          !this.attend ||
          this.attendImageId == 0 ||
          this.attendImageId == undefined
        )
          return;
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.fileParams = [
        {
          file: [],
          previewSrc: "",
        },
      ];
      this.fileInfos = [
        {
          attendance_id: null,
          file_name: "",
          file_id: 0,
          file_type: "",
          is_delete_file: false,
          file_hash: "",
          updated_at: "",
        },
      ];
      this.dialog = false;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.attendTimeCheck() || !this.middleTimeCheck()) {
        return;
      }
      if (!confirm("変更した内容を保存します。よろしいですか？")) {
        return;
      }
      let paramJobs = this.attendJobs.map((v) => {
        return {
          id: v.id,
          actual_time: Number(v.actual_time),
          plan_time: Number(v.plan_time),
          job_id: v.job_id,
        };
      });
      let paramMiddles = this.attendMiddles.map((v) => {
        const format = (date, hour, minute) => {
          const datetime = this.formatDate(date, hour, minute);
          return datetime ? new Date(datetime).toISOString() : null;
        };
        return {
          id: v.id,
          starttime_middle: format(
            this.attendDate,
            v.starttime_middle_hour,
            v.starttime_middle_minute
          ),
          endtime_middle: format(
            this.attendDate,
            v.endtime_middle_hour,
            v.endtime_middle_minute
          ),
          memo: v.memo,
        };
      });
      if (this.type == 0) {
        this.shiftStartHour = null;
        this.shiftStartMinute = null;
        this.shiftEndHour = null;
        this.shiftEndMinute = null;
        this.shiftRestTime = 0;
        this.attendStartHour = null;
        this.attendStartMinute = null;
        this.attendEndHour = null;
        this.attendEndMinute = null;
        this.attendRestTime = 0;
        paramJobs = this.attendJobs.map((v) => {
          return {
            id: v.id,
            actual_time: 0,
            plan_time: 0,
            job_id: v.job_id,
          };
        });
        paramMiddles = [];
      }
      if (this.type == 2) {
        this.shiftStartHour = null;
        this.shiftStartMinute = null;
        this.shiftEndHour = null;
        this.shiftEndMinute = null;
        this.shiftRestTime = 0;
        paramJobs.forEach((job) => {
          job.plan_time = 0;
        });
      }

      //複数ファイル登録
      this.attendFileLoading = true;
      let fileIds = await this.saveFiles();
      if (fileIds == -1) return;
      const param = {
        is_oncall: this.is_oncall,
        attendance: {
          id: this.attend ? this.attend.id : 0,
          attendance_date: new Date(this.formatAttendDate).toISOString(),
          attendance_div: this.type,
          starttime_shift: this.shiftStartTime
            ? new Date(this.shiftStartTime).toISOString()
            : null,
          endtime_shift: this.shiftEndTime
            ? new Date(this.shiftEndTime).toISOString()
            : null,
          starttime_archive: this.attendStartTime
            ? new Date(this.attendStartTime).toISOString()
            : null,
          endtime_archive: this.attendEndTime
            ? new Date(this.attendEndTime).toISOString()
            : null,
          rest_time_archive: Number(this.attendRestTime),
          rest_time_shift: Number(this.shiftRestTime),
          remarks: this.remarks,
          memo: this.attendMemo,
          updated_at: this.attend
            ? this.attend.updated_at
            : new Date().toISOString(),
        },
        attendance_jobs: paramJobs,
        attendance_middles: paramMiddles,
        file_ids: fileIds,
      };
      if (this.staffId) {
        param.staff_id = this.staffId;
      }
      this.postJson(
        window.base_url + "/api/attendprivate/update",
        param,
        (res) => {
          if (res.data.code > 0) {
            alert(res.data.message);
          } else {
            this.attendFileLoading = false;
            this.$emit("update", "保存しました。");
            this.close();
          }
        }
      );
    },
    deleteData() {
      if (confirm("データを削除します。よろしいですか？")) {
        if (this.attend && this.attend.id) {
          this.postJson(
            window.base_url + "/api/attendprivate/delete",
            {
              attendance: {
                id: this.attend.id,
                file_ids: this.attend.file_ids,
                updated_at: this.attend.updated_at,
              },
            },
            () => {
              this.$emit("update", "削除しました。");
              this.close();
            }
          );
        }
      }
    },
    async saveFiles() {
      let fileIds = [];
      await Promise.all(
        this.fileInfos.map(async (fileInfo, index) => {
          if (this.fileParams[index] == undefined) return;
          const saveFileParam = {
            staff_id: this.staffId,
            file_info: fileInfo,
          };
          // multipart/form-data によって複数形式のデータを扱えるようにするためFormData作成
          var formData = new FormData();
          formData.append("updateinfo", JSON.stringify(saveFileParam));
          formData.append(
            fileInfo.file_id + "_" + "file",
            this.fileParams[index].file
          );
          const url = window.base_url + "/api/attendprivate/file/save";
          let res = await this.postFormPromise(url, formData).catch(
            async (error) => {
              console.log("error", error);
              if (error.response && error.response.status == 401) {
                console.log("refToken");
                res = this.refTokenPromise(url, formData, false);
              } else {
                this.attendFileLoading = false;
                alert("サーバーでエラーが発生しました。");
                res = -1;
              }
            }
          );
          if (res == -1 || res == undefined) {
            this.attendFileLoading = false;
            return -1;
          }
          if (res.data.code > 0) {
            this.attendFileLoading = false;
            alert(res.data.message);
            return -1;
          } else {
            if (
              !(
                res.data.processed_file_id.file_id == 0 &&
                res.data.processed_file_id.delete_file_id == 0
              )
            ) {
              fileIds.push({
                file_id: res.data.processed_file_id.file_id,
                delete_file_id: res.data.processed_file_id.delete_file_id,
              });
            }
          }
        })
      );
      return fileIds;
    },
    addMiddle() {
      this.attendMiddles.push({
        id: 0,
        starttime_middle_hour: null,
        starttime_middle_minute: null,
        endtime_middle_hour: null,
        endtime_middle_minute: null,
        memo: "",
      });
    },
    deleteMiddle(index) {
      this.attendMiddles.splice(index, 1);
    },
    addFile() {
      this.getFileInfoDefault();
      this.getFileDefault();
    },
    deleteFileInput(index) {
      if (this.fileInfos[index].attendance_id == null) {
        this.fileParams.splice(index, 1);
        this.fileInfos.splice(index, 1);
      } else {
        this.deleteImage(index);
      }
    },
    clearAchive() {
      this.attendStartHour = null;
      this.attendStartMinute = null;
      this.attendEndHour = null;
      this.attendEndMinute = null;
      this.attendRestTime = 0;
      for (let i = 0; i < this.attendJobs.length; i++) {
        const v = this.attendJobs[i];
        v.actual_time = 0;
      }
    },
    attendTimeCheck() {
      //実績の出勤時間が退勤時間より大きい
      if (this.startMinutes >= this.attendEndMinutes) {
        alert("実績を正しく入力してください。");
        return false;
      }
      return true;
    },
    middleTimeCheck() {
      let result = true;
      //業務時間の合計の不整合
      if (this.attendMiddles.length > 0 && this.attendMiddleMinutes == 0) {
        result = false;
      }
      this.attendMiddles.forEach((attendMiddle) => {
        if (
          attendMiddle.starttime_middle_hour == null ||
          attendMiddle.starttime_middle_minute == null ||
          attendMiddle.endtime_middle_hour == null ||
          attendMiddle.endtime_middle_minute == null
        ) {
          result = false;
        }
        let starttimeMiddles =
          attendMiddle.starttime_middle_hour * 60 +
          attendMiddle.starttime_middle_minute;
        let endtimeMiddles =
          attendMiddle.endtime_middle_hour * 60 +
          attendMiddle.endtime_middle_minute;
        if (starttimeMiddles >= endtimeMiddles) {
          result = false;
        }
      });
      if (result == false) {
        alert("業務時間を正しく入力してください。");
      }
      return result;
    },
    actualTimeCheck() {
      //出退勤時間の差分と労働時間の不整合
      if (
        this.actualTimes >
        this.attendEndMinutes -
          this.startMinutes -
          this.attendRestTime -
          this.attendMiddleMinutes
      ) {
        alert("実績の合計が実働時間を超えています。");
        return false;
      }
      return true;
    },
    shiftTimeCheck() {
      if (this.type != this.shiftType.normal) {
        return true;
      }
      if (this.shiftStartMinutes >= this.shiftEndMinutes) {
        alert("シフトを正しく入力してください。");
        return false;
      }
      if (this.shiftTimeDiff != 0) {
        alert("シフトの合計がシフト時間と異なります。");
        return false;
      }
      return true;
    },
    time2HourStr(time) {
      let hour = 0;
      let minute = 0;
      if (time < 60) {
        minute = time;
      } else {
        hour = Math.floor(time / 60);
        minute = time % 60;
      }
      return `${hour}時間${minute}分`;
    },
    ruleShiftMinute(shiftTime) {
      if (this.type != this.shiftType.normal) {
        return true;
      }
      const start = shiftTime ? new Date(shiftTime).toISOString() : null;
      if (start === null) {
        return "";
      }
      return true;
    },
    ruleShiftHour(shiftTime) {
      if (this.ruleShiftMinute(shiftTime) === "") {
        return "時間を入力してください";
      }
      return true;
    },
    getFileDefault() {
      this.fileParams.push({
        file: [],
        previewSrc: "",
      });
    },
    getFileInfoDefault() {
      this.fileInfos.push({
        attendance_id: null,
        file_name: "",
        file_id: 0,
        file_type: "",
        is_delete_file: false,
        file_hash: "",
        updated_at: "",
      });
    },
    // inputFile(file) {
    //   if (!file) {
    //     this.deleteImage();
    //     return;
    //   }
    //   if (file.size > 10485760) {
    //     alert("ファイルサイズが10MB以上です。");
    //     return;
    //   }
    //   reader.onload = (file) => {
    //     this.fileParam.previewSrc = file.target.result;
    //   };
    //   reader.readAsDataURL(file);
    //   this.fileParam.file = file;
    //   this.fileInfo.is_delete_image = false;
    //   this.fileInfo.file_name = file.name;
    // },
    deleteImage(index) {
      this.fileInfos[index].is_delete_file = true;
      this.fileInfos[index].file_name = "";
      this.fileParams[index].file = [];
      this.fileParams[index].previewSrc = "";
    },
    changeImage(fileParam, fileInfo, index) {
      if (!fileParam.file) {
        this.deleteImage(index);
        return;
      }
      fileInfo.file_name = fileParam.file.name;
      fileParam.previewSrc = URL.createObjectURL(fileParam.file);
    },
  },
};
</script>

<style lang="scss">
.working-job-time {
  position: relative;
  .v-messages {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
}
</style>

<style lang="scss" scoped>
#scroll-target {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
#scroll-target > * {
  -webkit-transform: translateZ(0px);
}
.actual-time-error {
  color: red;
}
</style>