<template>
    <span>
        <span>
            <v-btn icon @click="prevMonth">
                <v-icon>mdi-calendar-arrow-left</v-icon>
            </v-btn>
        </span>
        <div style="width: 100px;display: inline-block;">
            <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field readonly
                        label="対象年月"
                        v-model="dateFormatted"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="innerValue" type="month" locale="ja" no-title @input="menu1 = false"></v-date-picker>
            </v-menu>
        </div>
        <span>
            <v-btn icon @click="nextMonth">
                <v-icon>mdi-calendar-arrow-right</v-icon>
            </v-btn>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            menu1: false,
        }
    },
	computed: {
		dateFormatted () {
			return this.dateFormat(this.innerValue)
        },
        innerValue: {
            get() {
                if (this.value) {
                    const year = String(this.value).substr(0, 4)
                    const month = String(this.value).substr(4, 2)
                    return new Date(year, month, 1).toISOString().substr(0, 7)
                } else {
                    return null
                }
            },
            set(value) {
                const [year, month] = value.split('-')
                const ym = Number(`${year}${month}`)
                this.$emit('input', ym)
                this.$emit('changeMonth', ym)
            }
        }
    },
    methods: {
        dateFormat(date) {
			if (!date) return null;
            const [year, month] = date.split('-')
			return `${year}年${month}月`
        },
		prevMonth() {
            const date = this.innerValue
            this.innerValue = new Date(date.substr(0, 4), parseInt(date.substr(5, 2)) - 1, 1).toISOString().substr(0, 7)
		},
		nextMonth() {
            const date = this.innerValue
            this.innerValue = new Date(date.substr(0, 4), parseInt(date.substr(5, 2)) + 1, 1).toISOString().substr(0, 7)
        },
    },

}
</script>