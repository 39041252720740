<template>
  <div class="d-flex align-center">
    <v-btn icon @click="addDay(-1)">
      <v-icon>mdi-calendar-arrow-left</v-icon>
    </v-btn>
    <div style="width:160px">
      <v-menu
        ref="targetDate"
        v-model="pickerFlag"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            readonly
            style="font-size:20px"
            label="対象年月日"
            v-model="dateFormatted"
            v-on="on"
            @blur="checkMonth"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          locale="ja"
          no-title
          @input="pickerFlag = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <v-btn icon @click="addDay(1)">
      <v-icon>mdi-calendar-arrow-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },

  data: vm => ({
    pickerFlag: false,
    formattingDate: vm.value
  }),

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.formattingDate = newValue;
        this.$emit("input", newValue);
      }
    },
    dateFormatted: {
      get() {
        if (!this.date) return null;
        const [year, month, day] = this.date.split("-");
        return `${year}年${month}月${day}日`;
      },
      set(newValue) {
        const regex = /^([0-9]+?)年([0-9]+?)月([0-9]+?)日$/g;
        const result = regex.exec(newValue);
        if (result) {
          const year = result[1];
          const month = result[2];
          const day = result[3];
          this.formattingDate =
            ("000" + year).slice(-4) +
            "-" +
            ("0" + month).slice(-2) +
            "-" +
            ("0" + day).slice(-2);
        }
      }
    }
  },

  methods: {
    checkMonth() {
      this.$emit("input", this.formattingDate);
    },
    addDay(num) {
      let date = new Date(this.date.replace(/-/g, "/"));
      date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
      date.setDate(date.getDate() + num);
      this.date = date.toISOString().substr(0, 10);
    }
  }
};
</script>