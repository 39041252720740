<template>
  <TimePicker
    :hh.sync="hh"
    :mm.sync="mm"
    :disabled="disabled"
    :hoursLabel="hoursLabel"
    :hoursSuffix="hoursSuffix"
    :minutesLabel="minutesLabel"
    :minutesSuffix="minutesSuffix"
    :hoursRules="hoursRules"
    :minutesRules="minutesRules"
  />
</template>

<script>
import TimePicker from "./TimePicker";

export default {
  components: {
    TimePicker
  },

  props: {
    value: Number,
    disabled: Boolean,
    hoursLabel: String,
    hoursSuffix: String,
    minutesLabel: String,
    minutesSuffix: String,
    hoursRules: Array,
    minutesRules: Array
  },

  computed: {
    hh: {
      get() {
        const hours = Math.floor(this.value / 60);
        if (hours) {
          return hours;
        } else {
          return null;
        }
      },
      set(hh) {
        if (hh <= 47) {
          this.inputValue(hh, this.mm);
        }
      }
    },
    mm: {
      get() {
        if (this.value === null) {
          return null;
        } else {
          return this.value % 60;
        }
      },
      set(mm) {
        if (mm <= 59) {
          this.inputValue(this.hh, mm);
        }
      }
    }
  },

  methods: {
    inputValue(hh, mm) {
      if (hh === null && mm === null) {
        this.$emit("input", 0);
      } else {
        this.$emit("input", hh * 60 + mm);
      }
    }
  }
};
</script>