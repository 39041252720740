<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800px">
    <v-card>
      <v-card-text class="pt-4" style="height: 625px" id="scroll-target">
        <v-row no-gutters class="px-4" style="height: 50px" align="center">
          <v-checkbox
            v-model="nationalHolidayCheck"
            label="祝日休み"
            class="pr-8"
          />
          <v-checkbox v-model="holidayCheck" label="公休休み" />
        </v-row>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="shifts"
          class="staff-tab-table elevation-1"
          hide-default-footer
          disable-sort
          :loading="loading"
          style="table-layout: fixed; min-width: 100%"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-start">
                {{ item.weekday_str }}
              </td>
              <td class="text-start">
                <v-select :items="shiftDiv" v-model="item.shift_div" />
              </td>
              <td class="text-start">
                <TimeCountPicker
                  v-model="item.starttime_shift"
                  v-show="item.shift_div !== 0"
                />
              </td>
              <td class="text-start">
                <TimeCountPicker
                  v-model="item.endtime_shift"
                  v-show="item.shift_div !== 0"
                />
              </td>
              <td class="text-start">
                <TimeCountPicker
                  v-model="item.resttime_shift"
                  v-show="item.shift_div !== 0"
                />
              </td>
              <td
                class="text-start"
                v-for="v in item.basicshift_job"
                :key="v.job_id"
              >
                <TimeCountPicker
                  v-model="v.plan_time"
                  v-show="item.shift_div !== 0"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="font-weight-bold"
          color="append"
          dark
          text
          @click="openShiftTab"
          >シフトテンプレート展開</v-btn
        >
        <v-btn class="font-weight-bold" color="cancel" dark text @click="close"
          >閉じる</v-btn
        >
        <v-btn class="font-weight-bold" color="primary" text @click="save"
          >保存</v-btn
        >
      </v-card-actions>
    </v-card>
    <BasicShiftTab
      ref="shiftTab"
      :shiftpatterns="shiftpatterns"
      @select="expandBasicShift"
    />
  </v-dialog>
</template>

<script>
import StaffMixin from "./StaffMixin";
import TimeCountPicker from "../common/TimeCountPicker";
import AxiosMixin from "../../mixins/axiosMixin";
import BasicShiftTab from "./BasicShiftTab";

export default {
  mixins: [AxiosMixin, StaffMixin],

  components: {
    TimeCountPicker,
    BasicShiftTab,
  },

  props: {
    staffId: Number,
  },

  data: () => ({
    dialog: false,
    baseHeaders: [
      { text: "曜日", value: "weekday_str", width: "60px" },
      { text: "シフト区分", value: "shift_div", width: "140px" },
      { text: "出勤時刻", value: "starttime_shift", width: "180px" },
      { text: "退勤時刻", value: "endtime_shift", width: "180px" },
      { text: "休憩時間", value: "resttime_shift", width: "180px" },
    ],
    jobHeaders: [],
    shifts: [],
    shiftpatterns: [],
    holidayCheck: 0,
    nationalHolidayCheck: 0,
  }),

  computed: {
    headers() {
      return this.baseHeaders.concat(this.jobHeaders);
    },
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.getDates();
      }
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      if (this.shiftTimeCheck() == false) {
        return;
      }
      if (!confirm("変更した内容を保存します。よろしいですか？")) {
        return;
      }
      const basic_shifts = this.shifts.map((v) => {
        const shift = {
          id: v.id,
          weekday: v.weekday,
          shift_div: v.shift_div,
          starttime_shift: v.starttime_shift,
          endtime_shift: v.endtime_shift,
          rest_time_shift: Number(v.resttime_shift),
          is_rest_holiday: Number(this.holidayCheck),
          is_rest_national_holiday: Number(this.nationalHolidayCheck),
          updated_at: v.updated_at,
        };
        if (shift.shift_div === 0) {
          shift.starttime_shift = null;
          shift.endtime_shift = null;
          shift.rest_time_shift = 0;
        }
        return shift;
      });
      const basic_shift_jobs = this.shifts.map((v, i) => {
        const jobs = {
          index: i,
          basic_shift_jobs: v.basicshift_job,
        };
        if (v.shift_div === 0) {
          jobs.basic_shift_jobs.forEach((v) => {
            v.plan_time = 0;
          });
        }
        return jobs;
      });
      const param = {
        staff_id: this.staffId,
        basic_shifts: basic_shifts,
        basic_shift_jobs: basic_shift_jobs,
      };
      this.postJsonCheck(
        window.base_url + "/api/basicshift/update",
        param,
        () => {
          this.close();
        }
      );
    },
    getDates() {
      this.postJsonCheck(
        window.base_url + "/api/basicshift/list",
        {
          staff_id: this.staffId,
        },
        (res) => {
          this.jobHeaders = res.data.job_headers;
          this.shifts = res.data.basicshifts;
          this.shiftpatterns = res.data.shiftpatterns;
          this.holidayCheck = res.data.check_info.is_rest_holiday;
          this.nationalHolidayCheck =
            res.data.check_info.is_rest_national_holiday;
        }
      );
    },
    openShiftTab() {
      this.$refs.shiftTab.open();
    },
    expandBasicShift(item) {
      this.shifts.forEach((v) => {
        const pattern = item.shiftpattern_details.filter(
          (w) => w.weekday === v.weekday
        )[0];
        v.shift_div = pattern.shift_div;
        v.starttime_shift = pattern.starttime_shift;
        v.endtime_shift = pattern.endtime_shift;
        v.resttime_shift = pattern.resttime_shift;
        v.basicshift_job[0].plan_time =
          v.endtime_shift - v.starttime_shift - v.resttime_shift;
      });
      this.holidayCheck = item.check_info.is_rest_holiday;
      this.nationalHolidayCheck = item.check_info.is_rest_national_holiday;
    },
    shiftTimeCheck() {
      for (let i = 0; i < this.shifts.length; i++) {
        const v = this.shifts[i];
        if (v.shift_div != 1) {
          continue;
        }
        if (
          v.endtime_shift <= v.starttime_shift ||
          v.endtime_shift == null ||
          v.starttime_shift == null
        ) {
          alert("勤務予定時間を正しく入力してください。");
          return false;
        }
        if (v.resttime_shift > v.endtime_shift - v.starttime_shift) {
          alert("休憩時間が勤務予定時間を超えています。");
          return false;
        }
        let planTimeSum = 0;
        for (let j = 0; j < v.basicshift_job.length; j++) {
          const job = v.basicshift_job[j];
          planTimeSum += job.plan_time;
        }
        if (planTimeSum == 0) {
          alert("職種ごとの勤務予定時間を入力してください");
          return false;
        }
        if (
          v.endtime_shift - v.starttime_shift <
          v.resttime_shift + planTimeSum
        ) {
          alert(
            "職種ごとの勤務予定時間と休憩時間の合計が勤務予定時間を超えています"
          );
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-tab-table {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.staff-tab-table > * {
  -webkit-transform: translateZ(0px);
}
</style>