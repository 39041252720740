<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="800px"
    min-height="800px"
  >
    <v-card class="pa-4">
      <v-card-text style="height: 600px" id="scroll-target">
        <v-form ref="form">
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="staff.id"
                label="ID"
                disabled
                v-show="staff.id != 0"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_family_name"
                label="*姓"
                maxlength="15"
                counter="15"
                :rules="[rules.required]"
                v-if="!isLinkedIbow"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_first_name"
                label="*名"
                maxlength="15"
                counter="15"
                :rules="[rules.required]"
                v-if="!isLinkedIbow"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_family_name_kana"
                label="*姓（ふりがな）"
                :rules="[rules.required, rules.hiragana]"
                maxlength="15"
                counter="15"
                v-if="!isLinkedIbow"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_first_name_kana"
                label="*名（ふりがな）"
                :rules="[rules.required, rules.hiragana]"
                maxlength="15"
                counter="15"
                v-if="!isLinkedIbow"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_name"
                label="*氏名"
                maxlength="30"
                counter="30"
                v-if="isLinkedIbow"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_furigana"
                label="*ふりがな"
                maxlength="30"
                counter="30"
                v-if="isLinkedIbow"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.s_id"
                label="*ID（ログインID）"
                :rules="[
                  checkLinkedAndRequired,
                  checkLinkedAndHalfwidthAlphanumeric,
                  checkLinkedAndStrMinlength(staff.s_id, 4),
                ]"
                maxlength="30"
                counter="30"
                :error-messages="errorMessages"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-if="!isLinkedIbow"
                v-model="staff.s_pass"
                label="仮パスワード"
                hint="登録される事でパスワードが初期化されます。 こちらは仮パスワードとなりますので本人のログイン時に必ず変更が必要です。"
                :rules="[password]"
                maxlength="12"
                counter="12"
                :disabled="isLinkedIbow"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <v-select
                v-model="staff.s_sex"
                :items="gender"
                label="性別"
                :disabled="isLinkedIbow"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :disabled="isLinkedIbow"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="生年月日"
                      v-model="dateFormatted"
                      v-on="on"
                      readonly
                      :disabled="isLinkedIbow"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="datePicker"
                    v-model="date"
                    locale="ja"
                    no-title
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @input="menu = false"
                    :disabled="isLinkedIbow"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="staff.staff_no"
                label="職員ID"
                :rules="[checkLinkedAndHalfwidthAlphanumeric]"
                maxlength="10"
                counter="10"
                :disabled="isLinkedIbow"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <v-text-field
                v-model="staff.sad_post_code"
                label="郵便番号"
                :rules="[
                  rules.halfwidthNumeric,
                  rules.strLength(staff.sad_post_code, 7),
                ]"
                maxlength="7"
                counter="7"
                :disabled="isLinkedIbow"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <v-select
                v-model="staff.sad_pref"
                :items="prefs"
                label="住所（県）"
                :disabled="isLinkedIbow"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="staff.sad_city"
                label="住所（市町村）"
                maxlength="100"
                counter="100"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.sad_area"
                label="住所（番地）"
                maxlength="100"
                counter="100"
                :disabled="isLinkedIbow"
              ></v-text-field>
              <v-text-field
                v-model="staff.sad_build"
                label="住所（建物名）"
                maxlength="100"
                counter="100"
                :disabled="isLinkedIbow"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <div class="d-flex align-center">
                <div>
                  <v-text-field
                    label="電話番号"
                    v-model="staff.stel_area"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff.stel_city"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff.stel_num"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex align-center">
                <div>
                  <v-text-field
                    label="個人携帯電話番号"
                    v-model="staff.mp1_num1"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff.mp1_num2"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff.mp1_num3"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex align-center">
                <div>
                  <v-text-field
                    label="会社携帯電話番号"
                    v-model="staff.mp2_num1"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff.mp2_num2"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff.mp2_num3"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                    :disabled="isLinkedIbow"
                  ></v-text-field>
                </div>
              </div>
              <v-text-field
                v-model="staff.mail_address"
                label="メールアドレス"
                type="email"
                :rules="[email]"
                maxlength="254"
                counter="254"
                @input="isInputMailAddress"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <div>
                <v-text-field
                  label="緊急連絡先氏名"
                  v-model="staff_info.em_name"
                  maxlength="30"
                  counter="30"
                />
              </div>
              <div>
                <v-text-field
                  label="緊急連絡先続柄"
                  v-model="staff_info.em_relationship"
                  maxlength="30"
                  counter="30"
                />
              </div>
              <div class="d-flex align-center">
                <div>
                  <v-text-field
                    label="緊急連絡先電話番号"
                    v-model="staff_info.etel_area"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                  />
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff_info.etel_city"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                  />
                </div>
                <div class="mx-2">{{ phons_hyphen }}</div>
                <div>
                  <v-text-field
                    v-model="staff_info.etel_num"
                    :rules="[checkLinkedAndHalfwidthNumeric]"
                    maxlength="4"
                    counter="4"
                  />
                </div>
              </div>
              <div>
                <v-text-field
                  label="緊急連絡先メールアドレス"
                  v-model="staff_info.em_mail"
                  type="email"
                  :rules="[emMail]"
                  maxlength="254"
                  counter="254"
                />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <v-select
                v-model="staff.auth"
                :items="auth"
                label="権限"
                :disabled="isWorkAuth"
              ></v-select>
              <v-select
                v-model="staff.s_status"
                :items="statuses"
                label="ステータス"
                :disabled="isWorkAuth"
              ></v-select>
              <!-- <v-select
                v-model="staff.worktype_id"
                :items="workTypes"
                label="勤務形態"
                :disabled="isWorkAuth"
              ></v-select> -->
            </v-col>
          </v-row>
          <div
            v-for="(jobinfo, index) in jobInfos"
            :key="jobinfo.staffs_job_id"
          >
            <v-card class="px-3 mb-3" outlined>
              <v-row no-gutters>
                <v-col>
                  <v-select
                    v-model="jobinfo.job_id"
                    :items="jobs"
                    label="職種"
                    :rules="[rules.required, checkJobs]"
                    :disabled="isWorkAuth"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col v-show="jobinfo.job_id == otherJobNo">
                  <v-text-field
                    v-model="jobinfo.other_job_name"
                    label="職種名（その他）"
                    maxlength="50"
                    counter="50"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    v-model="jobinfo.license_number"
                    label="登録番号"
                    maxlength="10"
                    counter="10"
                    :rules="[rules.halfwidthNumeric]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-file-input
                    ref="fileInput"
                    accept=".jpg, .png, .gif"
                    label="免許証等の画像を登録"
                    :clearable="false"
                    @change="inputFile(index, arguments[0])"
                  ></v-file-input>
                  <div style="width: 200px">
                    <v-img
                      :src="files[index].previewSrc"
                      alt=""
                      width="200"
                    ></v-img>
                    <v-row
                      no-gutters
                      class="mt-2"
                      v-show="isShowFileAction(index)"
                    >
                      <v-btn
                        class="mx-auto font-weight-bold"
                        color="cancel"
                        dark
                        small
                        @click="deleteImage(index)"
                      >
                        取り消し
                      </v-btn>
                      <v-btn
                        class="mx-auto font-weight-bold"
                        color="primary"
                        dark
                        small
                        @click="download(index)"
                      >
                        ダウンロード
                      </v-btn>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row class="py-3" no-gutters>
                <v-col class="text-right">
                  <v-btn
                    class="font-weight-bold white--text"
                    color="alert"
                    :disabled="isWorkAuth"
                    small
                    @click="deleteJob(index)"
                    >職種削除</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-row no-gutters>
            <v-btn
              class="ml-3 font-weight-bold white--text"
              color="append"
              :disabled="isWorkAuth"
              @click="addJob"
              >職種追加</v-btn
            >
          </v-row>
          <!-- <v-row no-gutters justify="center" class="mt-3">
            <v-select
              :items="shiftpatterns"
              label="シフトテンプレートを選択"
              v-model="selected_shiftpattern_id"
              :disabled="isWorkAuth || isLinkedIbow"
              v-if="staff != null && !this.staff.id"
            ></v-select>
          </v-row> -->
          <v-row
            no-gutters
            justify="center"
            class="mt-3"
            v-if="!(isWorkAuth || isLinkedIbow)"
          >
            <v-checkbox
              v-model="isSendMail"
              label="登録完了時、スタッフに招待メールを送信する"
              :disabled="isWorkAuth || isLinkedIbow"
            ></v-checkbox>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          class="font-weight-bold"
          v-show="staff != null && this.staff.id"
          color="alert"
          text
          @click="deleteStaff"
          :disabled="isWorkAuth || isLinkedIbow"
          >削除</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn class="font-weight-bold" color="cancel" text @click="close"
          >閉じる</v-btn
        >
        <v-btn class="font-weight-bold" color="primary" text @click="saveStaff"
          >保存</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- ローディング -->
    <loading-dialog :loading="loading"></loading-dialog>
  </v-dialog>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import StaffMixin from "./StaffMixin";
import LoadingDialog from "../common/LoadingDialog";

const reader = new FileReader();

export default {
  components: {
    LoadingDialog,
  },
  mixins: [axiosMixin, commonMixin, StaffMixin],
  props: {
    staffId: { type: Number, default: 0 },
  },
  data: () => ({
    loading: false,
    dialog: false,
    menu: false,
    date: null,
    isWorkAuth: true,
    isLinkedIbow: false,
    staff: { id: 0 },
    staff_info: {},
    jobs: [],
    workTypes: [],
    jobInfos: [],
    isSendMail: false,
    files: [],
    license_name: null,
    otherJobNo: 0,
    shiftpatterns: [],
    selected_shiftpattern_id: null,
    rules: {
      required: (v) => !!v || "必須入力です",
      hiragana: (v) =>
        !v ||
        /^[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}\u{30FC}]*$/mu.test(
          v
        ) ||
        "ひらがなでご記入ください",
      halfwidthAlphanumeric: (v) =>
        /^[A-Za-z0-9]*$/.test(v) || "半角英数字でご記入ください",
      strMinlength: (v, min) =>
        !v || (v && v.length >= min) || min + "文字以上でご記入ください",
      strLength: (v, len) =>
        !v || (v && v.length == len) || len + "文字でご記入ください",
      halfwidthNumeric: (v) =>
        /^[0-9]*$/.test(v) ||
        "半角数字（ハイフン「-」などの記号なし）でご記入ください",
    },
    errorMessages: "",
  }),
  mounted() {
    // 初期構築
    this.postJson(window.base_url + "/api/staff/init", null, (res) => {
      if (res.data.code != 0) {
        alert(res.data.message);
        return;
      }
      this.jobs = res.data.jobs;
      this.workTypes = res.data.work_types;
      this.otherJobNo = this.jobs.filter(
        (job) => job.text == "その他"
      )[0].value;
    });
  },
  computed: {
    dateFormatted() {
      if (!this.date) return null;
      const [year, month, day] = this.date.split("-");
      return `${year}年${month}月${day}日`;
    },
    email() {
      if (this.isSendMail && !this.staff.mail_address) {
        return "「登録完了時、スタッフに招待メールを送信する」にチェックがついている場合、メールアドレスは必須入力です。";
      }
      if (
        !!this.staff.mail_address &&
        !/.+@.+\..+/.test(this.staff.mail_address)
      ) {
        return "メールアドレスの形式が不正です。";
      }
      return true;
    },
    emMail() {
      if (
        !!this.staff_info.em_mail &&
        !/.+@.+\..+/.test(this.staff_info.em_mail)
      ) {
        return "メールアドレスの形式が不正です。";
      }
      return true;
    },
    password() {
      if (this.staffId == 0 && !this.staff.s_pass) {
        return "初回登録の場合、仮パスワードは必須入力です";
      }
      if (this.isSendMail && !this.staff.s_pass) {
        return "「登録完了時、スタッフに招待メールを送信する」にチェックがついている場合、仮パスワードは必須入力です";
      }
      if (this.staff.s_pass) {
        if (!/^[A-Za-z0-9#$%]*$/.test(this.staff.s_pass)) {
          return "半角英数の大文字、小文字、記号（#$%）でご記入ください";
        }
        if (
          !/[#$%]/g.test(this.staff.s_pass) ||
          !/[a-z]/g.test(this.staff.s_pass) ||
          !/[A-Z]/g.test(this.staff.s_pass) ||
          !/[0-9]/g.test(this.staff.s_pass)
        ) {
          return "半角英数の大文字、小文字、記号（#$%）各１つ以上含めてご記入ください";
        }
        if (this.staff.s_pass.length < 8) {
          return "8文字以上でご記入ください";
        }
        if (this.staff.s_pass.length > 12) {
          return "12文字以下でご記入ください";
        }
      }
      return true;
    },
    checkJobs() {
      var jobIds = this.jobInfos.map((x) => x.job_id);
      // console.log(jobIds.length);
      if (jobIds.length == 0) {
        return "職種が選択されていません。";
      }
      var distinctJobIds = [...new Set(jobIds)];
      if (jobIds.length != distinctJobIds.length) {
        return "同じ職種が複数登録されています。";
      }
      return true;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) this.getStaffDitail();
    },
    menu(val) {
      val &&
        setTimeout(
          () =>
            // 年から選ぶようにする
            (this.$refs.datePicker.activePicker = "YEAR")
        );
    },
  },
  methods: {
    checkLinkedAndRequired(v) {
      if (this.isLinkedIbow == false) {
        if (!v) {
          return "必須入力です";
        }
      }
      return true;
    },
    checkLinkedAndHalfwidthAlphanumeric(v) {
      if (this.isLinkedIbow == false) {
        if (/^[A-Za-z0-9]*$/.test(v) == false) {
          return "半角英数字でご記入ください";
        }
      }
      return true;
    },
    checkLinkedAndStrMinlength(v, min) {
      if (this.isLinkedIbow == false) {
        if (!v || (v && v.length < min)) {
          return min + "文字以上でご記入ください";
        }
      }
      return true;
    },
    checkLinkedAndHalfwidthNumeric(v) {
      if (this.isLinkedIbow == false) {
        if (/^[0-9]*$/.test(v) == false) {
          return "半角数字（ハイフン「-」などの記号なし）でご記入ください";
        }
      }
      return true;
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    saveStaff() {
      this.jobInfos.splice();
      if (!this.$refs.form.validate()) {
        alert("入力内容に不備があります。");
        return;
      }
      if (!confirm("登録内容を保存します。よろしいですか？")) return;

      this.staff.s_birth = this.date ? new Date(this.date) : null;

      var formData = new FormData();
      formData.append(
        "saveinfo",
        JSON.stringify({
          staff: this.staff,
          job_infos: this.jobInfos,
          shiftpattern_id: this.selected_shiftpattern_id,
          is_send_mail: this.isSendMail,
          staff_info: this.staff_info,
        })
      );
      var jobInfos = this.jobInfos;
      this.files.forEach(function (file, idx) {
        formData.append(jobInfos[idx].job_id + "_" + "file", file);
      });
      // console.log(this.staff);
      this.postForm(window.base_url + "/api/staff/save", formData, (res) => {
        if (res.data.code == 300) {
          // ログインID重複エラー
          this.errorMessages = res.data.message;
          alert("入力内容に不備があります。");
          return;
        } else if (res.data.code != 0) {
          alert(res.data.message);
          return;
        }
        this.$emit("updated", "保存しました。");
        this.close();
      });
    },
    deleteStaff() {
      if (!confirm("職員情報を削除します。よろしいですか？")) {
        return;
      }
      this.postJson(
        window.base_url + "/api/staff/delete",
        { staff: this.staff },
        (res) => {
          if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }
          this.$emit("updated", "削除しました。");
          this.close();
        }
      );
    },
    getStaffDitail() {
      // 職員詳細取得処理を入れる
      this.isSendMail = false;
      this.isWorkAuth = window.auth != 2;
      this.errorMessages = "";
      this.jobInfos = [];
      this.files = [];
      this.shiftpatterns = [];
      this.selected_shiftpattern_id = null;
      this.postJson(
        window.base_url + "/api/staff/ditail",
        { staff_id: this.staffId },
        (res) => {
          if (res.data.code != 0) {
            alert(res.data.message);
            this.close();
            return;
          }
          // 職員情報をセット
          this.staff = res.data.staff;
          this.staff_info = res.data.staff_info;
          this.date = !res.data.staff.s_birth
            ? null
            : res.data.staff.s_birth.substr(0, 10);
          this.isInputMailAddress(this.staff.mail_address);
          // 職種情報をセット
          if (res.data.job_infos) {
            this.jobInfos = res.data.job_infos;
            this.jobInfos.forEach((jobInfo, index) => {
              var file = {
                file: null,
                previewSrc: "",
              };
              this.files.push(file);
              if (jobInfo.image_id == 0) return;
              var req = {
                image_id: jobInfo.image_id,
                staff_id: this.staff.id,
              };
              this.postJsonBlobRes(
                window.base_url + "/api/staff/download",
                req,
                (res) => {
                  var blob = new Blob([res.data], {
                    type: "application/octet-binary",
                  });
                  this.files[index].file = blob;
                  this.files[index].previewSrc = URL.createObjectURL(blob);
                }
              );
            });
          } else {
            this.addJob();
          }
          // シフトテンプレートをセット
          if (this.staff != null && !this.staff.id) {
            this.postJsonCheck(
              window.base_url + "/api/shiftpattern/list",
              null,
              (res) => {
                if (res.data.shiftpatterns != null) {
                  this.shiftpatterns.push({ text: "", value: null });
                  res.data.shiftpatterns.forEach((shiftpattern) => {
                    this.shiftpatterns.push({
                      text: shiftpattern.shiftpattern_name,
                      value: shiftpattern.id,
                    });
                  });
                } else {
                  this.shiftpatterns.push({
                    text: "シフトテンプレートが登録されていません。",
                    value: null,
                  });
                }
              }
            );
          }
          this.isLinkedIbow = res.data.is_linked_ibow;
          // console.log(this.isLinkedIbow);
          this.$refs.form.resetValidation();
        }
      );
    },
    inputFile(index, file) {
      if (!file) {
        this.deleteImage(index);
        return;
      }
      if (file.size > 4194304) {
        alert("ファイルサイズが4MB以上です。");
        return;
      }
      reader.onload = (file) => {
        this.files[index].previewSrc = file.target.result;
        this.files.splice();
      };
      reader.readAsDataURL(file);
      this.files[index] = file;
      this.jobInfos[index].is_delete_image = false;
      this.jobInfos[index].file_name = file.name;
    },
    download(index) {
      const a = document.createElement("a");
      a.download = this.jobInfos[index].file_name;
      a.href = this.files[index].previewSrc;
      a.click();
    },
    addJob() {
      var jobinfo = {
        staffs_job_id: null,
        job_id: null,
        license_id: null,
        license_number: "",
        other_job_name: "",
        image_id: null,
      };
      this.jobInfos.push(jobinfo);
      var file = {
        file: null,
        previewSrc: "",
      };
      this.files.push(file);
    },
    deleteJob(index) {
      if (this.jobInfos.length <= 1) {
        return;
      }
      this.jobInfos.splice(index, 1);
      this.files.splice(index, 1);
    },
    deleteImage(index) {
      this.jobInfos[index].is_delete_image = true;
      this.jobInfos[index].file_name = null;
      this.files[index].previewSrc = "";
      this.files[index].file = null;
      this.jobInfos.splice();
    },
    isShowFileAction(index) {
      if (
        this.jobInfos[index].image_id > 0 &&
        !this.jobInfos[index].is_delete_image
      ) {
        return true;
      }
      return false;
    },
    isInputMailAddress(v) {
      //管理者でないまたはiBow連携している場合は登録時にメールを送る必要がない
      if (this.isWorkAuth || this.isLinkedIbow) {
        this.isSendMail = false;
        return;
      }
      //新規登録でない場合はメール送信フラグに何もしない
      if (this.staff != null && this.staff.id) {
        return;
      }
      if (v == "") {
        this.isSendMail = false;
      } else {
        this.isSendMail = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#scroll-target {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
#scroll-target > * {
  -webkit-transform: translateZ(0px);
}
</style>