<template>
  <div class="max-area-component">
    <v-row no-gutters justify="start" align="center">
      <v-btn class="ma-3 font-weight-bold" color="append" dark @click="clickNew"
        ><v-icon left>mdi-plus</v-icon>新規追加</v-btn
      >
    </v-row>
    <v-data-table
      height="100%"
      :headers="headers"
      :items="items"
      :options="options"
      class="elevation-1 shiftpattern-table"
      hide-default-footer
      disable-sort
      fixed-header
      no-data-text="シフトテンプレートが登録されていません。"
      @click:row="clickRow"
    />
    <ShiftPatternDialog ref="dialog" />
    <loading-dialog :loading="loading"></loading-dialog>
    <v-snackbar bottom v-model="snackbar" :timeout="1000">{{
      message
    }}</v-snackbar>
  </div>
</template>


<script>
import ShiftPatternDialog from "./ShiftPatternDialog";
import axiosMixin from "../../mixins/axiosMixin";
import LoadingDialog from "../common/LoadingDialog";
export default {
  components: {
    ShiftPatternDialog,
    loadingDialog: LoadingDialog,
  },
  mixins: [axiosMixin],
  provide: function () {
    return {
      loadShiftPatterns: this.loadShiftPatterns,
      showSnackbar: this.showSnackbar,
    };
  },

  data: () => ({
    snackbar: false,
    message: "",
    headers: [{ text: "シフトテンプレート一覧", value: "name" }],
    items: [],
    options: { itemsPerPage: -1 },
  }),
  mounted: function () {
    this.loadShiftPatterns();
  },
  methods: {
    loadShiftPatterns() {
      this.items = [];
      this.postJson(window.base_url + "/api/shiftpattern/list", null, (res) => {
        if (res.data.shiftpatterns != null) {
          res.data.shiftpatterns.forEach((shiftpattern) => {
            this.items.push({
              name: shiftpattern.shiftpattern_name,
              id: shiftpattern.id,
            });
          });
        }
      });
    },

    showSnackbar() {
      this.message = this.$refs.dialog.snackbar_message;
      this.snackbar = true;
    },

    clickRow(row) {
      this.$refs.dialog.add_new_pattern = false;
      this.$refs.dialog.name = row.name;
      this.$refs.dialog.id = row.id;
      this.$refs.dialog.open();
    },
    clickNew() {
      this.$refs.dialog.add_new_pattern = true;
      this.$refs.dialog.name = "";
      this.$refs.dialog.id = -1;
      this.$refs.dialog.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.max-area-component {
  .shiftpattern-table {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
</style>