<template>
  <!-- 月次出勤簿 -->
  <div class="max-area-component">
    <v-row no-gutters align="center" class="ml-auto">
      <TextMonthChangePicker v-model="date" />
    </v-row>
    <v-card flat>
      <div style="color: gray">職員名</div>
      <div class="text-h6">{{ staffInfo.staff_name }}</div>
    </v-card>
    <v-row no-gutters class="py-3" align="end">
      <v-col cols="5">
        <v-btn
          :disabled="checkDisable.work_disable || isRestricted"
          color="append"
          class="mt-3 mr-3 font-weight-bold white--text"
          @click="clickRow()"
        >
          <v-icon left>mdi-plus</v-icon>治験実績追加
        </v-btn>
        <div v-if="$vuetify.breakpoint.xs" class="my-15" />
        <v-btn
          color="primary"
          class="mt-3 mr-3 font-weight-bold"
          :disabled="isRestricted"
          @click="downloadExcel()"
        >
          <v-icon left>mdi-export-variant</v-icon>Excel出力
        </v-btn>
      </v-col>
      <v-col>
        <v-data-table
          :headers="summaryHeader"
          no-data-text="データが見つかりませんでした。"
          :hide-default-header="$vuetify.breakpoint.xs"
          hide-default-footer
          disable-pagination
          disable-sort
          :items="timesSum"
          class="elevation-1 attend-private-expand-table"
        >
          <template v-for="h in summaryHeader" v-slot:[`header.${h.value}`]>
            <span :key="h.value"
              >{{ h.text }}
              <!-- <v-tooltip top>
                <template v-if="h.tips != null" v-slot:activator="{ on }">
                  <v-icon left v-on="on">mdi-help-circle</v-icon>
                </template>
                <span v-html="overTimeTip"></span>
              </v-tooltip> -->
            </span>
          </template>
          <template v-slot:item="{ item }">
            <tr
              class="white text-center"
              :class="
                $vuetify.breakpoint.xs ? 'v-data-table__mobile-table-row' : ''
              "
            >
              <td
                v-if="$vuetify.breakpoint.xs"
                class="v-data-table__mobile-row"
              >
                <div class="v-data-table__mobile-row__header" />
                <div class="v-data-table__mobile-row__cell">
                  {{ item.sum_text }}
                </div>
              </td>
              <td v-if="!$vuetify.breakpoint.xs">
                {{ item.sum_text }}
              </td>
              <td
                v-if="$vuetify.breakpoint.xs"
                class="v-data-table__mobile-row"
              >
                <div class="v-data-table__mobile-row__header">
                  {{ summaryHeader[1].text }}
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ item.work_time_sum }}
                </div>
              </td>
              <td v-if="!$vuetify.breakpoint.xs">
                {{ item.work_time_sum }}
              </td>
              <td
                v-if="$vuetify.breakpoint.xs"
                class="v-data-table__mobile-row"
              >
                <div class="v-data-table__mobile-row__header">
                  {{ summaryHeader[2].text }}
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ item.night_time_sum }}
                </div>
              </td>
              <td v-if="!$vuetify.breakpoint.xs">
                {{ item.night_time_sum }}
              </td>
              <!-- <td
                v-if="$vuetify.breakpoint.xs"
                class="v-data-table__mobile-row"
              >
                <div class="v-data-table__mobile-row__header">
                  {{ summaryHeader[3].text }}
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ item.refresh_time_sum }}
                </div>
              </td>
              <td v-if="!$vuetify.breakpoint.xs">
                {{ item.refresh_time_sum }}
              </td>
              <td
                :class="overTimeCellClass(item.over_time_sum)"
                v-if="$vuetify.breakpoint.xs"
                class="v-data-table__mobile-row"
              >
                <div class="v-data-table__mobile-row__header">
                  {{ summaryHeader[4].text }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle</v-icon>
                    </template>
                    <span v-html="overTimeTip" />
                  </v-tooltip>
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ overTimeDisp(item.over_time_sum) }}
                </div>
              </td>
              <td
                :class="overTimeCellClass(item.over_time_sum)"
                v-if="!$vuetify.breakpoint.xs"
              >
                {{ overTimeDisp(item.over_time_sum) }}
              </td> -->
              <td
                v-if="$vuetify.breakpoint.xs"
                class="v-data-table__mobile-row"
              >
                <div class="v-data-table__mobile-row__header">
                  {{ summaryHeader[3].text }}
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ item.middle_time_sum }}
                </div>
              </td>
              <td v-if="!$vuetify.breakpoint.xs">
                {{ item.middle_time_sum }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-data-table
      height="100%"
      no-data-text="データが見つかりませんでした。"
      :headers="displayHeaders"
      :items="attends"
      @click:row="clickRow"
      hide-default-footer
      disable-pagination
      fixed-header
      style="min-width: 100%"
      class="mb-3 elevation-1 attend-private-expand-table"
    >
      <template v-slot:[`item.middle_time`]="{ item }">
        <div v-for="v in item.attend_middles" :key="v.id">
          {{ v.middle_time }}
        </div>
      </template>
      <template v-slot:[`item.memo`]="{ item }">
        <div style="white-space: pre-line">
          {{ item.memo }}
        </div>
      </template>
      <template v-slot:[`item.work_memo`]="{ item }">
        <div
          style="white-space: pre-line"
          v-for="v in item.attend_middles"
          :key="v.id"
        >
          {{ v.memo }}
        </div>
      </template>
      <template v-slot:[`item.file_upload`]="{ item }">
        <div v-if="item.file_infos">◯</div>
      </template>
    </v-data-table>
    <AttendPrivateDialog
      ref="dialog"
      :attend="attend"
      :staffJobs="staffJobs"
      :staffId="staffId"
      :month="date"
      @update="updated($event)"
      :checkDisable="checkDisable"
    />
    <LoadingDialog :loading="loading" />
    <v-snackbar bottom v-model="snackbar" :timeout="1000">{{
      message
    }}</v-snackbar>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import AttendMixin from "./AttendMixin";
import AttendPrivateDialog from "./AttendPrivateDialog";
import LoadingDialog from "../common/LoadingDialog";
import TextMonthChangePicker from "../common/TextMonthChangePicker";

export default {
  mixins: [axiosMixin, AttendMixin, commonMixin],

  components: {
    AttendPrivateDialog,
    LoadingDialog,
    TextMonthChangePicker,
  },

  data: (vm) => ({
    date: vm.dateJp(new Date()).substr(0, 7),
    headers: [
      { text: "日付", value: "date", width: "80px" },
      { text: "区分", value: "type_disp", width: "80px" },
      // { text: "オンコール当番", value: "is_oncall_disp", width: "100px" },
      // { text: "シフト", value: "shift_from_to", width: "140px" },
      { text: "実績", value: "work_from_to", width: "140px" },
      { text: "ログ", value: "log", width: "80px" },
      { text: "治験時間", value: "work_time", width: "100px" },
      { text: "時間外", value: "night_time", width: "100px" },
      // { text: "休憩時間", value: "refresh_time", width: "100px" },
      // { text: "残業時間", value: "over_time", width: "100px" },
      { text: "業務時間", value: "middle_time", width: "100px" },
      { text: "治験情報", value: "memo", width: "180px" },
      { text: "業務メモ", value: "work_memo", width: "260px" },
      {
        text: "添付ファイル",
        value: "file_upload",
        width: "140px",
        align: "center",
        sortable: false,
      },
    ],
    headerEnables: [
      { text: "治験開始住所", value: "start_address", width: "260px" },
      { text: "治験終了住所", value: "end_address", width: "260px" },
    ],
    headerAfter: [{ text: "備考", value: "remarks", width: "260px" }],
    attends: [],
    attend: {},
    staffJobs: [],
    staffInfo: {},
    checkDisable: {},
    summaryHeader: [
      { text: "", width: "60px", align: "center", value: "sum_text" },
      {
        text: "治験時間",
        width: "80px",
        align: "center",
        value: "work_time_sum",
      },
      {
        text: "時間外",
        width: "80px",
        align: "center",
        value: "night_time_sum",
      },
      // {
      //   text: "休憩時間",
      //   width: "80px",
      //   align: "center",
      //   value: "refresh_time_sum",
      // },
      // {
      //   text: "残業時間",
      //   width: "80px",
      //   align: "center",
      //   value: "over_time_sum",
      //   tips: true,
      // },
      {
        text: "業務時間",
        width: "80px",
        align: "center",
        value: "middle_time_sum",
      },
    ],
    timesSum: [],
    overTimeNoneColor: "",
    overTimeWarningColor: "warning lighten-1",
    overTimeAlertColor: "alert",
    sumTableRowClass: "v-data-table__mobile-table-row",
    sumTableDataClass: "v-data-table__mobile-row",
    snackbar: false,
    message: "保存しました。",
    staffId: 0,
    isRestricted: false,
    overTimeTip: `表示している対象年月の合計残業時間が30時間を超えると黄色になり、45時間を超えると赤になります。<br>
時間外労働（休日労働は含まず）の上限は、原則として、月45時間・年360時間となり、 臨時的な特別の事情がなければ、これを超えることはできません。`,
    overTimeDetailUrl: "https://www.mhlw.go.jp/content/000463185.pdf",
  }),

  computed: {
    dateForParameter() {
      if (!this.date) return null;
      const [year, month] = this.date.split("-");
      return `${year}${month}`;
    },
    displayHeaders() {
      if (this.checkDisable.address_enable) {
        return this.headers.concat(this.headerEnables).concat(this.headerAfter);
      }
      return this.headers.concat(this.headerAfter);
    },
  },

  watch: {
    dateForParameter() {
      this.getDispDatas();
    },
    $route(to) {
      if (to.query.yearmonth && this.date !== to.query.yearmonth) {
        this.date = to.query.yearmonth;
      } else {
        this.getDispDatas();
      }
    },
  },

  mounted() {
    if (
      this.$route.query.yearmonth &&
      this.date !== this.$route.query.yearmonth
    ) {
      this.date = this.$route.query.yearmonth;
    } else {
      this.getDispDatas();
    }
  },

  methods: {
    clickRow(row = null) {
      this.attend = row;
      if (this.$route.params.sid) {
        this.staffId = Number(this.$route.params.sid);
      } else {
        this.staffId = 0;
      }
      this.$refs.dialog.open();
    },
    getDispDatas() {
      let url = "/api/attendprivate/list?tag_ym=" + this.dateForParameter;
      if (this.$route.params.sid) {
        url += "&staff_id=" + this.$route.params.sid;
      }
      this.postJson(window.base_url + url, null, (res) => {
        if (res.data.code == 201) {
          this.attends = [];
          this.staffJobs = [];
          this.timesSum = [];
          this.isRestricted = true;
          alert(res.data.message);
          return;
        } else if (res.data.code > 0) {
          alert(res.data.message);
          return;
        }
        this.isRestricted = false;
        this.attends = res.data.attends;
        this.staffJobs = res.data.staff_jobs;
        this.staffInfo = res.data.staff_info;
        this.checkDisable = res.data.check_disable;
        this.timesSum = res.data.times_sum;
      });
    },
    updated(message) {
      this.snackbar = true;
      this.message = message;
      this.getDispDatas();
    },
    downloadExcel() {
      const param = {
        tag_ym: this.dateForParameter,
      };
      if (this.$route.params.sid) {
        param.staff_id = Number(this.$route.params.sid);
      }
      this.postJsonBlobResCheck(
        window.base_url + "/api/attendprivate/download",
        param,
        (res) => {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = `治験実績表_${this.dateForParameter}.xlsx`;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      );
    },
    overTimeDisp(time) {
      return this.minuteToStrHours(time);
    },
    overTimeCellClass(time) {
      if (time >= 2700) {
        return this.overTimeAlertColor;
      } else if (time >= 1800) {
        return this.overTimeWarningColor;
      }
      return this.overTimeNoneColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.max-area-component {
  .attend-private-expand-table {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0px);
  }
}
</style>