export default {
  methods: {
    // 指定された name を持つ Cookie を返します
    // なければ undefined を返します
    getCookie(name) {
      let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },

    // 使用例:
    // setCookie('user', 'John', {secure: true, 'max-age': 3600});
    setCookie(name, value, options = {}) {
      options = {
        path: '/',
        // 必要でれば他のデフォルトを追加する
        ...options
      }
      if (options.expires) {
        options.expires = options.expires.toUTCString();
      }
      let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)
      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }
      document.cookie = updatedCookie;
    },

    deleteCookie(name) {
        this.setCookie(name, "", {'max-age': -1})
    },
  },
}