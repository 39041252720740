<template>
  <v-dialog v-model="isActive" persistent max-width="400px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">【シフト展開】</span>
      </v-card-title>
      <v-card-text class="text-center">
        <div>指定した日付以降のシフトが再展開されます。</div>
        <text-date-picker
          style="margin: 0 auto;"
          class="text-center"
          v-model="date"
          :readonly="true"
        ></text-date-picker>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" class="mr-2 font-weight-bold" width="100px" @click="agree">展開する</v-btn>
        <v-btn v-show="showCancel" color="cancel" dark class="font-weight-bold" width="100px" @click="cancel">閉じる</v-btn>
      </v-card-actions>
    </v-card>
    <!-- ローディング -->
    <loading-dialog :loading="loading"></loading-dialog>
  </v-dialog>
</template>

<script>
import axiosMixin     from '../../mixins/axiosMixin';
import TextDatePicker from '../common/TextDatePicker';
import LoadingDialog  from '../common/LoadingDialog';

export default {
  components: {
    TextDatePicker,
    LoadingDialog,
  },
  mixins: [axiosMixin],
  data: () => ({
    isActive  : false,
    showCancel: false,
    resolve   : null,
    reject    : null,
    date      : null,
    staffId   : null,
  }),
  methods: {
    open(staffId, showCancel = false, date = new Date().toISOString().substr(0, 10)) {
      this.isActive   = true;
      this.staffId    = staffId;
      this.showCancel = showCancel;
      this.date       = date;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },
    agree() {
      var req = {
        staff_id    : this.staffId,
        from        : new Date(this.date),
        force_update: 1
      };
      this.postJson(window.base_url + '/api/staff/expand', req, (res) => {
        if (res.data.code != 0) {
          alert(res.data.message);
          return;
        }
        this.resolve(true);
        this.isActive = false;
      });
    },
    cancel() {
      this.resolve(false);
      this.isActive = false;
    },
  },
};
</script>