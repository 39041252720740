<template>
  <div class="inline-frame-area">
    <div class="iframe-wrapper" :style="{ height: height + 'px' }">
      <iframe :src="url" :style="{ height: height + 'px' }" />
    </div>
    <v-checkbox
      class="agree-checkbox"
      label="確認しました"
      v-model="isAgree"
    ></v-checkbox>
    <div class="mb-2">
      <v-btn
        width="240px"
        color="primary"
        :disabled="!isAgree"
        @click="$emit('attention')"
        >開始する</v-btn
      >
    </div>
    <!-- <AttentionComponent ref="attention" @attention="$emit('attention')" /> -->
  </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import cookieMixin from "../mixins/cookieMixin.js";
// import AttentionComponent from "@/components/Attention.vue";
// import Cookies from "js-cookie";

export default {
  mixins: [axiosMixin, cookieMixin],
  // components: {
  //   AttentionComponent,
  // },
  props: {
    isChikenUser: Boolean,
  },
  data: () => ({
    isAgree: false,
    url: "",
    height: 0,
  }),

  mounted() {
    this.postJsonCheck(
      window.base_url + "/iframe_url/get",
      {
        is_chiken_user: this.isChikenUser,
      },
      (res) => {
        if (res.data.notice) {
          this.url = res.data.notice.url;
          this.height = res.data.notice.height;
        }
      }
    );
  },
  // methods: {
  //   clickStartButton() {
  //     this.setCookie("isAgree", "true");
  //     // this.$refs.attention.openDialog();
  //   },
  //   attention() {
  //     this.setCookie("isAgree", "true");
  //     return;
  //   },
  // },
};
</script>

<style lang="scss">
.inline-frame-area {
  text-align: center;
  display: inline-block;

  .agree-checkbox {
    display: inline-block;
  }

  .iframe-wrapper {
    width: calc(100% - 16px);
    margin: 8px;

    iframe {
      width: 100%;
      border: solid 2px #fabe00;
    }
  }
}
</style>