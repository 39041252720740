<template>
  <div>
    <v-row no-gutters justify="end">
      <v-btn class="mr-5 font-weight-bold" color="primary" dark @click="save">
        <v-icon left>mdi-content-save</v-icon>保存
      </v-btn>
    </v-row>
    <v-form ref="forms">
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="10"
          counter="10"
          v-model="office.kofficecd"
          label="介護保険事業所番号"
          class="color"
          :disabled="data_linked"
          :rules="[rules.required, rules.requiredSpace]"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="50"
          counter="50"
          v-model="office.officename"
          label="事業所名"
          :disabled="data_linked"
          :rules="[rules.required, rules.requiredSpace]"
          @input="change_flg = true"
        />
      </v-row>
      <!-- <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="255"
          counter="255"
          v-model="office.service_type"
          label="サービス種類"
          :rules="[rules.required, rules.requiredSpace]"
          @input="change_flg = true"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle</v-icon>
              </template>
              <span>勤務形態一覧表のサービス種類に表記されます</span
              ><span class="ml-4">例）訪問看護・介護予防訪問看護</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          v-model.number="office.office_working_hours"
          label="常勤職員が勤務すべき1日当たりの勤務時間"
          :rules="[rules.floatNumeric]"
          maxlength="5"
          suffix="時間"
          @input="change_flg = true"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle</v-icon>
              </template>
              <span
                >ここが勤務形態一覧表と給与計算ソフトの連携時に影響します</span
              >
            </v-tooltip>
          </template>
        </v-text-field>
      </v-row> -->
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="50"
          counter="50"
          label="法人名"
          v-if="!data_linked"
          v-model="office.corporate_name"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="254"
          counter="254"
          label="メールアドレス"
          v-if="!data_linked"
          v-model="office.mail_address"
          :rules="[rules.mail]"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="7"
          counter="7"
          label="〒"
          v-if="!data_linked"
          v-model="office.oad_post_code"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-select
          label="都道府県"
          v-if="!data_linked"
          :items="prefs"
          v-model="office.oad_pref"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="100"
          counter="100"
          label="市区町村"
          v-if="!data_linked"
          v-model="office.oad_city"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="100"
          counter="100"
          label="町域・番地"
          v-if="!data_linked"
          v-model="office.oad_area"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto">
        <v-text-field
          maxlength="100"
          counter="100"
          label="建物名"
          v-if="!data_linked"
          v-model="office.oad_build"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto" align="center" v-if="!data_linked">
        <v-text-field
          maxlength="4"
          counter="4"
          label="TEL"
          v-model="office.otel_area"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
        <span class="mx-3">{{ phons_hyphen }}</span>
        <v-text-field
          maxlength="4"
          counter="4"
          v-model="office.otel_city"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
        <span class="mx-3">{{ phons_hyphen }}</span>
        <v-text-field
          maxlength="4"
          counter="4"
          v-model="office.otel_num"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto" align="center" v-if="!data_linked">
        <v-text-field
          maxlength="4"
          counter="4"
          label="FAX"
          v-model="office.ofax_area"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
        <span class="mx-3">{{ phons_hyphen }}</span>
        <v-text-field
          maxlength="4"
          counter="4"
          v-model="office.ofax_city"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
        <span class="mx-3">{{ phons_hyphen }}</span>
        <v-text-field
          maxlength="4"
          counter="4"
          v-model="office.ofax_num"
          :rules="[rules.halfwidthNumeric]"
          @input="change_flg = true"
        />
      </v-row>
      <v-row no-gutters class="mx-auto py-2 pl-4" align="center">
        <span v-if="data_linked" class="text-h7">iBow事業所コード</span>
        <span v-if="!data_linked" class="text-h7">iBowKINTAI事業所コード</span>
        <span class="mx-2 text-h7">{{ phons_hyphen }}</span>
        <span v-if="data_linked" class="text-h6">{{ office.officeid }}</span>
        <span v-if="!data_linked" class="text-h6">{{ office.officecd }}</span>
      </v-row>
      <v-row
        no-gutters
        class="mx-auto py-2 pl-4"
        align="center"
        justify="start"
      >
        <span class="text-h7">現在ご利用中の職員ID数</span>
        <span class="mx-2 text-h7">{{ phons_hyphen }}</span>
        <span class="text-h6">{{ staff_id_total_usage }}</span>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="ml-3" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>ステータスが退職と未所属の職員IDはカウントしません</span>
        </v-tooltip>
      </v-row>
      <!-- <v-row
        no-gutters
        class="mx-auto pl-4 paid-option-list-items"
        align="center"
      >
        <v-list>
          <template>
            <v-list-group no-action color="primary">
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-text="'有料版オプション'" />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                class="pl-10"
                v-for="(option, index) in paid_options"
                :key="option.text"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ option.text }}
                    {{ phons_hyphen }}
                    {{ option.status }}
                  </v-list-item-title>
                  <div style="font-size: 14px" v-if="index == 0">
                    ※出勤簿に打刻位置が表示されます
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
      </v-row>
      <v-row no-gutters class="mx-auto py-3 pl-4">
        <span class="text-h7 mr-2">iBow連携</span>
        <span v-if="data_linked">
          <span class="text-h7 green--text d-flex align-center">
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
            連携済み
          </span>
        </span>
        <span v-if="!data_linked">
          <span class="text-h7 grey--text text--darken-2 d-flex align-center">
            <v-icon color="grey">mdi-minus-circle</v-icon>
            未連携
          </span>
        </span>
      </v-row> -->
      <v-row no-gutters class="mx-auto mt-1 pt-3" align="center">
        <v-textarea
          outlined
          no-resize
          label="備考"
          v-model="office.remarks"
          @input="change_flg = true"
        />
      </v-row>
    </v-form>
    <loading-dialog :loading="loading"></loading-dialog>
    <v-snackbar bottom v-model="snackbar" :timeout="1000">
      保存しました。
    </v-snackbar>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import LoadingDialog from "../common/LoadingDialog";

export default {
  components: {
    loadingDialog: LoadingDialog,
  },

  inject: ["navigationManage"],

  mixins: [axiosMixin, commonMixin],

  data: () => ({
    snackbar: false,
    office: {},
    payment: {},
    staff_id_total_usage: 0,
    //payment_show: false,
    data_linked: false,
    change_flg: false,
    rules: {
      required: (v) => !!v || "必須入力です。",
      requiredSpace: (v) =>
        !v || v.replace(/\s+/g, "") != "" || "必須入力です。",
      floatNumeric: (v) =>
        !v ||
        /^([1-9][0-9]{0,1}|0)(\.[0-9]{0,2})?$/.test(v) ||
        "整数２桁、少数２桁以内でご記入ください",
      mail: (v) =>
        !v || /.+@.+\..+/.test(v) || "メールアドレスの形式が不正です。",
      halfwidthNumeric: (v) =>
        /^[0-9]*$/.test(v) ||
        "半角数字（ハイフン「-」などの記号なし）でご記入ください",
      bank_account_holder_furigana: (v) =>
        !v ||
        /^[ｱ-ﾟｦ0-9A-Z()\-,/. ]+$/u.test(v) ||
        "半角カナ大文字または半角英数大文字または記号()-,/.のみ入力できます。",
    },
    prefs: [
      { value: 1, text: "北海道" },
      { value: 2, text: "青森県" },
      { value: 3, text: "岩手県" },
      { value: 4, text: "宮城県" },
      { value: 5, text: "秋田県" },
      { value: 6, text: "山形県" },
      { value: 7, text: "福島県" },
      { value: 8, text: "茨城県" },
      { value: 9, text: "栃木県" },
      { value: 10, text: "群馬県" },
      { value: 11, text: "埼玉県" },
      { value: 12, text: "千葉県" },
      { value: 13, text: "東京都" },
      { value: 14, text: "神奈川県" },
      { value: 15, text: "新潟県" },
      { value: 16, text: "富山県" },
      { value: 17, text: "石川県" },
      { value: 18, text: "福井県" },
      { value: 19, text: "山梨県" },
      { value: 20, text: "長野県" },
      { value: 21, text: "岐阜県" },
      { value: 22, text: "静岡県" },
      { value: 23, text: "愛知県" },
      { value: 24, text: "三重県" },
      { value: 25, text: "滋賀県" },
      { value: 26, text: "京都府" },
      { value: 27, text: "大阪府" },
      { value: 28, text: "兵庫県" },
      { value: 29, text: "奈良県" },
      { value: 30, text: "和歌山県" },
      { value: 31, text: "鳥取県" },
      { value: 32, text: "島根県" },
      { value: 33, text: "岡山県" },
      { value: 34, text: "広島県" },
      { value: 35, text: "山口県" },
      { value: 36, text: "徳島県" },
      { value: 37, text: "香川県" },
      { value: 38, text: "愛媛県" },
      { value: 39, text: "高知県" },
      { value: 40, text: "福岡県" },
      { value: 41, text: "佐賀県" },
      { value: 42, text: "長崎県" },
      { value: 43, text: "熊本県" },
      { value: 44, text: "大分県" },
      { value: 45, text: "宮崎県" },
      { value: 46, text: "鹿児島県" },
      { value: 47, text: "沖縄県" },
    ],
    // payment_format: [{ value: null, text: "" }, { value: 0, text: "引落" }, { value: 1, text: "振込" }],
    // payment_bank_div: [{ value: null, text: "" }, { value: 0, text: "ゆうちょ銀行" }, { value: 1, text: "ゆうちょ銀行以外の金融機関" }],
    // payment_bank_type: [
    //   { value: null, text: "" },
    //   { value: 0, text: "銀行" },
    //   { value: 1, text: "信用金庫" },
    //   { value: 2, text: "信用組合" },
    //   { value: 3, text: "農業協同組合" },
    // ],
    // payment_branch_type: [
    //   { value: null, text: "" },
    //   { value: 0, text: "本店" },
    //   { value: 1, text: "支店" },
    //   { value: 2, text: "出張所" },
    //   { value: 3, text: "御中" },
    // ],
    // payment_bank_account_type: [
    //   { value: null, text: "" },
    //   { value: 1, text: "普通" },
    //   { value: 2, text: "当座" },
    // ],
    paid_options: [
      { icon: "mdi-alarm", status: "オフ", text: "GPS住所情報表示" },
      {
        icon: "mdi-notebook-multiple",
        status: "制限あり",
        text: "勤怠データ保存期間制限",
      },
    ],
  }),

  beforeRouteLeave(to, from, next) {
    if (this.change_flg === true) {
      if (!confirm("変更した内容が破棄されます。よろしいですか？")) {
        this.$nextTick(() => {
          this.navigationManage(from);
        });
        return;
      }
    }
    next();
  },

  mounted: function () {
    this.loadOfficeInfo();
  },

  methods: {
    loadOfficeInfo() {
      this.postJson(window.base_url + "/api/office/search", null, (res) => {
        this.office = res.data.office;
        this.data_linked = res.data.office.officeid > 0;
        //有償オプション
        if (this.office.is_position_to_adress == true) {
          this.paid_options[0].status = "オン";
        } else {
          this.paid_options[0].status = "オフ";
        }
        if (this.office.is_view_unlimited_log == true) {
          this.paid_options[1].status = "制限なし";
        } else {
          this.paid_options[1].status = "制限あり";
        }
        this.payment = res.data.payment;
        this.staff_id_total_usage = res.data.staff_id_total_usage;
        this.$refs.forms.resetValidation();
        this.change_flg = false;
      });
    },

    save() {
      if (!this.$refs.forms.validate(true)) {
        alert("入力内容に不備があります。");
        return;
      }
      if (confirm("データを保存しますか？")) {
        // 空白かつゼロ以外はnullセット
        if (!this.working_hours && this.working_hours !== 0) {
          this.working_hours = null;
        }
        this.postJson(
          window.base_url + "/api/office/update",
          {
            office: this.office,
            payment: this.payment,
          },
          () => {
            this.snackbar = true;
            this.loadOfficeInfo();
          }
        );
      }
    },

    stringToArray(str) {
      return (
        str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || []
      );
    },

    checkStringArrayLength(stringArray, length) {
      return stringArray.length <= length;
    },
  },
};
</script>

<style lang="scss">
.paid-option-list-items {
  .v-list {
    padding: 0px;
    .v-list-group {
      .v-list-group__header {
        padding: 0px;
        .v-list-item {
          padding: 0px;
        }
      }
    }
  }
}
</style>