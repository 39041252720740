<template>
  <div class="max-area-component">
    <v-row no-gutters>
      <v-alert
        icon="mdi-monitor-lock"
        class="ml-4"
        type="info"
        dense
        v-model="restrictedAlert"
      >
        {{ restrictedMessage }}
      </v-alert>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <MonthPicker
          v-model="yearmonth"
          @changeMonth="onChangeMonth"
        ></MonthPicker>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-btn
        class="mr-3 font-weight-bold"
        style="padding-right: 6px"
        color="primary"
        @click="getList"
      >
        <v-icon left>mdi-reload</v-icon>更新
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon style="margin-left: 6px" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span v-html="tips.reload"></span>
        </v-tooltip>
      </v-btn>
      <v-btn
        class="font-weight-bold white--text"
        color="append"
        :disabled="makeDataDisabled || isRestricted"
        @click="createStatus(0)"
      >
        <v-icon left>mdi-plus</v-icon>管理追加
      </v-btn>
    </v-row>
    <v-data-table
      height="100%"
      :headers="headers"
      :items="list"
      :single-select="true"
      :options.sync="options"
      item-key="yearmonth"
      class="elevation-1 shiftstatus-table"
      no-data-text="データなし"
      sort-by="yearmonth"
      fixed-header
      :sort-desc="true"
      :items-per-page="25"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
    >
      <!-- ヘッダツールチップ対応 -->
      <template v-for="h in headers" v-slot:[`header.${h.value}`]>
        <span :key="h.value"
          >{{ h.text }}
          <v-tooltip v-if="h.description != null" bottom :key="h.value">
            <template v-slot:activator="{ on }">
              <v-icon left v-on="on">mdi-help-circle</v-icon>
            </template>
            <span v-html="h.description"></span>
          </v-tooltip>
        </span>
      </template>

      <!-- 年月度 -->
      <template v-slot:item.yearmonth="{ item }">
        <span>{{ yearmonthFormat(item.yearmonth) }}</span>
      </template>
      <!-- シフト締め処理 -->
      <template v-slot:item.plan_closed_at="{ item }">
        <v-btn
          :disabled="item.plan_closed_at != null"
          small
          color="primary"
          class="mr-2 font-weight-bold"
          @click.stop="changeStatus(item, 'plan', 'close')"
          >シフト確定</v-btn
        >
        <v-btn
          :disabled="
            item.plan_closed_at == null || item.actual_closed_at != null
          "
          small
          color="warning"
          class="mr-2 font-weight-bold"
          @click.stop="changeStatus(item, 'plan', 'open')"
          >解除</v-btn
        >
        <span>{{ dateFormat(item.plan_closed_at) }}</span>
      </template>
      <!-- 実績締め処理 -->
      <template v-slot:item.actual_closed_at="{ item }">
        <v-btn
          :disabled="item.actual_closed_at != null"
          small
          color="primary"
          class="mr-2 font-weight-bold"
          @click.stop="changeStatus(item, 'actual', 'close')"
          >実績確定</v-btn
        >
        <v-btn
          :disabled="item.actual_closed_at == null"
          small
          color="warning"
          class="mr-2 font-weight-bold"
          @click.stop="changeStatus(item, 'actual', 'open')"
          >解除</v-btn
        >
        <span>{{ dateFormat(item.actual_closed_at) }}</span>
      </template>
    </v-data-table>
    <!-- ローディングダイアログコンポーネント   this.postJsonの呼び出しで自動で表示される-->
    <loading-dialog :loading="loading"></loading-dialog>
  </div>
</template>
<script>
// 共通部品のインポート
import axiosMixin from "../../mixins/axiosMixin";
// ローディングダイアログのインポート
import LoadingDialog from "../common/LoadingDialog";
import MonthPicker from "../common/NextPrevMonthPicker";

export default {
  components: {
    loadingDialog: LoadingDialog,
    MonthPicker,
  },
  mixins: [axiosMixin],
  data() {
    return {
      yearmonth: null,
      makeDataDisabled: false,
      list: [],
      options: {},
      headers: [
        { value: "yearmonth", text: "年月度" },
        {
          value: "plan_closed_at",
          text: "シフト確定処理",
          description: "出勤簿編集画面でシフト時間が修正できなくなります",
        },
        {
          value: "actual_closed_at",
          text: "実績確定処理",
          description:
            "出勤簿編集画面で打刻時間などの実績が修正できなくなります",
        },
      ],
      tips: {
        reload:
          "複数名で同時にシフトを管理している場合、<br>更新ボタンを押すと、最新の画面に更新します<br>（他の管理者が決定した内容が反映されます)",
      },
      isRestricted: false,
      restrictLogPeriodYM: null,
      restrictedMessage: "",
      restrictedAlert: false,
    };
  },
  created() {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      .toISOString()
      .substr(0, 7);
    const [year, month] = date.split("-");
    this.yearmonth = Number(`${year}${month}`);
  },
  mounted() {
    this.getList();
  },
  methods: {
    yearmonthFormat(yearmonth) {
      const year = String(yearmonth).substr(0, 4);
      const month = String(yearmonth).substr(4, 2);
      return `${year}年${month}月`;
    },
    dateFormat(date) {
      if (!date) return null;
      const d = new Date(date);
      // const year = d.getFullYear()
      const month = d.getMonth() + 1;
      const day = d.getDate();
      // return `${year}年${month}月${day}日`
      return `${month}月${day}日`;
    },
    createStatus(force) {
      const param = {
        yearmonth: this.yearmonth,
        forceupdate: force,
      };
      this.postJson(
        window.base_url + "/api/shiftstatus/status/create",
        param,
        (res) => {
          // console.log(res)

          if (res.data.code == 1) {
            // シフトデータが存在している
            if (confirm(res.data.message)) {
              // 強制更新
              this.createStatus(1);
              return;
            } else {
              // 処理中断
              return;
            }
          }
          if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }

          if (res.data != null && res.data.list != null) {
            this.list = res.data.list;
            this.updateDisp();
          }
        }
      );
    },
    getList() {
      this.restrictedAlert = false;
      this.postJson(window.base_url + "/api/shiftstatus/list", null, (res) => {
        // console.log(res)
        this.restrictLogPeriodYM = res.data.restrict_log_period_ym;
        if (res.data.code == 201) {
          //無償版の場合はアラートを出して処理続行
          this.restrictedAlert = true;
          this.restrictedMessage = res.data.message;
        } else if (res.data.code > 0) {
          alert(res.data.message);
          return;
        }
        this.list = res.data.list;
        this.updateDisp();
      });
    },
    // 画面から再展開ボタンを削除（2020/07/30）
    // expandPlan関数は未使用。
    expandPlan(item, force) {
      // console.log(item)
      const param = { shiftstatus: item, forceupdate: force };
      this.postJson(
        window.base_url + "/api/shiftstatus/plan/expand",
        param,
        (res) => {
          // console.log(res)

          if (res.data.code == 1) {
            if (confirm(res.data.message)) {
              // 強制更新
              this.expandPlan(item, 1);
              return;
            } else {
              // 処理中断
              return;
            }
          }

          if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }
          this.list = res.data.list;
          this.updateDisp();
        }
      );
    },
    changeStatus(item, trg, act) {
      // console.log(item, trg, act)
      const param = { shiftstatus: item, target: trg, action: act };
      this.postJson(
        window.base_url + "/api/shiftstatus/status/change",
        param,
        (res) => {
          // console.log(res)
          if (res.data.code != 0) {
            alert(res.data.message);
            return;
          }
          this.list = res.data.list;
          this.updateDisp();
        }
      );
    },
    // 画面から再展開ボタンを削除（2020/07/30）
    // getExpandPlanBtnColor関数は未使用。
    getExpandPlanBtnColor(item) {
      return item.plan_expanded_at == null ? "primary" : "warning";
    },
    // 画面から再展開ボタンを削除（2020/07/30）
    // getExpandPlanBtnLabel関数は未使用。
    getExpandPlanBtnLabel(item) {
      return item.plan_expanded_at == null ? "シフト展開" : "再展開";
    },
    onChangeMonth(ym) {
      if (this.restrictLogPeriodYM != null) {
        //無償版かつ閲覧制限期間がある場合設定期間より前の日付の管理追加を非活性にする
        if (ym >= this.restrictLogPeriodYM) {
          this.isRestricted = false;
        } else {
          this.isRestricted = true;
        }
      }
      this.updateDisp();
    },
    updateDisp() {
      const wk = this.list.filter((item) => {
        if (item.yearmonth == this.yearmonth) return true;
      });
      this.makeDataDisabled = wk.length != 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.max-area-component {
  .shiftstatus-table {
    margin-top: 5px;
    table {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}
</style>
