<template>
  <div>
    <v-row no-gutters class="mx-1" align="center">
      <TextMonthChangePicker v-model="calendarDate" />
      <v-btn
        class="ml-5"
        icon
        small
        elevation="2"
        @click="show_help = !show_help"
      >
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>
      <!-- <v-snackbar v-model="show_help" :timeout="0">
          <div>
            祝日は自動で公休にはなりません。祝日が公休である事業所
          </div>
          <div>
            は、都度公休を設定してください。こちらで設定した公休日
          </div>
          <div>
            が勤務形態一覧表の「常勤職員が通常勤務すべき日数」とし
          </div>
          <div>
           てカウントされ、常勤換算後の人数に影響します。
          </div>
          <v-row no-gutters justify="center">
            <v-btn light>
              以後表示しない
            </v-btn>
          </v-row>
      </v-snackbar> -->
      <v-spacer />
      <v-btn
        class="ma-3 font-weight-bold"
        :color="save_button_color"
        :disabled="!after_next_month"
        @click="save()"
      >
        <v-icon left>mdi-content-save</v-icon>保存
      </v-btn>
    </v-row>
    <v-row no-gutters>
      <v-alert
        class="mx-auto"
        :value="show_help"
        tile
        elevation="3"
        color="alert"
        colored-border
        border="bottom"
        type="warning"
        transition="slide-y-transition"
      >
        <v-row no-gutters>
          <span class="font-weight-bold">
            祝日は自動で公休にはなりません。
          </span>
          <v-spacer />
          <v-btn
            class="mr-2"
            color="alert"
            icon
            text
            x-small
            dark
            @click="show_help = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <div>
          祝日が公休である事業所は、都度公休を設定してください。こちらで設定した
        </div>
        <div>
          公休日が勤務形態一覧表の「常勤職員が通常勤務すべき日数」としてカウント
        </div>
        <div>され、常勤換算後の人数に影響します。</div>
        <div>
          また、公休（法定休日）は、給与計算ソフトの連携時に影響します。
        </div>
        <v-row no-gutters justify="center">
          <v-checkbox
            color="alert"
            v-model="notshow_help_check"
            @change="clickNotShowHelp"
            label="以後表示しない"
          />
        </v-row>
      </v-alert>
    </v-row>
    <v-row no-gutters>
      <v-col v-for="weekday in weekdays" :key="weekday">
        <v-card
          tile
          outlined
          :color="header_color"
          style="border-color: #cfcfcf"
        >
          <v-row no-gutters justify="center">
            <v-checkbox
              height="0"
              v-model="selected_weekdays"
              :value="weekday"
              :label="weekday"
              :disabled="!after_next_month"
              @change="selectWeekdays"
            />
          </v-row>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-auto" style="height: 550px">
      <v-calendar
        ref="calendar"
        locale="ja"
        :type="calendar_type"
        :start="date"
        :events="events"
        :event-color="getEventColor"
        :hide-header="true"
        @click:day="clickDate"
        @click:date="clickDate"
      >
      </v-calendar>
    </v-row>
    <LoadingDialog :loading="loading"></LoadingDialog>
    <v-snackbar bottom v-model="snackbar" :timeout="1000"
      >保存しました。</v-snackbar
    >
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";
import LoadingDialog from "../common/LoadingDialog";
import TextMonthChangePicker from "../common/TextMonthChangePicker";

export default {
  mixins: [axiosMixin, commonMixin],

  components: {
    LoadingDialog,
    TextMonthChangePicker,
  },

  inject: ["navigationManage"],

  data: (vm) => ({
    national_holidays: [],
    national_holiday_color: "purple",
    national_holiday_name: "",
    events: [],
    event_items: [
      { name: "公休", color: "cyan darken-2" },
      { name: "公休（法定休日）", color: "#fb8c00" },
    ],
    event_name: "公休",
    event_color: "cyan darken-2",
    legal_name: "公休（法定休日）",
    legal_color: "#fb8c00",
    header_color: "#eee",
    calendar_type: "month",
    date: vm.dateJp(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
    selected_weekdays: [],
    last_selected_weekdays: [],
    save_button_color: "primary",
    after_next_month: false,
    change_flg: false,
    snackbar: false,
    user_show_help_flg: null,
    show_help: false,
    notshow_help_check: false,
  }),

  computed: {
    calendarDate: {
      get() {
        return this.date.substr(0, 7);
      },
      set(v) {
        if (v == this.date.substr(0, 7)) return;
        if (this.change_flg === true) {
          if (!confirm("変更した内容が破棄されます。よろしいですか？")) {
            return;
          }
        }
        this.date = `${v}-01`;
        this.refreshCollection();
        this.loadHolidayCalendar();
      },
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.change_flg === true) {
      if (!confirm("変更した内容が破棄されます。よろしいですか？")) {
        this.$nextTick(() => {
          this.navigationManage(from);
        });
        return;
      }
    }
    next();
  },

  watch: {
    user_show_help_flg() {
      this.show_help = this.user_show_help_flg;
      this.notshow_help_check = !this.user_show_help_flg;
    },
  },

  mounted: function () {
    this.loadHolidayCalendar();
    this.show_help = this.user_show_help_flg;
  },

  methods: {
    loadHolidayCalendar() {
      // 祝日、公休日の読み込み
      this.postJson(
        window.base_url + "/api/holiday/search",
        { year_month: this.date.substr(0, 7) },
        (res) => {
          this.events = [];
          if (res.data.code > 0) {
            alert(res.data.message);
            return;
          }
          if (res.data.national_holidays != null) {
            res.data.national_holidays.forEach((nationalHoliday) => {
              this.national_holiday_name =
                nationalHoliday.national_holiday_name;
              this.addNationalHolidayToEvents(
                nationalHoliday.national_holiday.substr(0, 10)
              );
            });
          }

          res.data.registed_holidays.forEach((holiday) => {
            this.addDateToEvents(
              holiday.public_holiday.substr(0, 10),
              holiday.holiday_option
            );
          });
          this.user_show_help_flg = res.data.show_holidays_help;
        }
      );
      this.after_next_month = this.isAfterNextMonth();
      this.change_flg = false;
    },

    save() {
      if (confirm("データを保存しますか？")) {
        let holidays = [];
        this.events.forEach((event) => {
          if (
            event.name === this.event_items[0].name ||
            this.event_items[1].name
          ) {
            if (event.isLegal == false) {
              holidays.push({ public_holiday: event.start, holiday_option: 0 });
            } else if (event.isLegal == true) {
              holidays.push({ public_holiday: event.start, holiday_option: 1 });
            }
          }
        });

        this.postJson(
          window.base_url + "/api/holiday/update",
          {
            update_holiday_datas: holidays,
            year_month: this.date.substr(0, 7),
          },
          (res) => {
            this.loadHolidayCalendar();
            this.snackbar = true;
            if (res.data.expanded === true) {
              alert(
                "既に当月のシフトが展開されています。\r\n保存した公休日をシフトに反映する場合は再度シフト展開してください。"
              );
            }
          }
        );
      }
    },

    clickDate(clicked) {
      // 来月以降のみ編集可能
      if (this.isAfterNextMonth() === false) return;

      // フォーマット時の日付のずれを予防するためDate.UTCによる初期化を行う
      const date = new Date(this.date),
        firstDate = new Date(
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
        ),
        lastDate = new Date(
          Date.UTC(date.getFullYear(), date.getMonth() + 1, 0)
        );

      let clickedDate = new Date(clicked.date);

      // カレンダーに表示される前月および来月の日付は変更不可
      if (clickedDate < firstDate) return;
      else if (clickedDate > lastDate) return;

      // 公休日かつ同じ日付のイベントを探す
      let sameDateIndex = this.events.findIndex(
        (event) =>
          event.start === clicked.date &&
          (event.name === this.event_items[0].name ||
            event.name === this.event_items[1].name)
      );
      // 見つからなかった場合追加、そうでなければ法定休日フラグをtrueにする。法定休日フラグがtrueの場合削除する。
      if (sameDateIndex === -1) {
        this.addDateToEvents(clicked.date, 0);
      } else if (this.events[sameDateIndex].isLegal == false) {
        this.events[sameDateIndex].isLegal = true;
        this.events[sameDateIndex].name = this.event_items[1].name;
        this.events[sameDateIndex].color = this.event_items[1].color;
      } else {
        this.events.splice(sameDateIndex, 1);
      }
      this.change_flg = true;
    },

    isAfterNextMonth() {
      // 編集可能な月かチェックする
      let calendarsDate = new Date(this.date);
      let nowDate = new Date(
        Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)
      );
      return nowDate <= calendarsDate;
    },

    addDateToEvents(date, option) {
      let legal = false;
      if (option == 1) {
        legal = true;
      }
      this.events.push({
        name: this.event_items[option].name,
        start: date,
        end: date,
        color: this.event_items[option].color,
        isLegal: legal,
      });
    },

    addNationalHolidayToEvents(date) {
      this.events.push({
        name: this.national_holiday_name,
        start: date,
        end: date,
        color: this.national_holiday_color,
      });
    },

    getEventColor(event) {
      return event.color;
    },

    selectWeekdays(selectedWeekDays) {
      const date = new Date(this.date);
      let targetDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), 1)
      );
      if (selectedWeekDays.length > this.last_selected_weekdays.length) {
        targetDate = this.getFirstWeekDay(
          selectedWeekDays[selectedWeekDays.length - 1]
        );
        // 選択した曜日と一致する日付を追加する
        while (targetDate.getMonth() === date.getMonth()) {
          // 祝日を除き、重複した日付は追加しない
          if (
            this.events.findIndex(
              (event) =>
                event.start === targetDate.toISOString().substr(0, 10) &&
                (event.name === this.event_items[0].name ||
                  event.name === this.event_items[1].name)
            ) === -1
          ) {
            this.addDateToEvents(targetDate.toISOString().substr(0, 10), 0);
          }
          targetDate.setDate(targetDate.getDate() + this.weekdays.length);
        }
      } else {
        selectedWeekDays
          .concat(this.last_selected_weekdays)
          .forEach((weekday) => {
            if (
              !selectedWeekDays.includes(weekday) &&
              this.last_selected_weekdays.includes(weekday)
            ) {
              targetDate = this.getFirstWeekDay(weekday);
            }
          });
        // 選択した曜日と一致する日付を祝日を除き削除する
        while (targetDate.getMonth() === date.getMonth()) {
          let deleteIndex = this.events.findIndex(
            (event) =>
              event.start === targetDate.toISOString().substr(0, 10) &&
              (event.name === this.event_items[0].name ||
                event.name === this.event_items[1].name)
          );
          if (deleteIndex != -1) {
            this.events.splice(deleteIndex, 1);
          }
          targetDate.setDate(targetDate.getDate() + this.weekdays.length);
        }
      }

      this.last_selected_weekdays = selectedWeekDays;
      this.change_flg = true;
    },

    getFirstWeekDay(weekday) {
      let targetDate = new Date(this.date);
      // 第一weekday曜日の日付を探す
      for (let i = 0; i < this.weekdays.length; i++) {
        targetDate.setDate(i + 1);
        if (weekday === this.weekdays[targetDate.getDay()]) {
          return targetDate;
        }
      }
    },

    refreshCollection() {
      this.selected_weekdays = []; // 曜日チェックボックスの選択
      this.last_selected_weekdays = []; // 曜日チェックボックスの選択バックアップ
      this.events = []; // 公休日および祝日
    },

    clickNotShowHelp() {
      this.postJson(
        window.base_url + "/api/holiday/updatehelp",
        { show_holidays_help: !this.notshow_help_check },
        () => {}
      );
    },
  },
};
</script>