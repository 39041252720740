<template>
  <div :style="styles">
    <v-navigation-drawer
      ref="naviDrawer"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      height="100%"
      app
    >
      <v-list dense>
        <v-list-item-group v-model="select" mandatory color="primary">
          <template v-for="item in items">
            <v-list-item :key="item.text" link @click.stop="linkto(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-on="on">
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </v-list-item>
          </template>
          <template v-if="show_systems">
            <v-list-group
              no-action
              prepend-icon="mdi-laptop-chromebook"
              color="primary"
              :value="is_opened_system_list"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="'システム管理'" />
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="systemitem in systemitems"
                class="pl-10"
                :key="systemitem.text"
                link
                @click.stop="linkto(systemitem)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item-icon v-on="on">
                      <v-icon>{{ systemitem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content v-on="on">
                      <v-list-item-title v-text="systemitem.text" />
                    </v-list-item-content>
                  </template>
                  <span>{{ systemitem.text }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>
      <v-row no-gutters justify="center">
        <v-btn
          class="font-weight-bold"
          color="warning"
          v-text="'ユーザーガイド'"
          :href="$url.user_guide"
          target="_blank"
        />
      </v-row>
    </v-navigation-drawer>

    <v-app-bar
      ref="appHeader"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      app
      dark
      flat
      class="font-weight-bold"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-img
        v-if="!$vuetify.breakpoint.xs"
        src="../assets/E-1-3_white_pink_E-1-3_white_pink.svg"
        max-width="160px"
        height="35.19px"
      />
      <v-toolbar-title class="ml-0 pl-4">
        <span>{{ title_text }}</span>
      </v-toolbar-title>
      <v-row no-gutters align="center" justify="end">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn dark icon large v-on="on">
              <v-avatar color="white" size="36">
                <v-icon color="primary">mdi-account</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$refs.staffDialog.open()">
              <v-list-item-title>職員詳細</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title>ログアウト</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <span class="ml-3 text-truncate" v-if="!$vuetify.breakpoint.xs">{{
        name
      }}</span>
    </v-app-bar>
    <div id="top-ad" class="advertising" v-if="$ad.top.height !== '0px'">
      <iframe :src="$ad.top.url"></iframe>
    </div>

    <v-main class="main-base">
      <v-container class="main-container" fluid>
        <router-view></router-view>
      </v-container>
      <div
        id="bottom-ad"
        class="advertising"
        v-if="$ad.bottom.height !== '0px'"
      >
        <iframe :src="$ad.bottom.url"></iframe>
      </div>
    </v-main>
    <staff-dialog ref="staffDialog" :staffId="staffid"></staff-dialog>

    <v-footer app color="footer" height="45px">
      <v-row no-gutters align="center">
        <v-img
          src="../assets/ewell_logo-grayscale.svg"
          max-width="50px"
          height="35.38px"
        />
        <div class="ml-2 text-caption grey--text text--darken-1">
          Copyright(c) eWeLL, Inc. All rights reserved.
        </div>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import StaffDialog from "./staff/StaffDialog";
import cookieMixin from "../mixins/cookieMixin.js";
export default {
  name: "Main",
  mixins: [cookieMixin],
  components: {
    StaffDialog,
  },
  provide: function () {
    return {
      navigationManage: this.navigationManage,
    };
  },
  data: () => ({
    drawer: null,
    select: 0,
    name: "",
    show_systems: false,
    is_opened_system_list: false,
    items: [
      { icon: "mdi-alarm", url: "/stamp", text: "治験打刻" },
      { icon: "mdi-notebook", url: "/attendprivate", text: "治験実績表" },
      {
        icon: "mdi-notebook-multiple",
        url: "/attendday",
        text: "治験実施状況",
      },
      // { icon: "mdi-phone-in-talk", url: "/oncall", text: "オンコール当番表" },
      // {
      //   icon: "mdi-phone-in-talk",
      //   url: "/oncallrequest",
      //   text: "オンコール当番申請",
      // },
    ],
    systemitems: [
      { icon: "mdi-account-multiple", url: "/staffs", text: "職員管理" },
      // {
      //   icon: "mdi-calendar-heart",
      //   url: "/holidayCalendar",
      //   text: "休日カレンダー",
      // },
      // {
      //   icon: "mdi-calendar-clock",
      //   url: "/shiftPatterns",
      //   text: "シフトテンプレート",
      // },
      // { icon: "mdi-calendar-check", url: "/shiftstatuses", text: "シフト管理" },
      // {
      //   icon: "mdi-printer-settings",
      //   url: "/worktypelist",
      //   text: "勤務形態一覧表",
      // },
      {
        icon: "mdi-text-box-multiple-outline",
        url: "/office",
        text: "基本情報",
      },
      {
        icon: "mdi-table-arrow-down",
        url: "/attenddatalink",
        text: "治験実績出力",
      },
    ],
    staffid: window.id,
    title_text: "",
    headerHeight: "64px",
    adMarginLeft: "0px",
  }),
  created: function () {
    this.name = window.name;
    if (window.auth == 2) {
      this.show_systems = true;
    } else {
      let removeIndex = this.items.findIndex(({ url }) => url === "/attendday");
      if (removeIndex > -1) {
        this.items.splice(removeIndex, 1);
      }
    }
  },
  mounted: function () {
    if (this.$router.currentRoute.path === "/") {
      this.$router.replace(this.items[0].url);
    }
    this.is_opened_system_list =
      this.systemitems.findIndex(
        ({ url }) => url === this.$router.currentRoute.path
      ) != -1;
    this.handleResize();
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to) {
        this.navigationManage(to);
      },
    },
    "$vuetify.breakpoint.xs"() {
      this.handleResize();
    },
    "$vuetify.breakpoint.sm"() {
      this.handleResize();
    },
    "$vuetify.breakpoint.md"() {
      this.handleResize();
    },
    "$vuetify.breakpoint.lg"() {
      this.handleResize();
    },
    "$vuetify.breakpoint.xl"() {
      this.handleResize();
    },
    drawer() {
      this.handleResize();
    },
  },
  computed: {
    styles() {
      return {
        "--header-height": this.headerHeight,
        "--top-ad-height": this.$ad.top.height,
        "--bottom-ad-height": this.$ad.bottom.height,
        "--ad-margin-left": this.adMarginLeft,
      };
    },
  },
  methods: {
    linkto(item) {
      if (this.$router.currentRoute.path == item.url) return;
      this.$router.push(item.url);
    },
    logout() {
      //tokenクリア
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("reftoken");
      if (window.isiBow) {
        //最近のブラウザではscriptからcloseできないらしい（暫定対応）
        window.open("about:blank", "_self").close();
        return;
      }
      window.token = "";
      window.reftoken = "";
      window.id = "";
      window.name = "";
      window.level = -1;
      window.auth = "";
      location.href = "/";
    },
    navigationManage(to) {
      let paths = to.path.toLowerCase().split("/");
      for (var i = 0; i < this.items.length; i++) {
        if ("/" + paths[1] == this.items[i].url.toLowerCase()) {
          this.$nextTick(() => {
            this.select = i;
            this.title_text = this.items[i].text;
          });
          return;
        }
      }
      for (var j = 0; j < this.systemitems.length; j++) {
        if ("/" + paths[1] == this.systemitems[j].url.toLowerCase()) {
          this.$nextTick(() => {
            this.select = i + (j + 1);
            this.title_text = this.systemitems[j].text;
          });
          return;
        }
      }
    },
    handleResize() {
      // 広告マージン
      if (this.$vuetify.breakpoint.lgAndUp && this.drawer) {
        this.adMarginLeft = this.$refs.naviDrawer.width + "px";
      } else {
        this.adMarginLeft = "0px";
      }

      // ヘッダマージン
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.headerHeight = "64px";
      } else {
        this.headerHeight = "56px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-base {
  padding-top: calc(var(--header-height) + var(--top-ad-height)) !important;
  padding-bottom: 0px !important;
  margin-bottom: 45px;
  .main-container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.advertising {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff77;
  iframe {
    height: 100%;
    width: 100%;
    border: none;
    vertical-align: bottom;
  }
}

#top-ad {
  position: fixed;
  z-index: 3;
  margin-left: var(--ad-margin-left);
  margin-top: var(--header-height);
  height: var(--top-ad-height);
  width: calc(100% - var(--ad-margin-left));
}

#bottom-ad {
  height: var(--bottom-ad-height);
  width: 100%;
}
</style>