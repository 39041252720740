import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';

Vue.use(VueRouter);

Vue.config.devtooles = true;
Vue.config.productionTip = false

window.axios = require('axios');
window.usertype = null;

window.base_url = process.env.VUE_APP_BACKEND_URL;
if (!window.base_url) {
  window.base_url = "http://localhost:8080";
}

//------------------------------
// グローバル変数
//------------------------------
// リンクURL設定
Vue.prototype.$url = {
  user_guide: process.env.VUE_APP_USER_GUIDE_URL,
  apply_subscription: process.env.VUE_APP_APPLY_SUBSCRIPTION_URL
}
// 広告表示情報
Vue.prototype.$ad = { top: { url: "", height: "0px" }, bottom: { url: "", height: "0px" } }

import staffs from './components/staff/Staffs';
import attendDay from './components/attend/AttendDay';
import attendPrivate from './components/attend/AttendPrivate';
import attendDataLink from './components/attenddatalink/OutputAttendCSV';
import stamp from './components/stamp/Stamp';
import office from './components/office/Office';
import workTypeView from './components/worktypelist/WorkTypeView';
import shiftPatterns from './components/shift/ShiftPatterns';
import shiftStatuses from './components/shiftstatus/ShiftStatuses';
import holidayCalendar from './components/calendar/HolidayCalendar';
import onCall from './components/oncall/Oncall';
import onCallRequest from './components/oncall/OncallRequest';

const routes = [
  { path: '/staffs', component: staffs },
  { path: '/attendday', component: attendDay },
  { path: '/attendprivate', component: attendPrivate },
  { path: '/attendprivate/:sid', component: attendPrivate },
  { path: '/attenddatalink', component: attendDataLink },
  { path: '/stamp', component: stamp },
  { path: '/office', component: office },
  { path: '/worktypelist', component: workTypeView },
  { path: '/shiftstatuses', component: shiftStatuses },
  { path: '/shiftPatterns', component: shiftPatterns },
  { path: '/holidayCalendar', component: holidayCalendar },
  { path: '/oncall', component: onCall },
  { path: '/oncallrequest', component: onCallRequest },
];

const router = new VueRouter({
  routes
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
