<template>
  <div style="width:150px">
    <v-menu
      ref="targetDate"
      v-model="pickerFlag"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          :disabled="disabled"
          v-model="dateFormatted"
          :class="textClass"
          :label="label"
          :rules="rules"
          :readonly="readonly"
          @blur="inputDate"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        locale="ja"
        no-title
        @input="pickerFlag = false"
        :min="min"
        :max="max"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["label", "value", "rules", "textClass", "disabled", "min", "max", "readonly", "mode"],

  data: vm => ({
    pickerFlag: false,
    formattingDate: vm.value
  }),

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.formattingDate = newValue;
        this.$emit("input", newValue);
      }
    },
    dateFormatted: {
      get() {
        if (!this.date) return;
        if (this.mode == "ja") {
          const [year, month, day] = this.date.split("-");
          return `${year}年${month}月${day}日`;
        } else {
          const [year, month, day] = this.date.split("-");
          return `${year}/${month}/${day}`;
        }
      },
      set(newValue) {
        let regex = /^([0-9]+?)\/([0-9]+?)\/([0-9]+?)$/g;
        let result = regex.exec(newValue);
        if (result) {
          this.formattingDate = this.convertDate(result);
          return;
        }
        regex = /^([0-9]+?)-([0-9]+?)-([0-9]+?)$/g;
        result = regex.exec(newValue);
        if (result) {
          this.formattingDate = newValue;
          return;
        }
        regex = /^([0-9]+?)年([0-9]+?)月([0-9]+?)日$/g;
        result = regex.exec(newValue);
        if (result) {
          this.formattingDate = this.convertDate(result);
          return;
        }
      }
    }
  },

  methods: {
    inputDate() {
      this.$emit("input", this.formattingDate);
    },
    convertDate(result) {
      const year = result[1];
      const month = result[2];
      const day = result[3];
      return (
        ("000" + year).slice(-4) +
        "-" +
        ("0" + month).slice(-2) +
        "-" +
        ("0" + day).slice(-2)
      );
    }
  }
};
</script>

<style>
</style>