<template>
  <div class="max-area-component">
    <v-row no-gutters style="max-width: 600px">
      <v-col>
        <v-text-field v-model="staffName" label="職員名" clearable />
      </v-col>
    </v-row>
    <v-row no-gutters align="end">
      <v-col>
        <v-select :items="jobs" label="職種" v-model="selectJob" clearable />
      </v-col>
      <!-- <v-col>
        <v-select
          :items="workTypes"
          label="勤務形態"
          v-model="selectWorkType"
          clearable
        />
      </v-col> -->
      <v-col>
        <v-select
          class="status"
          :items="statuses"
          label="就業状況"
          v-model="selectStatuses"
          multiple
          chips
          clearable
        >
          <template v-slot:selection="{ item }">
            <v-chip class="font-weight-bold" color="primary" dark>
              <v-icon left>{{ item.icon }}</v-icon>
              <span>{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="start">
      <v-btn
        class="ml-3 mb-10 font-weight-bold"
        color="append"
        dark
        @click="getList"
      >
        <v-icon left>mdi-magnify</v-icon>検索
      </v-btn>
    </v-row>
    <v-row no-gutters align="center" justify="start">
      <v-btn
        class="ml-3 mb-2 font-weight-bold white--text"
        v-show="!is_linked_ibow"
        :disabled="is_usage_limit_total_id"
        color="append"
        @click="staffDitail()"
      >
        <v-icon left>mdi-plus</v-icon>職員追加
      </v-btn>
      <!-- <span
        class="ml-4"
        v-html="usage_limit_comment"
        :style="usage_limit_comment_color"
      />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-help-circle</v-icon>
        </template>
        <span v-html="usage_limit_help_text"></span>
      </v-tooltip>
      <v-spacer /> -->
      <span class="mr-2">現在の利用ID数</span>
      <span class="mx-4">{{ staff_id_total_usage }}</span>
      <span class="mr-3">ID</span>
    </v-row>
    <v-data-table
      height="100%"
      no-data-text="データが見つかりませんでした。"
      :headers="headers"
      :items="staffs"
      hide-default-footer
      disable-pagination
      @click:row="staffDitail"
      fixed-header
      class="elevation-1 staffs-table mb-3"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row no-gutters>
          <!-- <v-col style="padding: 3px">
            <v-btn
              class="font-weight-bold"
              color="primary"
              small
              @click.stop="clickShift(item)"
              >シフト編集</v-btn
            >
          </v-col>
          <v-col style="padding: 3px">
            <v-btn
              class="font-weight-bold"
              color="primary"
              small
              @click.stop="clickExpandShift(item)"
              >シフト展開</v-btn
            >
          </v-col> -->
          <v-col style="padding: 3px">
            <v-btn
              class="font-weight-bold"
              color="primary"
              small
              :to="/attendprivate/ + item.id"
              >治験実績表</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <!-- シフト登録画面 -->
    <shift-tab ref="shiftDialog" :staffId="selectStaffId" />
    <!-- 詳細画面 -->
    <staff-dialog ref="dialog" :staffId="staffId" @updated="updated" />
    <!-- シフト展開画面 -->
    <staff-expand-shift ref="expandShift" />
    <!-- ローディング -->
    <loading-dialog :loading="loading"></loading-dialog>
    <!-- スナックバー -->
    <v-snackbar bottom v-model="snackbar" :timeout="1000">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import StaffMixin from "./StaffMixin";
import ShiftTab from "./ShiftTab";
import StaffDialog from "./StaffDialog";
import StaffExpandShift from "./StaffExpandShiftDialog";
import LoadingDialog from "../common/LoadingDialog";

export default {
  components: {
    StaffDialog,
    LoadingDialog,
    ShiftTab,
    StaffExpandShift,
  },
  mixins: [axiosMixin, StaffMixin],
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      // { text: '職員ID', value: 'staff_no' },
      { text: "職員名", value: "s_name", width: "20%" },
      { text: "職種", value: "job_name", width: "20%" },
      { text: "ステータス", value: "s_status" },
      { text: "リンクボタン", value: "actions", sortable: false },
    ],
    staffs: [],
    jobs: [],
    workTypes: [],
    staffName: "",
    selectJob: null,
    selectWorkType: null,
    selectStatuses: [],
    staffId: 0,
    selectStaffId: 0,
    snackbar: false,
    snackMessage: "",
    is_linked_ibow: false,
    staff_id_total_usage: 0,
    total_usage_id: 0,
    show_total_id_help: false,
    is_usage_limit_total_id: false,
    usage_limit_comment: "",
    usage_limit_comment_color: "color:black;",
    usage_limit_help_text:
      "職員の登録数には制限があります。<br>無料版…最大50人まで<br>有料版…お申し込みの契約内容によって異なります。",
  }),
  mounted() {
    this.selectStatuses = this.statuses
      .filter((status) => status.value != 4)
      .map((status) => status.value);
    this.postJson(window.base_url + "/api/staff/init", null, (res) => {
      if (res.data.code != 0) {
        alert(res.data.message);
        return;
      }
      this.jobs = res.data.jobs;
      this.workTypes = res.data.work_types;
      this.getList();
    });
  },
  computed: {
    req() {
      return {
        serch_condition: {
          staff_name: this.staffName,
          job_id: this.selectJob,
          work_type_id: this.selectWorkType,
          statuses: this.selectStatuses,
        },
      };
    },
  },
  methods: {
    getList() {
      this.postJson(window.base_url + "/api/staff/list", this.req, (res) => {
        if (res.data.code != 0) {
          alert(res.data.message);
          return;
        }
        this.staffs = res.data.staffs;
        this.is_linked_ibow = res.data.is_linked_ibow;
        this.staff_id_total_usage = res.data.staff_id_total_usage;
        this.total_usage_id = res.data.total_usage_id;
        this.is_usage_limit_total_id = res.data.is_usage_limit_total_id;
        if (this.is_usage_limit_total_id == false) {
          this.usage_limit_comment =
            this.total_usage_id + "人まで登録できます。";
          this.usage_limit_comment_color = "color:black;";
        } else {
          this.usage_limit_comment =
            this.total_usage_id +
            "人まで登録できます。<br>これ以上登録できません。";
          this.usage_limit_comment_color = "color:red;";
        }
      });
    },
    staffDitail(row = null) {
      // 職員編集画面に遷移
      this.staffId = row ? row.id : 0;
      this.$refs.dialog.open();
    },
    clickShift(item) {
      // シフト登録画面に遷移
      this.selectStaffId = item.id;
      this.$refs.shiftDialog.open();
    },
    async clickExpandShift(item) {
      this.snackMessage = "シフトを展開しました。";
      this.snackbar = await this.$refs.expandShift.open(item.id, true);
    },
    updated(message) {
      this.snackMessage = message;
      this.snackbar = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.max-area-component {
  .staffs-table {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
</style>