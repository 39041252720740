<template>
  <v-app>
    <!-- <Login
      @login="loginsuccess"
      @startPassword="startPasswordchange"
      @startAdminPassword="startAdminPasswordReminder"
      v-if="!logedin && !ibowlogin && !passwordchange && !adminpasswordreminder"
      :snackbar="snackbar"
      :snackmessage="snackmessage"
    /> -->
    <Main
      v-if="logedin && !passwordchange && !adminpasswordreminder && isAgree"
    />
    <!-- <Passwordchange
      v-if="passwordchange"
      @endPassword="endPasswordchange"
      :logedin="logedin"
      :message="message"
    /> -->
    <!-- <AdminPasswordReminder
      v-if="adminpasswordreminder"
      @endAdminPassword="endAdminPasswordReminder"
      @displaySnackbar="displaySnackbar"
    /> -->
    <InlineFrame
      v-if="!isAgree && logedin && isChikenUser"
      :isChikenUser="isChikenUser"
      @attention="attention()"
    />
    <InfoInlineFrame
      v-if="!isAgree && logedin && !isChikenUser"
      :isChikenUser="isChikenUser"
    />
  </v-app>
</template>

<script>
import Main from "./components/Main";
// import Login from "./components/Login";
// import Passwordchange from "./components/Passwordchange";
// import AdminPasswordReminder from "./components/AdminPasswordReminder";
import cookieMixin from "./mixins/cookieMixin.js";
import jwtMixin from "./mixins/jwtMixin.js";
import InlineFrame from "./components/InlineFrame";
import InfoInlineFrame from "./components/InfoInlineFrame";

export default {
  name: "App",
  mixins: [cookieMixin, jwtMixin],
  data: () => ({
    logedin: false,
    ibowlogin: false,
    passwordchange: false,
    adminpasswordreminder: false,
    message: "",
    snackbar: false,
    snackmessage: "",
    isAgree: false,
    isChikenUser: false, // 治験ユーザー判定
  }),
  created: function () {
    window.isiBow = false;
    const token = sessionStorage.getItem("token");
    const reftoken = sessionStorage.getItem("reftoken");
    this.isChikenUser = location.href.indexOf("chiken_div=1") != -1;
    if (token != undefined && reftoken != undefined) {
      // ブラウザ再読み込みの処理（ログイン済みと判断する）
      window.token = token;
      window.reftoken = reftoken;
      const payload = this.getPayloadFromJwtToken(token);
      window.id = payload.id;
      window.name = payload.name;
      window.auth = payload.auth;

      // ログインフラグ更新
      this.logedin = true;
      const isiBow = location.href.indexOf("?onetime=") != -1;
      this.ibowlogin = isiBow;
      window.isiBow = isiBow;

      return;
    }

    if (location.href.indexOf("?onetime=") != -1) {
      var st = location.href.indexOf("?onetime=");
      var en = location.href.indexOf("&chiken_div");
      this.ibowlogin = true;
      window.isiBow = true;
      this.onetime(location.href.substring(st + 9, en));
    }
    if (location.href.indexOf("chiken_div=2") != -1) {
      this.logedin = true;
      this.ibowlogin = true;
      window.isiBow = true;
    }
  },
  methods: {
    loginsuccess() {
      this.logedin = true;
      console.log(window.usertype);
    },
    startPasswordchange(message) {
      this.passwordchange = true;
      this.message = message;
    },
    endPasswordchange() {
      this.passwordchange = false;
    },
    startAdminPasswordReminder() {
      this.adminpasswordreminder = true;
      this.snackbar = false;
    },
    endAdminPasswordReminder() {
      this.adminpasswordreminder = false;
    },
    displaySnackbar(message) {
      this.snackmessage = message;
      this.snackbar = true;
    },
    onetime(token) {
      window.axios
        .post(window.base_url + "/auth/fromonetime", { one_time_token: token })
        .then(
          function (response) {
            console.log(response);
            if (response.data.code != 0) {
              alert(response.data.message);
            } else {
              const payload = this.getPayloadFromJwtToken(response.data.token);
              window.token = response.data.token;
              window.reftoken = response.data.ref_token;
              window.name = payload.name;
              window.auth = payload.auth;
              sessionStorage.setItem("token", window.token, {
                samesite: "strict",
              });
              sessionStorage.setItem("reftoken", window.reftoken, {
                samesite: "strict",
              });
              this.logedin = true;
            }
          }.bind(this)
        )
        .catch(
          function (error) {
            alert("サーバでエラーが発生しました。\n(" + error + ")");
            console.log(error);
            console.log(error.response);
          }.bind(this)
        );
    },
    attention() {
      this.setCookie("isAgree", "true");
      this.isAgree = true;
    },
  },
  components: {
    // Login,
    Main,
    // Passwordchange,
    // AdminPasswordReminder,
    InlineFrame,
    InfoInlineFrame,
  },
};
</script>

<style lang="scss" scoped>
</style>
