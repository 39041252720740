<template>
  <v-menu content-class="time-picker-area" nudge-bottom="40px">
    <template v-slot:activator="{ on }">
      <div class="time-picker" ref="timePicker">
        <div class="input">
          <v-text-field
            :label="hoursLabel"
            v-model="HH"
            :disabled="disabled"
            :suffix="hoursSuffix"
            :rules="hoursRules"
            ref="hourArea"
            type="Number"
            min="0"
            :id="uuid + '-hour-area'"
            @keyup="onKeyup($event, '-hour')"
            @click="onFocus($event, on)"
          />
        </div>
        <div>
          <v-text-field
            :label="minutesLabel"
            v-model="MM"
            :disabled="disabled"
            prefix=":"
            :suffix="minutesSuffix"
            :rules="minutesRules"
            ref="minuteArea"
            type="Number"
            min="0"
            :id="uuid + '-minute-area'"
            @keyup="onKeyup($event, '-minute')"
            @click="onFocus($event, on)"
          />
        </div>
      </div>
    </template>
    <div class="select" ref="select">
      <div class="select-inner-area">
        <v-row no-gutters>
          <v-col cols="6" class="time-area" ref="hour" :id="uuid + '-hour'">
            <ul class="hour">
              <li
                class="hour-item"
                v-for="t in hours"
                :key="t"
                @click.stop="clickHour(Number(t))"
              >
                {{ t }}
              </li>
            </ul>
          </v-col>
          <v-col cols="6" class="time-area" ref="minute" :id="uuid + '-minute'">
            <ul class="minute">
              <li
                class="minute-item"
                v-for="m in minites"
                :key="m"
                @click.stop="clickMinute(Number(m))"
              >
                {{ m }}
              </li>
            </ul>
          </v-col>
          <div class="center-line">:</div>
        </v-row>
      </div>
    </div>
  </v-menu>
</template>

<script>
import commonMixin from "../../mixins/commonMixin";
import { uuid } from "vue-uuid";

export default {
  mixins: [commonMixin],

  props: {
    hh: {
      // 時 親コンポーネントで :hh.sync= で値を同期
      type: Number,
      default: 0,
    },
    mm: {
      // 分 親コンポーネントで :mm.sync= で値を同期
      type: Number,
      default: 0,
    },
    hours: {
      // 時 選択肢（デフォルト0~47）
      type: Array,
      default: () =>
        Array(48)
          .fill(1)
          .map((_, i) => ("0" + i).slice(-2)),
    },
    minites: {
      // 分 選択肢（デフォルト0~59）
      type: Array,
      default: () =>
        Array(60)
          .fill(1)
          .map((_, i) => ("0" + i).slice(-2)),
    },
    disabled: {
      type: Boolean,
    },
    hoursLabel: {
      type: String,
      default: "",
    },
    hoursSuffix: {
      type: String,
      default: "",
    },
    hoursRules: {
      type: Array,
      default: () => {
        return [(v) => !v || /^[0-9]*$/.test(v) || ""];
      },
    },
    minutesSuffix: {
      type: String,
      default: "",
    },
    minutesLabel: {
      type: String,
      default: "",
    },
    minutesRules: {
      type: Array,
      default: () => {
        return [(v) => !v || /^[0-9]*$/.test(v) || ""];
      },
    },
  },

  data: () => ({
    uuid: uuid.v4(),
    isFocus: false,
  }),

  computed: {
    HH: {
      get: function () {
        return this.hh;
      },
      set: function (val) {
        let tmp = String(val).replace(/\D/g, "");
        if (tmp !== "") {
          tmp = Number(tmp);
          const p = window.document.getElementById(this.uuid + "-hour");
          if (p) {
            const c = p.getElementsByClassName("hour");
            if (c.length > 0) {
              c[0].children.forEach((element, index) => {
                if (index == tmp) {
                  element.style.fontWeight = "900";
                } else {
                  element.style.fontWeight = "normal";
                }
              });
            }
          }
        } else {
          tmp = null;
        }
        this.$emit("update:hh", tmp);
        if (this.MM === null) {
          this.$emit("update:mm", 0);
        }
      },
    },
    MM: {
      get: function () {
        return this.mm;
      },
      set: function (val) {
        let tmp = String(val).replace(/\D/g, "");
        if (tmp !== "") {
          tmp = Number(tmp);
          const p = window.document.getElementById(this.uuid + "-minute");
          if (p) {
            const c = p.getElementsByClassName("minute");
            if (c.length > 0) {
              c[0].children.forEach((element, index) => {
                if (index == tmp) {
                  element.style.fontWeight = "900";
                } else {
                  element.style.fontWeight = "normal";
                }
              });
            }
          }
        } else {
          tmp = null;
        }
        this.$emit("update:mm", tmp);
      },
    },
  },

  methods: {
    onKeyup(event, prefix) {
      if (event.target.validity.badInput) {
        event.target.value = null;
      } else {
        if (prefix == "-minute") {
          if (event.target.value >= 59) {
            this.$nextTick(() => {
              event.target.value = 59;
              let ele = window.document.getElementById(
                this.uuid + "-minute-area"
              );
              ele.value = 59;
            });
          }
        } else if (prefix == "-hour") {
          if (event.target.value >= 47) {
            this.$nextTick(() => {
              event.target.value = 47;
              let ele = window.document.getElementById(
                this.uuid + "-hour-area"
              );
              ele.value = 47;
            });
          }
        }
        const ele = window.document.getElementById(this.uuid + prefix);
        this.movePosition(ele, event.target.value);
      }
    },
    onFocus(event, on) {
      setTimeout(() => {
        const hour = window.document.getElementById(this.uuid + "-hour");
        const minute = window.document.getElementById(this.uuid + "-minute");
        this.movePosition(hour, this.HH);
        this.movePosition(minute, this.MM);
        this.addScrollListener(hour, (val) => {
          this.HH = val;
        });
        this.addScrollListener(minute, (val) => {
          this.MM = val;
        });
      }, 50);
      on.click(event);
      this.isFocus = true;
    },
    addScrollListener(ele, setFunc) {
      if (!ele) {
        return;
      }
      let timeoutId = 0;
      let finish = false;
      ele.onscroll = () => {
        clearTimeout(timeoutId);
        if (!finish) {
          const thisScroll = ele.scrollTop;
          const positionNo = Math.round(thisScroll / 28);
          setFunc(positionNo);
          timeoutId = setTimeout(() => {
            const thisScroll = ele.scrollTop;
            const positionNo = Math.round(thisScroll / 28);
            ele.scrollTo({
              top: positionNo * 28,
              behavior: "smooth",
            });
            finish = true;
          }, 500);
        } else {
          finish = false;
        }
      };
    },
    movePosition(ele, position) {
      if (!ele) {
        return;
      }
      ele.scrollTo({
        top: position * 28,
        behavior: "auto",
      });
    },
    clickHour(position) {
      const hour = window.document.getElementById(this.uuid + "-hour");
      this.movePosition(hour, position);
      this.HH = position;
    },
    clickMinute(position) {
      const minute = window.document.getElementById(this.uuid + "-minute");
      this.movePosition(minute, position);
      this.MM = position;
    },
  },
};
</script>

<style lang="scss">
.time-picker {
  display: flex;
  justify-content: center;

  input {
    text-align: center;
    vertical-align: middle;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.time-picker-area {
  border-radius: 8px;

  .select {
    overflow: hidden;
    height: 120px;
    width: 100%;
    z-index: 100;
    background: white;
    border: 1px solid #afafaf;
    border-radius: 8px;
    padding: 0px 0px 0px 0px;

    .select-inner-area {
      position: relative;
      .time-area {
        overflow: scroll;
        height: 120px;
        padding-top: 42px;
        padding-bottom: 54px;
      }

      ul {
        list-style-type: none;
        padding: 0px;

        li {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      .hour {
        text-align: center;
        padding-right: 5px;

        .hour-item {
          cursor: pointer;
        }
      }

      .minute {
        text-align: center;
        padding-left: 5px;

        .minute-item {
          cursor: pointer;
        }
      }

      .center-line {
        pointer-events: none;
        position: absolute;
        top: 42px;
        width: 100%;
        height: 24px;
        border: 1px solid #afafaf;
        border-width: 1px 0px 1px 0px;
        padding-left: 50%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .time-picker-area {
    .select {
      .select-inner-area {
        .hour {
          text-align: center;
          padding-right: 2px;
        }
        .minute {
          text-align: center;
          padding-right: 0px;
          padding-left: 8px;
        }
      }
    }
  }
}
</style>
