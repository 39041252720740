var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-area-component"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-alert',{staticClass:"ml-4",attrs:{"icon":"mdi-monitor-lock","type":"info","dense":""},model:{value:(_vm.restrictedAlert),callback:function ($$v) {_vm.restrictedAlert=$$v},expression:"restrictedAlert"}},[_vm._v(" "+_vm._s(_vm.restrictedMessage)+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('MonthPicker',{on:{"changeMonth":_vm.onChangeMonth},model:{value:(_vm.yearmonth),callback:function ($$v) {_vm.yearmonth=$$v},expression:"yearmonth"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"mr-3 font-weight-bold",staticStyle:{"padding-right":"6px"},attrs:{"color":"primary"},on:{"click":_vm.getList}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("更新 "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"margin-left":"6px"}},on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tips.reload)}})])],1),_c('v-btn',{staticClass:"font-weight-bold white--text",attrs:{"color":"append","disabled":_vm.makeDataDisabled || _vm.isRestricted},on:{"click":function($event){return _vm.createStatus(0)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("管理追加 ")],1)],1),_c('v-data-table',{staticClass:"elevation-1 shiftstatus-table",attrs:{"height":"100%","headers":_vm.headers,"items":_vm.list,"single-select":true,"options":_vm.options,"item-key":"yearmonth","no-data-text":"データなし","sort-by":"yearmonth","fixed-header":"","sort-desc":true,"items-per-page":25,"footer-props":{
      itemsPerPageOptions: [10, 25, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(){return [_c('span',{key:h.value},[_vm._v(_vm._s(h.text)+" "),(h.description != null)?_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"left":""}},on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(h.description)}})]):_vm._e()],1)]},proxy:true}}),{key:"item.yearmonth",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.yearmonthFormat(item.yearmonth)))])]}},{key:"item.plan_closed_at",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 font-weight-bold",attrs:{"disabled":item.plan_closed_at != null,"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.changeStatus(item, 'plan', 'close')}}},[_vm._v("シフト確定")]),_c('v-btn',{staticClass:"mr-2 font-weight-bold",attrs:{"disabled":item.plan_closed_at == null || item.actual_closed_at != null,"small":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.changeStatus(item, 'plan', 'open')}}},[_vm._v("解除")]),_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.plan_closed_at)))])]}},{key:"item.actual_closed_at",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 font-weight-bold",attrs:{"disabled":item.actual_closed_at != null,"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.changeStatus(item, 'actual', 'close')}}},[_vm._v("実績確定")]),_c('v-btn',{staticClass:"mr-2 font-weight-bold",attrs:{"disabled":item.actual_closed_at == null,"small":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.changeStatus(item, 'actual', 'open')}}},[_vm._v("解除")]),_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.actual_closed_at)))])]}}],null,true)}),_c('loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }