<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-container v-if="shiftpatterns.length > 0">
        <v-row
          no-gutters
          justify="center"
          v-for="item in shiftpatterns"
          :key="item.id"
        >
          <v-btn width="300px" color="blue" text @click="select(item)">{{
            item.shiftpattern_name
          }}</v-btn>
        </v-row>
      </v-container>
      <v-container v-else>
        <div style="text-align: center">
          シフトテンプレートが登録されていません。
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    shiftpatterns: Array,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    select(item) {
      if (
        !confirm(
          `「${item.shiftpattern_name}」を職員シフトに展開します。よろしいですか？`
        )
      ) {
        return;
      }
      this.$emit("select", item);
      this.close();
    },
  },
};
</script>