export default {
  methods: {
    getPayloadFromJwtToken(jwtToken) {
      const base64Url = jwtToken.split(".")[1]
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
      const jsonPayload = decodeURIComponent(
          atob(base64)
          .split("")
          .map(function(c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      const payload = JSON.parse(jsonPayload);
      if (payload.top_ad_url != null && payload.top_ad_height != null) {
        this.$ad.top = {url: payload.top_ad_url, height: `${payload.top_ad_height}px`}
      }
      if (payload.bottom_ad_url != null && payload.bottom_ad_height != null) {
        this.$ad.bottom = {url: payload.bottom_ad_url, height: `${payload.bottom_ad_height}px`}
      }
      return payload
    },
  },
}