export default {
	data() {
		return {
			phons_hyphen: "—",
		};
	},
    methods: {
        zeroPadding(num, length) {
            return ('0000000000' + num).slice(-length);
        },
        formatDate(date, hour, minute) {
            if (date === null || hour === null || minute === null) {
                return null
            }
            const tmpDate = date.replace(/-/g, "/")
            const addDay = Math.floor(hour / 24);
            let tmpHour = hour % 24;
            tmpHour = this.zeroPadding(tmpHour, 2);
            const tmpMinute = this.zeroPadding(minute, 2);
            if (addDay) {
                let [year, month, day] = tmpDate.split("/");
                month = this.zeroPadding(month, 2);
                day = this.zeroPadding(Number(day) + addDay, 2);
                return `${year}/${month}/${day} ${tmpHour}:${tmpMinute}:00`
            }
            return `${tmpDate} ${tmpHour}:${tmpMinute}:00`;
        },
        dateJp(date) {
            date.setTime(date.getTime() + 1000 * 60 * 60 * 9)
            return date.toISOString().substr(0, 10)
        },
        minuteToStrHours(num) {
            let hour = Math.floor(num / 60);
            let minute = num % 60;
            let dispHour = String(hour);
            if (dispHour.length < 3) {
                dispHour = this.zeroPadding(hour, 2)
            }

            return dispHour + ":" + this.zeroPadding(minute, 2);
        }
    },
}