<template>
  <v-container>
    <v-row no-gutters justify="center">
      <v-card-title>
        <div style="text-align: center" v-html="dateFormattedYMDHMS()" />
      </v-card-title>
    </v-row>
    <!-- <v-row no-gutters justify="center"> -->
    <div class="text-center font-weight-bold">
      <div>{{ greeting }}</div>
      <div>{{ staffName }} さん (ID：{{ sId }})</div>
    </div>
    <!-- </v-row> -->
    <v-row no-gutters>
      <!-- <v-col cols="12" class="text-center">
        <v-btn
          class="font-weight-bold"
          color="primary"
          x-large
          width="200px"
          @click="attend"
          :disabled="disableAttend"
        >
          <v-icon left>mdi-briefcase-clock</v-icon>出勤
        </v-btn>
      </v-col> -->
      <v-col cols="12" class="text-center py-2">
        <v-btn
          class="font-weight-bold"
          color="primary"
          x-large
          width="200px"
          @click="overAttend"
          :disabled="disableOverAttend"
        >
          <v-icon left>mdi-briefcase-clock-outline</v-icon>治験開始
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center py-2">
        <v-btn
          class="font-weight-bold"
          color="warning"
          x-large
          width="200px"
          @click="middle"
          :disabled="disableMiddle"
        >
          <v-icon left>mdi-clock-outline</v-icon>{{ textMiddle }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center py-2">
        <v-btn
          class="font-weight-bold white--text"
          color="append"
          x-large
          width="200px"
          @click="leave"
          :disabled="disableLeave"
        >
          <v-icon left>mdi-briefcase-check</v-icon>治験終了
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center py-2">
        <v-btn
          class="white--text font-weight-bold"
          x-large
          width="200px"
          color="cancel"
          to="/attendPrivate"
        >
          治験実績表
        </v-btn>
      </v-col>
    </v-row>

    <!-- confirm -->
    <stamp-confirm ref="confirm" @agree="submitMemo" />

    <!-- 休憩時間入力ダイアログ -->
    <rest-dialog ref="restDialog" />

    <!-- 実績割り振りダイアログ -->
    <allocate-actual-dialog ref="allocateActualDialog" />

    <!-- loading -->
    <loading-dialog :loading="loading" />
  </v-container>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import LoadingDialog from "../common/LoadingDialog";
import StampConfirm from "./StampConfirm";
import RestDialog from "./StampRestDialog";
import AllocateActualDialog from "./StampAllocateActualDialog";

export default {
  components: {
    LoadingDialog,
    StampConfirm,
    AllocateActualDialog,
    RestDialog,
  },
  mixins: [axiosMixin],
  data: () => ({
    date: null, // 表示用日付
    intervalId: null, // 表示用日付IntervalID
    disableAttend: true, // 出勤ボタン制御
    disableLeave: true, // 退勤ボタン制御
    disableOverAttend: true, // 時間外出勤ボタン制御
    disableMiddle: true, // 中抜けボタン制御
    textMiddle: "", // 中抜けボタンテキスト
    attendanceDiv: 0, // 0: 出勤前 1: 出勤中 2: 出勤済みorシフトなし 3: 中抜け中
    position: {}, // 位置情報
    attendance: [], // 出勤簿
    attendanceJobs: [], // 職種別出勤簿
    actualTime: 0, // 実働時間
    restTime: 0, // 休憩時間
    sId: "", // 職員ログインID
    staffName: "", // 職員氏名
    greeting: "", // 挨拶
    greetingsArray: [
      "おはようございます",
      "こんにちは",
      "こんばんは",
      "お疲れ様でした！",
      "お誕生日おめでとうございます！！",
    ],
    isStaffBirthday: false,
    timeOutSeq: 20000, // タイムアウト時間
    memo: "", // メモ
    middleMemo: "",
  }),
  mounted() {
    this.refresh();
    this.setTime();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    setTime() {
      this.intervalId = setInterval(() => {
        this.date = new Date();
        // console.log(this.date)
      }, 100);
    },
    // 出退勤ボタン制御
    refresh() {
      // 初期化
      this.disableAttend = true;
      this.disableLeave = true;
      this.disableOverAttend = true;
      this.disableMiddle = true;
      this.textMiddle = "業務開始";
      this.restTime = 0;
      this.sId = "";
      this.greeting = "";
      this.position = {};
      this.memo = "";
      this.middleMemo = "";
      // 出勤状態取得
      this.postJson(window.base_url + "/api/stamp/status", null, (res) => {
        if (res.data.code != 0) {
          alert(res.data.message);
          return;
        }
        // this.date          = res.data.attend_date;
        this.attendanceDiv = res.data.div;
        this.attendance = res.data.attendance;
        this.restTime = this.attendance.rest_time_shift;
        this.sId = res.data.s_id;
        this.staffName = res.data.s_name;
        this.isStaffBirthday = res.data.is_staff_birthday;
        this.timeOutSeq = res.data.time_out_seq;
        // 状態設定
        switch (this.attendanceDiv) {
          case 0: // 出勤前
            this.disableAttend = false; // 出勤ボタン活性
            this.disableOverAttend = false; // 時間外出勤ボタン活性
            break;
          case 1: // 出勤中
            this.disableLeave = false; // 退勤ボタン活性
            this.disableMiddle = false; // 中抜けボタン活性
            break;
          case 2: // 出勤済み or シフトなし
            this.disableOverAttend = false; // 時間外出勤ボタン活性
            break;
          case 3: // 中抜け中
            this.disableMiddle = false; // 中抜けボタン活性
            this.middleMemo = res.data.middle_memo;
            this.textMiddle = "業務終了";
            break;
        }
      });
    },

    // yyy年mm月dd日 \n hh:mm:ss
    dateFormattedYMDHMS() {
      if (!this.date) return null;
      var year = this.date.getFullYear();
      var month = this.date.getMonth() + 1;
      var date = this.date.getDate();
      var hh = this.zeroPaddingTime(this.date.getHours());
      var mm = this.zeroPaddingTime(this.date.getMinutes());
      var ss = this.zeroPaddingTime(this.date.getSeconds());
      if (this.greeting != this.greetingsArray[3]) {
        if (this.isStaffBirthday == true) {
          //誕生日はお疲れ様以外の挨拶を祝辞に変更する
          this.greeting = this.greetingsArray[4];
        } else {
          this.greeting = this.getGreeting(hh);
        }
      }

      return `${year}年${month}月${date}日<br /><span style="font-size:34px;">${hh}:${mm}:${ss}</span>`;
    },
    dateFormattedYMD() {
      if (!this.date) return null;
      const [year, month, day] = this.date
        .toISOString()
        .substr(0, 10)
        .split("-");
      return `${year}年${month}月${day}日`;
    },
    zeroPaddingTime(val) {
      if (val < 10) {
        return "0" + val;
      }
      return val;
    },
    // 位置情報取得
    getPosition() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          setTimeout(() => {
            reject("制限時間を超えました");
          }, this.timeOutSeq);
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            timeout: this.timeOutSeq,
          });
        } else {
          reject("位置情報が取得できない端末です");
        }
      });
    },

    getGreeting(hh) {
      if (hh <= 12) {
        return (this.greeting = this.greetingsArray[0]);
      } else if (hh <= 18) {
        return (this.greeting = this.greetingsArray[1]);
      } else {
        return (this.greeting = this.greetingsArray[2]);
      }
    },
    // 出勤処理
    // async attend() {
    //   if ((await this.openPositionConfirm()) == false) return;
    //   // 打刻
    //   this.postJson(
    //     window.base_url + "/api/stamp/attend",
    //     {
    //       attendance: this.attendance,
    //       position: this.position,
    //     },
    //     (res) => {
    //       if (res.data.code != 0) {
    //         alert(res.data.message);
    //         return;
    //       }
    //       this.$refs.confirm
    //         .open(
    //           this.dateFormattedYMD(),
    //           ["【打刻完了】", res.message, res.data.time],
    //           false,
    //           null
    //         )
    //         .then(() => {
    //           this.refresh();
    //         });
    //     }
    //   );
    // },

    // 退勤処理
    async leave() {
      // 実績未登録で退勤が押された場合は位置情報取得済みなので処理をスキップ
      if (!this.attendance.endtime_archive) {
        // 休憩時間再入力時に位置情報取得済みの場合はスキップし、休憩時間入力へ
        if (Object.keys(this.position).length == 0) {
          if ((await this.openPositionConfirm()) == false) return;
          this.loading = false;
        }
        // 休憩時間入力なし
        this.restTime = 0;
        // this.restTime = await this.$refs.restDialog.open(this.restTime, true);
        // if (this.restTime === false) {
        //   this.refresh();
        //   return;
        // }
      }
      // 退勤処理
      const params = {
        attendance: this.attendance,
        rest_time: this.restTime,
      };
      this.postJson(window.base_url + "/api/stamp/leave", params, (res) => {
        if (res.data.code == 0) {
          this.attendanceJobs = res.data.attendance_jobs;
          this.actualTime = res.data.actual_time;
        } else if (res.data.code == 302) {
          // 休憩時間不正 再入力
          var tmpTime = res.data.actual_time;
          var hour = Math.floor(tmpTime / 60);
          var minute = tmpTime % 60;
          var message = "(実働時間:" + hour + "時間" + minute + "分)";
          alert(res.data.message + message);
          this.leave();
          return;
        } else {
          alert(res.data.message);
          this.refresh();
          return;
        }
        // if (this.attendanceJobs.length > 1) {
        //   //職種が複数ある場合は職種別実績登録ダイアログを開く
        //   this.$refs.allocateActualDialog
        //     .open(this.actualTime, this.attendanceJobs, true)
        //     .then((items) => {
        //       this.attendanceJobs = items;
        //       //キャンセル押下時は何もしない
        //       if (this.attendanceJobs === false) {
        //         this.refresh();
        //         return;
        //       }
        //       this.stampLeaveTime();
        //     });
        // } else {
        //職種が複数あっても1つ目の職種に実働時間をそのまま設定する
        this.attendanceJobs[0].actual_time = this.actualTime;
        this.stampLeaveTime();
        // }
      });
    },
    stampLeaveTime() {
      const allocateparams = {
        attendance: this.attendance,
        position: this.position,
        rest_time: this.restTime,
        attendance_jobs: this.attendanceJobs,
      };
      this.postJson(
        window.base_url + "/api/stamp/allocateactuals",
        allocateparams,
        (res) => {
          if (res.data.code != 0) {
            alert(res.data.message);
            this.refresh();
            return;
          }
          //打刻完了
          this.$refs.confirm
            .open(
              this.dateFormattedYMD(),
              ["【打刻完了】", res.message, res.data.time],
              false,
              false
            )
            .then(() => {
              this.refresh();
              this.greeting = this.greetingsArray[3];
            });
        }
      );
    },

    // 時間外出勤処理
    async overAttend() {
      if ((await this.openPositionConfirm()) == false) return;
      // 打刻
      this.postJson(
        window.base_url + "/api/stamp/overattend",
        {
          position: this.position,
          memo: this.attendance.memo,
        },
        (res) => {
          if (res.data.code != 0) {
            alert(res.data.message);
            this.refresh();
            return;
          }
          this.$refs.confirm
            .open(
              this.dateFormattedYMD(),
              ["【打刻完了】", res.data.message, res.data.time],
              false,
              false
            )
            .then(() => {
              this.refresh();
            });
        }
      );
    },

    // 業務
    async middle() {
      if ((await this.openPositionConfirmMiddle()) == false) return;
      // 打刻
      // console.log(this.position);
      this.postJson(
        window.base_url + "/api/stamp/middle",
        {
          attendance_id: this.attendance.id,
          position: this.position,
          memo: this.memo,
        },
        (res) => {
          if (res.data.code != 0) {
            alert(res.data.message);
            this.refresh();
            return;
          }
          this.$refs.confirm
            .open(
              this.dateFormattedYMD(),
              ["【打刻完了】", res.data.message, res.data.time],
              false,
              false
            )
            .then(() => {
              this.refresh();
            });
        }
      );
    },
    async openPositionConfirm() {
      // 位置情報取得confirm
      if (
        !(await this.$refs.confirm.open(
          "【位置情報取得】",
          ["GPSで位置情報を取得します"],
          true,
          true,
          this.attendance.memo,
          "治験情報"
        ))
      )
        return false;
      this.attendance.memo = this.memo;
      // 位置情報取得処理
      this.loading = true;
      try {
        var res = await this.getPosition();
        this.position.latitude = res.coords.latitude;
        this.position.longitude = res.coords.longitude;
      } catch (e) {
        alert("位置情報の取得に失敗しました");
        // console.log(e);
      }
    },
    async openPositionConfirmMiddle() {
      // 位置情報取得confirm
      if (
        !(await this.$refs.confirm.open(
          "【位置情報取得】",
          ["GPSで位置情報を取得します"],
          true,
          true,
          this.middleMemo,
          "業務メモ"
        ))
      )
        return false;
      this.middleMemo = this.memo;
      // 位置情報取得処理
      this.loading = true;
      try {
        var res = await this.getPosition();
        this.position.latitude = res.coords.latitude;
        this.position.longitude = res.coords.longitude;
      } catch (e) {
        alert("位置情報の取得に失敗しました");
        // console.log(e);
      }
    },
    submitMemo(memo) {
      this.memo = memo;
    },
  },
};
</script>
