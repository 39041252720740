<template>
  <v-dialog
    ref="updateDialog"
    v-model="dialog"
    scrollable
    persistent
    max-width="700px"
  >
    <v-card>
      <v-card-text height="550px">
        <v-form ref="form">
          <v-text-field
            autofocus
            class="mt-5"
            v-model="name"
            label="シフトテンプレート名"
            :rules="[
              () => !!name || 'シフトテンプレート名は必須入力です。',
              () =>
                !!name.replace(/\s+/g, '') ||
                'シフトテンプレート名は必須入力です。',
              () =>
                checkStringArrayLength(stringToArray(name), 45) ||
                'シフトテンプレート名は45文字以内で入力してください。',
            ]"
          />
          <v-row no-gutters>
            <v-checkbox
              class="ml-5"
              v-model="is_rest_national_holiday"
              label="祝日休み"
              :false-value="0"
              :true-value="1"
            />
            <v-checkbox
              class="ml-5"
              v-model="is_rest_holiday"
              label="公休休み"
              :false-value="0"
              :true-value="1"
            />
          </v-row>
          <v-row no-gutters>
            <v-data-table
              :headers="headers"
              :items="shifts"
              :fixed-header="true"
              class="shiftpattern-table elevation-1"
              hide-default-footer
              disable-sort
              height="320px"
            >
              <template v-slot:item.shiftdiv="{ item }">
                <v-select
                  label="シフト区分"
                  :items="shiftDiv"
                  v-model="item.shiftdiv"
                ></v-select>
              </template>
              <template v-slot:item.attendtime="{ item }">
                <div class="d-flex align-center" v-if="item.shiftdiv != '0'">
                  <TimePicker
                    :hh.sync="item.attendtime.hh"
                    :mm.sync="item.attendtime.mm"
                    class="mr-2"
                  />
                </div>
              </template>
              <template v-slot:item.leavetime="{ item }">
                <div class="d-flex align-center" v-if="item.shiftdiv != '0'">
                  <TimePicker
                    :hh.sync="item.leavetime.hh"
                    :mm.sync="item.leavetime.mm"
                    class="mr-2"
                  />
                </div>
              </template>
              <template v-slot:item.resttime="{ item }">
                <div class="d-flex align-center" v-if="item.shiftdiv != '0'">
                  <TimePicker
                    :hh.sync="item.resttime.hh"
                    :mm.sync="item.resttime.mm"
                    class="mr-2"
                  />
                </div>
              </template>
            </v-data-table>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-2 font-weight-bold"
          color="alert"
          text
          v-show="!add_new_pattern"
          @click="deleteData"
          >削除</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn class="mr-2 font-weight-bold" color="cancel" text @click="close"
          >閉じる</v-btn
        >
        <v-btn class="mr-2 font-weight-bold" color="primary" text @click="save"
          >保存</v-btn
        >
      </v-card-actions>
    </v-card>
    <loading-dialog :loading="loading"></loading-dialog>
  </v-dialog>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import LoadingDialog from "../common/LoadingDialog";
import TimePicker from "../common/TimePicker";
export default {
  components: {
    loadingDialog: LoadingDialog,
    TimePicker,
  },
  mixins: [axiosMixin],
  inject: ["loadShiftPatterns", "showSnackbar"],
  data: () => ({
    id: 0,
    name: "",
    add_new_pattern: false,
    dialog: false,
    is_rest_holiday: 0,
    is_rest_national_holiday: 0,
    snackbar_message: "",
    details: [],
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
    shiftDiv: [
      { text: "通常", value: "1" },
      { text: "休み", value: "0" },
    ],
    headers: [
      { text: "曜日", value: "weekday" },
      { text: "シフト区分", value: "shiftdiv" },
      { text: "出勤時刻", value: "attendtime" },
      { text: "退勤時刻", value: "leavetime" },
      { text: "休憩時刻", value: "resttime" },
    ],
    shifts: [],
  }),
  methods: {
    loadDetails() {
      this.shifts = [];
      this.postJson(
        window.base_url + "/api/shiftpattern/detailslist",
        { shiftpattern_id: this.id },
        (res) => {
          if (res.data.shiftpattern_details != null) {
            this.is_rest_holiday =
              res.data.shiftpattern_details[0].is_rest_holiday;
            this.is_rest_national_holiday =
              res.data.shiftpattern_details[0].is_rest_national_holiday;
            res.data.shiftpattern_details.forEach((detail) => {
              this.shifts.push({
                weekdayNo: detail.weekday,
                weekday: this.weekdays[detail.weekday],
                shiftdiv: String(detail.attendance_div),
                attendtime: {
                  hh: detail.starttime_shift_hour,
                  mm: detail.starttime_shift_minute,
                },
                leavetime: {
                  hh: detail.endtime_shift_hour,
                  mm: detail.endtime_shift_minute,
                },
                resttime: {
                  hh: detail.rest_time_shift_hour,
                  mm: detail.rest_time_shift_minute,
                },
              });
            });
          }
        }
      );
    },

    open() {
      this.loadDetails();
      this.dialog = true;
      if (this.$refs.form) {
        this.is_rest_national_holiday = 0;
        this.is_rest_holiday = 0;
        this.$refs.form.resetValidation();
      }
    },
    close() {
      this.dialog = false;
    },
    save() {
      if (!this.$refs.form.validate(true)) return;
      if (confirm("データを保存しますか？")) {
        this.details = [];
        this.shifts.forEach((shift) => {
          this.details.push({
            weekday: shift.weekdayNo,
            attendance_div: parseInt(shift.shiftdiv, 10),
            starttime_shift: [shift.attendtime.hh, shift.attendtime.mm],
            endtime_shift: [shift.leavetime.hh, shift.leavetime.mm],
            rest_time_shift: [shift.resttime.hh, shift.resttime.mm],
          });
        });
        this.postJson(
          window.base_url + "/api/shiftpattern/save",
          {
            shiftpattern_name: this.name,
            add_new_pattern: this.add_new_pattern,
            shiftpattern_id: this.id,
            shiftpattern_details: this.details,
            is_rest_holiday: this.is_rest_holiday,
            is_rest_national_holiday: this.is_rest_national_holiday,
          },
          () => {
            this.dialog = false;
            this.snackbar_message = "保存しました。";
            this.showSnackbar();
            this.loadShiftPatterns();
          }
        );
      }
    },
    deleteData() {
      if (confirm("データを削除しますか？")) {
        this.postJson(
          window.base_url + "/api/shiftpattern/delete",
          { shiftpattern_id: this.id },
          () => {
            this.dialog = false;
            this.snackbar_message = "削除しました。";
            this.showSnackbar();
            this.loadShiftPatterns();
          }
        );
      }
    },
    stringToArray(str) {
      return (
        str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || []
      );
    },

    checkStringArrayLength(stringArray, length) {
      return stringArray.length <= length;
    },
  },
};
</script>

<style lang="scss" scoped>
.shiftpattern-table {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.shiftpattern-table > * {
  -webkit-transform: translateZ(0px);
}
</style>