import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FABE00',
        append: '#1064B7',
        alert: '#E40113',
        cancel: '#595757',
        footer: 'F2F2F2',
      }
    }
  }
});
