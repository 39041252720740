<template>
  <div>
    <!-- 対象年月 -->
    <v-row no-gutters align="center">
      <div class="d-flex align-center">
        <v-btn icon @click="prev()">
          <v-icon>mdi-calendar-arrow-left</v-icon>
        </v-btn>
        <div style="width: 150px">
          <v-menu
            ref="target_date"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                style="font-size: 20px"
                label="対象年月"
                v-model="dateFormatted"
                v-on="on"
                :readonly="true"
              />
            </template>
            <v-date-picker
              v-model="date"
              locale="ja"
              no-title
              type="month"
              @input="menu1 = false"
            />
          </v-menu>
        </div>
        <v-btn icon @click="next()">
          <v-icon>mdi-calendar-arrow-right</v-icon>
        </v-btn>
      </div>
      <!-- <v-spacer />
      <v-btn class="mr-3 font-weight-bold" color="primary" @click="save">
        <v-icon left>mdi-content-save</v-icon>保存
      </v-btn> -->
    </v-row>

    <!--- 曜日（ヘッダ） -->
    <v-row no-gutters height="30">
      <v-col v-for="weekday in weekdays" :key="weekday">
        <v-card tile :color="header_color">
          <center>
            <b>{{ weekday }}</b>
          </center>
        </v-card>
      </v-col>
    </v-row>

    <!--- カレンダ -->
    <v-row no-gutters class="mt-1 mx-auto" style="height: 500px">
      <v-calendar
        ref="calendar"
        locale="ja"
        :type="calendar_type"
        :start="calDate"
        :events="events"
        :event-color="getEventColor"
        :hide-header="true"
        @click:day="clickDate"
        @click:date="clickDate"
      >
      </v-calendar>
    </v-row>
    <v-snackbar bottom v-model="snackbar" :timeout="1000"
      >保存しました。</v-snackbar
    >
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";

export default {
  mixins: [axiosMixin, commonMixin],
  data: (vm) => ({
    snackbar: false,
    menu1: false,
    events: [],
    event_name: "オンコール当番申請",
    event_color: "cyan darken-2",
    header_color: "#eee",
    calendar_type: "month",
    date: vm.dateJp(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
    isRestricted: false,
  }),
  created: function () {
    var date = new Date();
    this.date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      .toISOString()
      .substr(0, 7);
  },
  computed: {
    calDate() {
      return new Date(this.date.substr(0, 4), this.date.substr(5, 2), 1)
        .toISOString()
        .substr(0, 10);
    },
    dateFormatted() {
      if (!this.date) return null;
      const [year, month] = this.date.split("-");
      return `${year}年${month}月`;
    },
  },
  watch: {
    date: {
      handler() {
        if (this.date != null) {
          this.getData();
        }
      },
    },
  },
  methods: {
    prev() {
      this.date = new Date(
        this.date.substr(0, 4),
        parseInt(this.date.substr(5, 2)) - 1,
        1
      )
        .toISOString()
        .substr(0, 7);
    },
    next() {
      this.date = new Date(
        this.date.substr(0, 4),
        parseInt(this.date.substr(5, 2)) + 1,
        1
      )
        .toISOString()
        .substr(0, 7);
    },
    getData() {
      const [year, month] = this.date.split("-");
      this.postJson(
        window.base_url + "/api/oncall/request",
        { tag_ym: Number(`${year}${month}`) },
        (response) => {
          if (response.data.code == 201) {
            this.isRestricted = true;
            alert(response.data.message);
            return;
          } else if (response.data.code > 0) {
            alert(response.data.message);
            return;
          }
          this.isRestricted = false;
          this.events = [];
          for (var i = 0; i < response.data.list.length; i++) {
            this.addDateToEvents(response.data.list[i]);
          }
        }
      );
    },
    getEventColor() {
      return this.event_color;
    },
    clickDate(clicked) {
      //閲覧制限期間の場合何もしない
      if (this.isRestricted == true) return;

      let sameDateIndex = this.events.findIndex(
        (event) => event.start === clicked.date
      );
      // 見つからなかった場合追加、そうでなければ削除する
      if (sameDateIndex === -1) {
        this.postJson(
          window.base_url + "/api/oncall/request/add",
          { tag_ymd: clicked.date },
          (response) => {
            if (response.data.code != 0) {
              alert(response.data.message);
              return;
            }
            this.addDateToEvents(response.data.oncall);
            this.snackbar = true;
          }
        );
      } else {
        this.postJson(
          window.base_url + "/api/oncall/request/del",
          { tag_ymd: clicked.date },
          (response) => {
            if (response.data.code != 0) {
              alert(response.data.message);
              return;
            }
            this.events.splice(sameDateIndex, 1);
            this.snackbar = true;
          }
        );
      }
    },
    addDateToEvents(data) {
      this.events.push({
        name: this.event_name,
        start: data.oncalldate.substr(0, 10),
        end: data.oncalldate.substr(0, 10),
        color: this.event_color,
        app_data: data,
      });
    },
  },
};
</script>