<template>
  <div class="max-area-component">
    <v-row no-gutters align="center">
      <v-sheet tile height="60" color="white" class="d-flex">
        <!-- 対象年月 -->
        <div class="d-flex align-center">
          <v-btn icon :disabled="editmode" @click="prev()">
            <v-icon>mdi-calendar-arrow-left</v-icon>
          </v-btn>
          <div style="min-width: 160px">
            <v-menu
              ref="target_date"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="editmode"
                  style="font-size: 20px"
                  label="対象年月"
                  v-model="dateFormatted"
                  v-on="on"
                  :readonly="true"
                />
              </template>
              <v-date-picker
                v-model="date"
                locale="ja"
                no-title
                type="month"
                @input="menu1 = false"
              />
            </v-menu>
          </div>
          <v-btn icon :disabled="editmode" @click="next()">
            <v-icon>mdi-calendar-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-sheet>
    </v-row>
    <v-select
      v-if="satellites.length > 1"
      :disabled="editmode"
      class="mx-3"
      :items="satellites"
      v-model="satelliteid"
      @change="getData"
      label="事業所・サテライト"
      outlined
    />
    <v-row no-gutters class="mb-3">
      <v-col>
        <span v-if="!editmode">
          <span v-if="auth == 2">
            <v-btn
              class="mr-3 font-weight-bold white--text"
              color="append"
              @click="editmode = true"
              :disabled="isRestricted"
            >
              <v-icon left>mdi-pencil</v-icon>編集
            </v-btn>
          </span>
          <v-btn
            class="font-weight-bold"
            color="primary"
            @click="downloadExcel"
            :disabled="isRestricted"
          >
            <v-icon left>mdi-export-variant</v-icon>Excel出力
          </v-btn>
        </span>
        <span v-if="editmode">
          <v-btn class="font-weight-bold" color="primary" @click="save">
            <v-icon left>mdi-content-save</v-icon>保存
          </v-btn>
          <v-btn
            class="font-weight-bold"
            color="cancel"
            dark
            @click="cancel"
            style="margin-left: 10px"
          >
            <v-icon left>mdi-cancel</v-icon>キャンセル
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <!--- 曜日（ヘッダ） -->
    <v-row no-gutters>
      <v-col v-for="weekday in weekdays" :key="weekday">
        <v-card tile :color="header_color">
          <center>
            <b>{{ weekday }}</b>
          </center>
        </v-card>
      </v-col>
    </v-row>

    <!--- カレンダ -->
    <v-row no-gutters class="mx-auto" style="height: 550px">
      <v-calendar
        ref="calendar"
        locale="ja"
        :type="calendar_type"
        :start="calDate"
        :events="events"
        :event-color="event_color"
        :hide-header="true"
        :event-more="false"
        @click:day="clickDate"
        @click:date="clickDate"
      >
      </v-calendar>
    </v-row>

    <!-- 登録ダイアログ -->
    <v-dialog persistent v-model="dialog" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          <v-row no-gutters align="center">
            <span class="mr-5 headline"
              >オンコール当番 - {{ tagDateDisp }}</span
            >
            <v-row no-gutters class="ml-auto">
              <v-btn :disabled="prevbtn" @click="prevdate">＜</v-btn>
              <v-btn :disabled="nextbtn" @click="nextdate">＞</v-btn>
            </v-row>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                style="font-size: 20px"
                label="当番１"
                v-model="duty1Name"
                readonly
              />
              <v-card style="max-height: 300px" class="overflow-y-auto">
                <v-list>
                  <v-subheader>オンコール申請者</v-subheader>
                  <v-list-item-group v-model="duty1" color="primary">
                    <v-list-item
                      v-for="(item, i) in app_staff"
                      :key="'duty1-1' + i"
                      @click="clickList1Item(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.s_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-subheader>その他職員</v-subheader>
                    <v-list-item
                      v-for="(item, i) in oth_staff"
                      :key="'duty1-2' + i"
                      @click="clickList1Item(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.s_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <v-col>
              <v-text-field
                style="font-size: 20px"
                label="当番２"
                v-model="duty2Name"
                readonly
              />
              <v-card style="max-height: 300px" class="overflow-y-auto">
                <v-list>
                  <v-subheader>オンコール申請者</v-subheader>
                  <v-list-item-group v-model="duty2" color="primary">
                    <v-list-item
                      v-for="(item, i) in app_staff"
                      :key="'duty2-1' + i"
                      @click="clickList2Item(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.s_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-subheader>その他職員</v-subheader>
                    <v-list-item
                      v-for="(item, i) in oth_staff"
                      :key="'duty2-2' + i"
                      @click="clickList2Item(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.s_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="font-weight-bold"
            text
            color="cancel"
            @click="dialog = false"
            >閉じる</v-btn
          >
          <v-btn
            class="font-weight-bold"
            text
            color="primary"
            @click="dialogSave"
            >登録</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar bottom v-model="snackbar" :timeout="timeout">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";
import commonMixin from "../../mixins/commonMixin";

export default {
  mixins: [axiosMixin, commonMixin],
  inject: ["navigationManage"],
  data: (vm) => ({
    menu1: false,
    events: [],
    event_color: "cyan darken-2",
    header_color: "#eee",
    calendar_type: "month",
    date: vm.dateJp(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
    fromdate: null,
    todate: null,
    dialog: false,
    nextbtn: false,
    prevbtn: false,
    editmode: false,
    tagDateDisp: null,
    tagDate: null,
    app_staff: [],
    oth_staff: [],
    duty1: null,
    duty2: null,
    duty1Name: "未設定",
    duty2Name: "未設定",
    selectedduty1: -1,
    selectedduty2: -1,
    satellites: [],
    satelliteid: 0,
    isRestricted: false,
    snackbar: false,
    snackMessage: "保存しました。",
  }),
  beforeRouteLeave(to, from, next) {
    if (this.editmode === true) {
      if (!confirm("変更した内容が破棄されます。よろしいですか？")) {
        this.$nextTick(() => {
          this.navigationManage(from);
        });
        return;
      }
    }
    next();
  },
  created: function () {
    this.postJson(window.base_url + "/api/oncall/office", {}, (response) => {
      if (response.data.code == 201) {
        this.isRestricted = true;
        alert(response.data.message);
        return;
      } else if (response.data.code > 0) {
        alert(response.data.message);
        return;
      }
      this.isRestricted = false;
      for (var i = 0; i < response.data.list.length; i++) {
        this.satellites.push({
          text: response.data.list[i].satellite_name,
          value: response.data.list[i].satellite_id,
        });
      }
      var date = new Date();
      this.date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
        .toISOString()
        .substr(0, 7);
    });
  },
  computed: {
    calDate() {
      return new Date(this.date.substr(0, 4), this.date.substr(5, 2), 1)
        .toISOString()
        .substr(0, 10);
    },
    dateFormatted() {
      if (!this.date) return null;
      const [year, month] = this.date.split("-");
      return `${year}年${month}月`;
    },
    auth() {
      return window.auth;
    },
  },
  watch: {
    date: {
      handler() {
        if (this.date != null) {
          this.getData();
        }
      },
    },
  },
  methods: {
    prev() {
      this.date = new Date(
        this.date.substr(0, 4),
        parseInt(this.date.substr(5, 2)) - 1,
        1
      )
        .toISOString()
        .substr(0, 7);
    },
    next() {
      this.date = new Date(
        this.date.substr(0, 4),
        parseInt(this.date.substr(5, 2)) + 1,
        1
      )
        .toISOString()
        .substr(0, 7);
    },
    getData() {
      const [year, month] = this.date.split("-");
      this.postJson(
        window.base_url + "/api/oncall",
        { tag_ym: Number(`${year}${month}`), satellite_id: this.satelliteid },
        (response) => {
          if (response.data.code == 201) {
            this.isRestricted = true;
            alert(response.data.message);
            return;
          } else if (response.data.code > 0) {
            alert(response.data.message);
            return;
          }
          this.isRestricted = false;
          this.fromdate = response.data.from_date;
          this.todate = response.data.to_date;
          this.events = [];
          for (var i = 0; i < response.data.list.length; i++) {
            this.addDateToEvents(response.data.list[i]);
          }
        }
      );
    },
    cancel() {
      if (!window.confirm("変更した内容が破棄されます。よろしいですか？"))
        return;
      this.editmode = false;
      this.getData();
    },
    save() {
      if (!window.confirm("変更した内容を保存します。よろしいですか？")) return;
      const [year, month] = this.date.split("-");
      this.postJson(
        window.base_url + "/api/oncall/save",
        {
          tag_ym: Number(`${year}${month}`),
          satellite_id: this.satelliteid,
          oncalls: this.events,
        },
        (response) => {
          if (response.data.code != 0) {
            alert(response.data.message);
            return;
          }
          this.editmode = false;
          this.snackMessage = "保存しました。";
          this.snackbar = true;
        }
      );
    },
    downloadExcel() {
      const [year, month] = this.date.split("-");
      this.postJsonBlobRes(
        window.base_url + "/api/oncall/excel",
        { tag_ym: Number(`${year}${month}`), satellite_id: this.satelliteid },
        (response) => {
          var blob = new Blob([response.data]);
          var url = window.URL || window.webkitURL;
          var blobURL = url.createObjectURL(blob);

          var a = document.createElement("a");
          a.setAttribute("href", blobURL);
          a.setAttribute("download", "オンコール当番表.xlsx");
          a.style.visibility = "hidden";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      );
    },
    clickDate(clicked) {
      if (!this.editmode) return;
      //*** リストを初期化しておかないとリストの選択がづれる ***
      this.app_staff = [];
      this.oth_staff = [];
      this.postJson(
        window.base_url + "/api/oncall/staff",
        { tag_ymd: clicked.date },
        (response) => {
          if (response.data.code != 0) {
            alert(response.data.message);
            return;
          }
          this.app_staff = response.data.app_list;
          this.oth_staff = response.data.other_list;
          const [year, month, day] = clicked.date.split("-");
          this.tagDate = clicked.date;
          this.tagDateDisp = `${year}年${month}月${day}日`;
          this.prevbtn = this.fromdate == clicked.date;
          this.nextbtn = this.todate == clicked.date;

          //登録済みデータ取得
          var filter = this.events.filter(function (value) {
            return value.start == clicked.date;
          });
          console.log(filter);
          if (filter.length > 0) {
            if (filter[0].disp_order == 1) {
              this.duty1Name = filter[0].name;
              this.selectedduty1 = filter[0].staff_id;
              this.duty1 = this.app_staff
                .concat(this.oth_staff)
                .findIndex((event) => event.staff_id === filter[0].staff_id);

              this.duty2Name = "未設定";
              this.selectedduty2 = -1;
              this.duty2 = null;
            } else if (filter[0].disp_order == 2) {
              this.duty1Name = "未設定";
              this.selectedduty1 = -1;
              this.duty1 = null;

              this.duty2Name = filter[0].name;
              this.selectedduty2 = filter[0].staff_id;
              this.duty2 = this.app_staff
                .concat(this.oth_staff)
                .findIndex((event) => event.staff_id === filter[0].staff_id);
            }
            console.log(filter[0].disp_order);
            console.log(this.duty2Name);
          } else {
            this.duty1Name = "未設定";
            this.selectedduty1 = -1;
            this.duty1 = null;
          }
          if (filter.length > 1) {
            this.duty2Name = filter[1].name;
            this.selectedduty2 = filter[1].staff_id;
            this.duty2 = this.app_staff
              .concat(this.oth_staff)
              .findIndex((event) => event.staff_id === filter[1].staff_id);
          }
          if (filter.length == 0) {
            this.duty1Name = "未設定";
            this.selectedduty1 = -1;
            this.duty1 = null;

            this.duty2Name = "未設定";
            this.selectedduty2 = -1;
            this.duty2 = null;
          }
          this.dialog = true;
        }
      );
    },
    addDateToEvents(data) {
      if (!data) return;
      this.events.push({
        name: data.s_name,
        start: data.oncalldate.substr(0, 10),
        end: data.oncalldate.substr(0, 10),
        color: this.event_color,
        staff_id: data.staff_id,
        disp_order: data.disp_order,
      });
    },
    //----------------------------------------------
    //ダイアログ関連
    //----------------------------------------------
    prevdate() {
      let dt = new Date(this.tagDate);
      dt.setDate(dt.getDate() - 1);
      var dtstring =
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2);
      this.clickDate({ date: dtstring });
    },
    nextdate() {
      let dt = new Date(this.tagDate);
      dt.setDate(dt.getDate() + 1);
      var dtstring =
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2);
      this.clickDate({ date: dtstring });
    },
    clickList1Item(vale) {
      if (this.selectedduty1 == vale.staff_id) {
        this.duty1Name = "未設定";
        this.selectedduty1 = -1;
      } else {
        this.duty1Name = vale.s_name;
        this.selectedduty1 = vale.staff_id;
      }
    },
    clickList2Item(vale) {
      if (this.selectedduty2 == vale.staff_id) {
        this.duty2Name = "未設定";
        this.selectedduty2 = -1;
      } else {
        this.duty2Name = vale.s_name;
        this.selectedduty2 = vale.staff_id;
      }
    },
    dialogSave() {
      //同一担当の場合はエラー
      if (
        this.selectedduty1 == this.selectedduty2 &&
        this.selectedduty1 != -1
      ) {
        alert("同一職員が選択されています。");
        return;
      }
      //登録済みデータ削除
      var sameDateIndex = this.events.findIndex(
        (event) => event.start === this.tagDate
      );
      while (sameDateIndex !== -1) {
        this.events.splice(sameDateIndex, 1);
        sameDateIndex = this.events.findIndex(
          (event) => event.start === this.tagDate
        );
      }
      //データ追加
      var s1 = null;
      var s2 = null;
      if (this.selectedduty1 != -1) {
        s1 = {
          s_name: this.duty1Name,
          oncalldate: this.tagDate,
          staff_id: this.selectedduty1,
          disp_order: 1,
        };
      }
      if (this.selectedduty2 != -1) {
        s2 = {
          s_name: this.duty2Name,
          oncalldate: this.tagDate,
          staff_id: this.selectedduty2,
          disp_order: 2,
        };
      }
      this.addDateToEvents(s1);
      this.addDateToEvents(s2);
      this.snackMessage = "登録しました。";
      this.snackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>