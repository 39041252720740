<template>
  <div>
    <v-row no-gutters align="center">
      <month-picker
        class="mx-3"
        v-model="yearmonth"
        @changeMonth="onChangeMonth"
      >
      </month-picker>
      <div style="width: 100px">
        <v-select
          class="mx-3"
          label="予定/実績"
          :items="actual_divs"
          v-model="actual_div"
          @change="onChangeActualDiv"
        >
        </v-select>
      </div>
      <div style="width: 140px">
        <v-select
          class="mx-3"
          label="表記形式"
          :items="display_divs"
          v-model="display_div"
          @change="onChangeDisplayDiv"
        >
        </v-select>
      </div>
      <v-spacer></v-spacer>
    </v-row>
    <v-row no-gutters align="center" justify="end">
      <span class="mx-3"
        >１日あたりの勤務時間：{{ header.working_hours }}時間</span
      >
    </v-row>
    <v-row no-gutters align="center" class="mb-3">
      <v-btn
        class="mx-3 font-weight-bold white--text"
        color="append"
        @click="showSetting"
        :disabled="details.length === 0"
      >
        <v-icon left>mdi-tools</v-icon>設定
      </v-btn>
      <v-btn
        class="font-weight-bold"
        color="primary"
        @click="downloadExcel"
        :disabled="details.length === 0"
      >
        <v-icon left>mdi-export-variant</v-icon>Excel出力
      </v-btn>
      <v-spacer />
      <span class="mx-3">{{ workingDaysLabel }}：{{ workingDays }}日</span>
    </v-row>
    <v-alert :icon="alertIcon" v-show="warning != null" :type="alertType">{{
      warning
    }}</v-alert>
    <v-card class="card" v-show="warning == null && details.length !== 0">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="auto">
            <p>従業員の勤務体制及び勤務形態一覧表</p>
          </v-col>
          <v-col cols="auto">
            <p>( {{ yearmonthFormat(header.yearmonth) }} )</p>
          </v-col>
          <v-col class="text-right">
            <p>サービス種類 （ {{ header.service_type }} ）</p>
            <p>事業所番号 （ {{ header.kofficecd }} ）</p>
            <p>事業所名 （ {{ header.officename }} ）</p>
          </v-col>
        </v-row>
        <v-data-table
          id="scroll-target"
          style="table-layout: fixed; min-width: 100%"
          :headers="headers"
          :items="details"
          hide-default-footer
          disable-pagination
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.job_name }}</td>
              <td>{{ item.worktype_pattern }}</td>
              <td>{{ item.s_name }}</td>
              <td>{{ item.day1 }}</td>
              <td>{{ item.day2 }}</td>
              <td>{{ item.day3 }}</td>
              <td>{{ item.day4 }}</td>
              <td>{{ item.day5 }}</td>
              <td>{{ item.day6 }}</td>
              <td>{{ item.day7 }}</td>
              <td>{{ item.day8 }}</td>
              <td>{{ item.day9 }}</td>
              <td>{{ item.day10 }}</td>
              <td>{{ item.day11 }}</td>
              <td>{{ item.day12 }}</td>
              <td>{{ item.day13 }}</td>
              <td>{{ item.day14 }}</td>
              <td>{{ item.day15 }}</td>
              <td>{{ item.day16 }}</td>
              <td>{{ item.day17 }}</td>
              <td>{{ item.day18 }}</td>
              <td>{{ item.day19 }}</td>
              <td>{{ item.day20 }}</td>
              <td>{{ item.day21 }}</td>
              <td>{{ item.day22 }}</td>
              <td>{{ item.day23 }}</td>
              <td>{{ item.day24 }}</td>
              <td>{{ item.day25 }}</td>
              <td>{{ item.day26 }}</td>
              <td>{{ item.day27 }}</td>
              <td>{{ item.day28 }}</td>
              <td :class="classGray()">{{ item.day29 }}</td>
              <td :class="classGray()">{{ item.day30 }}</td>
              <td :class="classGray()">{{ item.day31 }}</td>

              <td v-if="display_div == 'disp_4w'">{{ item.total4w }}</td>
              <td v-if="display_div == 'disp_4w'">{{ item.week_avg4w }}</td>
              <td v-if="display_div == 'disp_4w'">{{ item.conv_cnt4w }}</td>

              <td v-if="display_div == 'disp_1m'">{{ item.total1m }}</td>
              <td v-if="display_div == 'disp_1m'">{{ item.conv_cnt1m }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-row no-gutters>
          <v-col>
            <div>看護職員の常勤換算後の人数：{{ nurseConvCnt }}</div>
            <div>
              ※
              看護職員の勤務時間の合計を常勤者が勤務すべき時間で割った値。少数第２位以下切り捨て
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- ローディングダイアログコンポーネント   this.postJsonの呼び出しで自動で表示される-->
    <loading-dialog :loading="loading"></loading-dialog>
    <setting-dialog
      ref="dialog"
      :yearmonth="yearmonth"
      @updated="settingUpdated"
    ></setting-dialog>
    <v-snackbar bottom v-model="snackbar" :timeout="1000">
      {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>
<script>
// 共通部品のインポート
import axiosMixin from "../../mixins/axiosMixin";
// ローディングダイアログのインポート
import LoadingDialog from "../common/LoadingDialog";
import MonthPicker from "../common/NextPrevMonthPicker";
import SettingDialog from "./SettingDialog";

export default {
  components: {
    LoadingDialog,
    MonthPicker,
    SettingDialog,
  },

  mixins: [axiosMixin],

  data() {
    return {
      yearmonth: null,
      actual_divs: [
        { value: "plan", text: "予定" },
        { value: "actual", text: "実績" },
      ],
      actual_div: "plan",
      display_divs: [
        { value: "disp_4w", text: "4週表記" },
        { value: "disp_1m", text: "暦月表記" },
      ],
      display_div: "disp_4w",
      bucket: {},
      header: {},
      cloneHeader: {},
      details: [],
      warning: null,
      snackbar: false,
      snackbarMessage: "",
      alertType: "warning",
      alertIcon: "mdi-exclamation",
    };
  },

  computed: {
    workingDaysLabel() {
      if (this.display_div === "disp_4w") {
        return `4週の勤務日数`;
      }
      if (this.display_div === "disp_1m") {
        return `${this.monthFormat(this.yearmonth)}の勤務日数`;
      }
      return -1;
    },
    workingDays() {
      if (this.display_div === "disp_4w") {
        return this.header.working_days_4weeks;
      }
      if (this.display_div === "disp_1m") {
        return this.header.working_days_month;
      }
      return -1;
    },
    headers() {
      if (this.display_div === "disp_4w") {
        return [
          { value: "job_name", text: "職種", width: "150px" },
          { value: "worktype_pattern", text: "勤務形態" },
          { value: "s_name", text: "氏名", width: "150px" },
          { value: "day1", text: "" },
          { value: "day2", text: "" },
          { value: "day3", text: "" },
          { value: "day4", text: "" },
          { value: "day5", text: "" },
          { value: "day6", text: "" },
          { value: "day7", text: "" },
          { value: "day8", text: "" },
          { value: "day9", text: "" },
          { value: "day10", text: "" },
          { value: "day11", text: "" },
          { value: "day12", text: "" },
          { value: "day13", text: "" },
          { value: "day14", text: "" },
          { value: "day15", text: "" },
          { value: "day16", text: "" },
          { value: "day17", text: "" },
          { value: "day18", text: "" },
          { value: "day19", text: "" },
          { value: "day20", text: "" },
          { value: "day21", text: "" },
          { value: "day22", text: "" },
          { value: "day23", text: "" },
          { value: "day24", text: "" },
          { value: "day25", text: "" },
          { value: "day26", text: "" },
          { value: "day27", text: "" },
          { value: "day28", text: "" },
          { value: "day29", text: "", class: "grey lighten-1" },
          { value: "day30", text: "", class: "grey lighten-1" },
          { value: "day31", text: "", class: "grey lighten-1" },
          { value: "total4w", text: "4週の合計" },
          { value: "week_avg4w", text: "週平均の勤務時間" },
          { value: "conv_cnt4w", text: "常勤換算後の人数" },
        ];
      }
      if (this.display_div === "disp_1m") {
        return [
          { value: "job_name", text: "職種", width: "150px" },
          { value: "worktype_pattern", text: "勤務形態" },
          { value: "s_name", text: "氏名", width: "150px" },
          { value: "day1", text: "" },
          { value: "day2", text: "" },
          { value: "day3", text: "" },
          { value: "day4", text: "" },
          { value: "day5", text: "" },
          { value: "day6", text: "" },
          { value: "day7", text: "" },
          { value: "day8", text: "" },
          { value: "day9", text: "" },
          { value: "day10", text: "" },
          { value: "day11", text: "" },
          { value: "day12", text: "" },
          { value: "day13", text: "" },
          { value: "day14", text: "" },
          { value: "day15", text: "" },
          { value: "day16", text: "" },
          { value: "day17", text: "" },
          { value: "day18", text: "" },
          { value: "day19", text: "" },
          { value: "day20", text: "" },
          { value: "day21", text: "" },
          { value: "day22", text: "" },
          { value: "day23", text: "" },
          { value: "day24", text: "" },
          { value: "day25", text: "" },
          { value: "day26", text: "" },
          { value: "day27", text: "" },
          { value: "day28", text: "" },
          { value: "day29", text: "" },
          { value: "day30", text: "" },
          { value: "day31", text: "" },
          {
            value: "total1m",
            text: `${this.monthFormat(this.yearmonth)}の合計`,
          },
          { value: "conv_cnt1m", text: "常勤換算後の人数" },
        ];
      }
      return [];
    },
    nurseConvCnt() {
      if (this.display_div === "disp_4w") {
        return this.header.nurse_conv_cnt4w;
      }
      if (this.display_div === "disp_1m") {
        return this.header.nurse_conv_cnt1m;
      }
      return -1;
    },
  },

  provide: {},

  created() {
    this.initialize();
  },

  methods: {
    isValid(param) {
      if (param === "plan" || param === "actual") {
        return true;
      }
      return false;
    },
    initialize() {
      if (!this.isValid(this.actual_div)) {
        return;
      }

      let date = new Date();
      date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
        .toISOString()
        .substr(0, 7);
      const [year, month] = date.split("-");
      this.yearmonth = Number(`${year}${month}`);

      this.makeViewData();
    },
    yearmonthFormat(yearmonth) {
      const year = String(yearmonth).substr(0, 4);
      const month = String(yearmonth).substr(4, 2);
      return `${year}年${month}月`;
    },
    monthFormat(yearmonth) {
      if (yearmonth == null) {
        return "";
      }
      const month = Number(String(yearmonth).substr(4, 2));
      return `${month}月`;
    },
    makeViewData() {
      if (this.yearmonth <= 0) return;

      this.warning = null;
      this.header = {};
      this.details = [];

      const param = { yearmonth: this.yearmonth, actual_div: this.actual_div };
      this.postJson(
        window.base_url + "/api/worktypelist/view/make",
        param,
        (res) => {
          // console.log(res)
          if (res.data.code == 201) {
            this.alertType = "info";
            this.alertIcon = "mdi-monitor-lock";
            this.warning = res.data.message;
            return;
          } else if (res.data.code != 0) {
            // alert( res.data.message )
            this.alertType = "warning";
            this.alertIcon = "mdi-exclamation";
            this.warning = res.data.message;
            return;
          }
          this.makeHeader();
          this.header = res.data.header == null ? {} : res.data.header;
          this.details = res.data.details == null ? [] : res.data.details;
          // console.log("details", this.details)
        }
      );
    },
    downloadExcel() {
      const param = {
        actual_div: this.actual_div,
        display_div: this.display_div,
        header: this.header,
        details: this.details,
      };
      const wk = this.actual_div === "plan" ? "予定" : "実績";
      const fname = `勤務体制及び勤務形態一覧表_${this.yearmonth}_${wk}.xlsx`;

      this.postJsonBlobRes(
        window.base_url + "/api/worktypelist/download",
        param,
        (res) => {
          // console.log(res)
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fname;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      );
    },
    makeHeader() {
      if (this.yearmonth <= 0) return;
      const year = Number(String(this.yearmonth).substr(0, 4));
      const month = Number(String(this.yearmonth).substr(4, 2));

      let date = new Date(year, month - 1, 1);
      let lastdate = new Date(year, month, 0).getDate();
      // console.log(year, month, lastdate)

      let week = ["日", "月", "火", "水", "木", "金", "土"];

      for (let i = 1; i <= 31; i++) {
        date.setDate(i);
        let text = "";
        if (i <= lastdate) {
          text = `${i}\n${week[date.getDay()]}`;
        }
        const element = this.headers.find((elm) => elm.value === `day${i}`);
        if (element) {
          element.text = text;
        }
      }
    },
    onChangeMonth() {
      this.makeViewData();
    },
    onChangeActualDiv() {
      this.makeViewData();
    },
    onChangeDisplayDiv() {
      this.makeHeader();
    },
    showSetting() {
      this.$refs.dialog.open();
    },
    showSnackbar(message) {
      this.snackbarMessage = message;
      this.snackbar = true;
    },
    settingUpdated(message) {
      this.showSnackbar(message);
      this.makeViewData();
    },
    classGray() {
      if (this.display_div === "disp_4w") {
        return "grey lighten-1";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 57px;
}
#scroll-target {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>