<template>
  <v-dialog v-model="isActive" persistent max-width="400px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">【実績登録】</span>
      </v-card-title>
      <v-card-text class="text-center">
        <div>実績の割り振りをしてください</div>
        <div :class="{ 'actual-time-error': time < 0 }">
          実働時間：{{ dispTime }}
        </div>
      </v-card-text>
      <v-row
        no-gutters
        class="mx-2"
        align="center"
        style="max-width: 400px"
        v-for="item in items"
        :key="item.id"
      >
        <v-col cols="3">{{ item.job.job_name }}</v-col>
        <v-col>
          <time-picker :hh.sync="item.hour" :mm.sync="item.minute" />
        </v-col>
      </v-row>
      <v-card-actions class="justify-center">
        <v-col>
          <v-row no-gutters justify="center">
            <v-btn class="font-weight-bold" color="primary" large @click="agree"
              >OK</v-btn
            >
          </v-row>
          <v-row no-gutters class="mt-5" justify="center">
            <v-btn v-show="showCancel" color="cancel" large text @click="cancel"
              ><span class="text-caption">キャンセル</span></v-btn
            >
          </v-row>
        </v-col>
      </v-card-actions>
      <v-spacer :style="{ height: '120px' }"></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import TimePicker from "../common/TimePicker";
export default {
  components: {
    TimePicker,
  },
  data: () => ({
    isActive: false,
    showCancel: false,
    time: 0,
    items: [],
    actualTime: null,
    attendanceJobs: null,
    resolve: null,
    reject: null,
  }),
  watch: {
    attendanceJobs: function () {
      this.items = JSON.parse(JSON.stringify(this.attendanceJobs));
    },
  },
  computed: {
    dispTime: function () {
      var calcTime = this.calcTime();
      var isMinus = false;
      var tmpTime;
      if (calcTime < 0) {
        isMinus = true;
        tmpTime = calcTime * -1;
      } else {
        tmpTime = calcTime;
      }

      var hour = Math.floor(tmpTime / 60);
      var minute = tmpTime % 60;
      var resultTime = isMinus ? "- " : "";
      resultTime += hour + "時間" + minute + "分";
      return resultTime;
    },
  },
  methods: {
    open(actualTime, attendanceJobs, showCancel = false) {
      this.isActive = true;
      this.showCancel = showCancel;
      this.actualTime = actualTime;
      this.attendanceJobs = attendanceJobs;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (this.time < 0) {
        alert("実績の合計が実働時間を超えています");
        return;
      }
      var inputTime = this.items.reduce((p, x) => p + x.actual_time, 0);
      if (inputTime == 0) {
        alert("実績が入力されていません");
        return;
      }
      this.resolve(this.items);
      this.isActive = false;
    },
    cancel() {
      this.resolve(false);
      this.isActive = false;
    },
    calcTime() {
      var calcTime = this.actualTime;
      this.items.forEach((item) => {
        var hh = !item.hour ? 0 : item.hour * 60;
        var mm = !item.minute ? 0 : item.minute;
        calcTime -= hh;
        calcTime -= mm;
        item.actual_time = hh + mm;
      });
      this.time = calcTime;
      return calcTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.actual-time-error {
  color: red;
}
</style>
