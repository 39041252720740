<template>
  <div class="d-flex align-center">
    <v-btn icon @click="addMonth(-1)">
      <v-icon>mdi-calendar-arrow-left</v-icon>
    </v-btn>
    <div style="width:160px">
      <v-menu
        ref="targetDate"
        v-model="pickerFlag"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            readonly
            style="font-size:20px"
            label="対象年月"
            v-model="dateFormatted"
            v-on="on"
            @blur="checkMonth"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          locale="ja"
          no-title
          type="month"
          @input="pickerFlag = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <v-btn icon @click="addMonth(1)">
      <v-icon>mdi-calendar-arrow-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },

  data: vm => ({
    pickerFlag: false,
    formattingDate: vm.value
  }),

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.formattingDate = newValue;
        this.$emit("input", newValue);
      }
    },
    dateFormatted: {
      get() {
        if (!this.date) return null;
        const [year, month] = this.date.split("-");
        return `${year}年${month}月`;
      },
      set(newValue) {
        const regex = /^([0-9]+?)年([0-9]+?)月$/g;
        const result = regex.exec(newValue);
        if (result) {
          const year = result[1];
          const month = result[2];
          this.formattingDate =
            ("000" + year).slice(-4) + "-" + ("0" + month).slice(-2);
        }
      }
    }
  },

  methods: {
    checkMonth() {
      this.$emit("input", this.formattingDate);
    },
    addMonth(num) {
      const date = new Date(this.date.replace(/-/g, "/") + "/01");
      let month = date.getMonth();
      let year = date.getFullYear();
      let targetMonth = month + num;
      year = Math.floor(targetMonth / 12) + year;
      month = targetMonth % 12;
      if (month < 0) {
        month = 12 + month;
      }
      const newDate = new Date(year, month, 2);
      this.date = newDate.toISOString().substr(0, 7);
    }
  }
};
</script>