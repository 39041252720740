<template>
  <div>
    <v-file-input
      ref="fileInput"
      label="ファイルを添付(10MB以内)"
      :rules="[isPermissionFileSize()]"
      clearable
      @change="$emit('change')"
      v-model="fileParam.file"
    />
    <div style="width: 200px">
      <v-img
        :src="fileParam.previewSrc"
        alt=""
        width="200"
        v-if="isImageExt(fileInfo.file_name)"
      />
      <v-icon
        color="primary"
        size="200"
        v-if="fileInfo.file_name != '' && !isImageExt(fileInfo.file_name)"
      >
        <!-- <v-icon color="primary" size="200" v-if="fileInfo.file_name != ''"> -->
        mdi-file-outline
      </v-icon>
      <v-row no-gutters class="mt-2" v-show="isShowFileAction()">
        <v-btn
          class="mx-auto font-weight-bold"
          color="cancel"
          dark
          small
          @click="$emit('delete')"
        >
          取り消し
        </v-btn>
        <v-btn
          class="mx-auto font-weight-bold"
          color="primary"
          dark
          small
          @click="download()"
        >
          ダウンロード
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";

export default {
  components: {},
  mixins: [axiosMixin],
  props: {
    fileParam: {
      default: () => ({
        file: [],
        previewSrc: "",
      }),
    },
    fileInfo: {
      default: () => ({
        attendance_id: null,
        file_name: "",
        image_id: 0,
        file_type: "",
        is_delete_file: false,
        file_hash: "",
        updated_at: "",
      }),
    },
  },
  data: () => ({
    img_exts: ["jpg", "jpeg", "png"],
  }),

  methods: {
    download() {
      var fileReq = {
        file_id: this.fileInfo.file_id,
      };
      this.postJsonBlobResCheck(
        window.base_url + "/api/attendprivate/file/download",
        fileReq,
        (res) => {
          var blob = new Blob([res.data], {
            type: "application/octet-binary",
          });
          // BlobからFileオブジェクト作成
          const file = new File([blob], this.fileInfo.file_name, {
            type: this.fileInfo.file_type,
          });
          const a = document.createElement("a");
          a.download = this.fileInfo.file_name;
          a.href = URL.createObjectURL(file);
          a.click();
        }
      );
    },
    isShowFileAction() {
      return this.fileInfo.file_id > 0 && !this.fileInfo.is_delete_file;
    },
    isPermissionFileSize() {
      if (!this.fileParam || !this.fileParam.file) {
        // this.$emit("delete");
        return true;
      }
      if (this.fileParam.file.size > 10485760) {
        return "ファイルサイズが10MB以上です。";
      }
      return true;
    },
    getExt(filename) {
      var pos = filename.lastIndexOf(".");
      if (pos === -1) return "";
      return filename.slice(pos + 1);
    },
    isImageExt(filename) {
      //比較のため小文字にする
      var ext = this.getExt(filename).toLowerCase();
      if (this.img_exts.indexOf(ext) === -1) return false;
      return true;
    },
  },
};
</script>