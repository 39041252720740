<template>
  <v-dialog v-model="isActive" persistent max-width="400px">
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">【支給日入力】</span>
      </v-card-title>
      <v-card-text class="text-center">
        <div>入力した支給日でファイルが作成されます。</div>
        <text-date-picker
          style="margin: 0 auto;"
          class="text-center"
          v-model="date"
          :readonly="true"
        ></text-date-picker>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" class="mr-2 font-weight-bold" width="100px" @click="agree">OK</v-btn>
        <v-btn v-show="showCancel" color="cancel" dark class="font-weight-bold" width="100px" @click="cancel">キャンセル</v-btn>
      </v-card-actions>
    </v-card>
    <!-- ローディング -->
    <loading-dialog :loading="loading"></loading-dialog>
  </v-dialog>
</template>

<script>
import axiosMixin     from '../../mixins/axiosMixin';
import TextDatePicker from '../common/TextDatePicker';
import LoadingDialog  from '../common/LoadingDialog';

export default {
  components: {
    TextDatePicker,
    LoadingDialog,
  },
  mixins: [axiosMixin],
  data: () => ({
    isActive  : false,
    showCancel: false,
    resolve   : null,
    reject    : null,
    date      : null,
  }),
  methods: {
    open(showCancel = false, date = new Date().toISOString().substr(0, 10)) {
      this.isActive   = true;
      this.showCancel = showCancel;
      this.date       = date;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },
    agree() {
      this.resolve(this.date.replace(/-/g, '/'));
      this.isActive = false;
    },
    cancel() {
      this.resolve(false);
      this.isActive = false;
    },
  },
};
</script>