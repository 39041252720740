export default {
  data() {
    return {
      div: [
        { text: "休み", value: 0 },
        { text: "通常", value: 1 },
        { text: "時間外", value: 2 }
      ],
      oncall: [
        { text: "", value: 0 },
        { text: "○", value: 1 }
      ],
      shiftType: {
        rest: 0,
        normal: 1,
        over: 2
      }
    };
  },
}
